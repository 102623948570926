import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: string, format?: any): any {
	if(value && value.search("Z")==-1)
		value=value+"Z";

  	if(format){
		moment.locale('es');
  		return moment(value).format(format);
  	}
    return moment(value).format("DD-MM-YYYY hh:mm:ss a");
  }

}
