import { Component, Input, OnInit } from '@angular/core';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';
import { DiraPayInvoiceResponse } from 'src/app/gateway/paymentez/paymentez-dialog/pay-dira/pay-dira.interface';

@Component({
  selector: 'app-purchase-dira-pay-pdf',
  templateUrl: './purchase-dira-pay-pdf.component.html',
  styleUrls: ['./purchase-dira-pay-pdf.component.scss']
})
export class PurchaseDiraPayPdfComponent implements OnInit {
  @Input() diraPayInfo: DiraPayInvoiceResponse;
  @Input() paymentRequest: PaymentConfigModel;
  @Input() logo : ''
  
  constructor() { }

  ngOnInit(): void {
  }

}
