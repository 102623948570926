import { Component, OnInit, Inject } from '@angular/core';
import { MobileProductDataComponent } from '../mobile-product-data.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {PaymentConfigModel} from "../../../../core/models/payment-config.model";

@Component({
  selector: 'app-dialog-summary',
  templateUrl: './dialog-summary.component.html',
  styleUrls: ['./dialog-summary.component.scss']
})
export class DialogSummaryComponent implements OnInit {

  paymentRequest: PaymentConfigModel;

  constructor(
    public dialogRef: MatDialogRef<MobileProductDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log("esto debe entrar aqui", this.data);
    this.paymentRequest = this.data;
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
