import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
//import { AppRoutingModule } from "../app-routing.module";
import { AdminComponent } from './admin.component';
import { SidenavModule } from '../sidenav/sidenav.module';


@NgModule({
  imports: [
    CommonModule,
    SidenavModule,
    //AppRoutingModule
  ],
  declarations: [AdminComponent],
  exports:[AdminComponent],
  providers: []
})
export class AdminModule {
}
