import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CardsService {

  public cardsObservable = new Subject<any>();
  private cards = null;

  emitConfig(val) {
    this.cards = val;
    this.cardsObservable.next(val);
  }

  public getCards() {
    return this.cards;
  }

}
