import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentComponent } from './payment/payment.component';
import { PaymentModule } from './payment/payment.module';
import { LogoutComponent } from './logout/logout.component';
import { AdminGuard } from './guard/admin.guard';
import { AdminComponent } from './admin/admin.component';
import { PaymentRedirectComponent } from './payment/payment-redirect/payment-redirect.component';

const appRoutes: Routes = [
  {
    path: 'payments',
    loadChildren: () =>
      import('./payment-gateway/payment-gateway.module').then(
        (m) => m.PaymentGatewayModule,
      ),
  },
  {
    path: 'pagos',
    loadChildren: () =>
      import('./payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AdminGuard],
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AdminGuard],
        pathMatch: 'full',
      },
      {
        path: 'reverse',
        loadChildren: () =>
          import('./reverse/reverse.module').then((m) => m.ReverseModule),
        canActivate: [AdminGuard],
        pathMatch: 'full',
      },
      {
        path: 'check-transaction-status',
        loadChildren: () =>
          import(
            './check-transaction-status/check-transaction-status.module'
          ).then((m) => m.CheckTransactionStatusModule),
        canActivate: [AdminGuard],
        pathMatch: 'full',
      },
      {
        path: 'check-transaction-status/:reference/:payment_reference',
        loadChildren: () =>
          import(
            './check-transaction-status/check-transaction-status.module'
          ).then((m) => m.CheckTransactionStatusModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'check-subscription-detail',
        loadChildren: () =>
          import(
            './check-subscription-detail/check-subscription-detail.module'
          ).then((m) => m.CheckSubscriptionDetailModule),
        canActivate: [AdminGuard],
        pathMatch: 'full',
      },
      {
        path: 'check-subscription-detail/:subscription',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import(
            './check-subscription-detail/check-subscription-detail.module'
          ).then((m) => m.CheckSubscriptionDetailModule),
      },
      {
        path: 'report-transactions',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./report-transactions/report-transactions.module').then(
            (m) => m.ReportTransactionsModule,
          ),
      },
      {
        path: 'report-subscriptions',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./report-subscriptions/report-subscriptions.module').then(
            (m) => m.ReportSubscriptionsModule,
          ),
      },
      {
        path: 'report-transactions/:client',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./report-transactions/report-transactions.module').then(
            (m) => m.ReportTransactionsModule,
          ),
      },
      {
        path: 'paylinks-list',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./paylinks/paylinks.module').then((m) => m.PaylinksModule),
      },
      {
        path: 'paylinks-bulk-list',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./paylinks-bulk/paylinks-bulk.module').then(
            (m) => m.PaylinksBulkModule,
          ),
      },
      {
        path: 'generate-paylink',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./generate-paylink/generate-paylink.module').then(
            (m) => m.GeneratePaylinkModule,
          ),
      },
      {
        path: 'sub-clients',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./subclient/subclient.module').then((m) => m.SubclientModule),
      },
      {
        path: 'update-credit-card',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./update-credit-card/update-credit-card.module').then(
            (m) => m.UpdateCreditCardModule,
          ),
      },
      {
        path: 'administrator/new-client',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./new-user/new-user.module').then((m) => m.NewUserModule),
      },
      {
        path: 'administrator/config-client',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./confg-client/config-client.module').then(
            (m) => m.CongClientModule,
          ),
      },
      {
        path: 'conjuntos',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./conjunto/conjunto.module').then((m) => m.ConjuntoModule),
      },
      {
        path: 'epayco-conciliation',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./epayco-conciliation/epayco-conciliation.module').then(
            (m) => m.EpaycoConciliationModule,
          ),
      },
      { path: 'logout', component: LogoutComponent }
    ]
  },
  {
    path: 'login-admin',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'update-credit-card',
    loadChildren: () =>
      import('./update-credit-card/update-credit-card.module').then(
        (m) => m.UpdateCreditCardModule,
      ),
  },
  {
    path: 'update-credit-card?q=:data',
    loadChildren: () =>
      import('./update-credit-card/update-credit-card.module').then(
        (m) => m.UpdateCreditCardModule,
      ),
  },
  {
    path: 'custom/paylink',
    loadChildren: () =>
      import('./generic-paylink/generic-paylink.module').then(
        (m) => m.GenericPaylinkModule,
      ),
  },

  { path: '', redirectTo: '/pagos', pathMatch: 'full' },
  { path: 'pagos/solicita', loadChildren: () => PaymentModule },
  {
    path: 'paylink',
    component: PaymentRedirectComponent,
  },

  // {path: 'pagos/solicita', loadChildren: './payment/payment.module#PaymentModule'},
  { path: '**', component: PaymentComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
