import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DefaultDialogComponent } from '../default-dialog/default-dialog.component';


@Component({
  selector: 'app-puntos-colombia-dialog',
  templateUrl: './puntos-colombia-dialog.component.html',
  styleUrls: ['./puntos-colombia-dialog.component.scss']
})
export class PuntosColombiaDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }
}
