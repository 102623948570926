import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-return-browser',
  templateUrl: './return-browser.component.html',
  styleUrls: ['./return-browser.component.scss']
})
export class ReturnBrowserComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
