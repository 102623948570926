import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

export interface Retention {
  calculateRetentions: boolean;
  reteIca: number;
  reteFuente: number;
  reteIva: number;
  totalAmount: number;
  amount: string;
  amountWithoutIva: number;
  iva: string;
}

interface RetentionRequest {
  calculateRetentions: string;
  applyReteVat: string;
  applyReteIca: string;
  feeReteFuente: boolean;
  cityCode: number;
  cityName: string;
  amount: number;
  reference: string;
  amountIva: number;
  vat: number;
  idType: string;
}

@Injectable({
  providedIn: 'root',
})
export class RetentionsService {
  #httpClient = inject(HttpClient);
  #showInfo = new BehaviorSubject(false);

  getRetentions(retentionRequest: RetentionRequest) {
    return this.#httpClient.get<Retention>('payments/get-retentions', {
      params: retentionRequest as any,
    });
  }

  set showInfo(value: boolean) {
    this.#showInfo.next(value);
  }

  get showInfo() {
    return this.#showInfo.getValue();
  }

  get showInfo$() {
    return this.#showInfo.asObservable();
  }
}
