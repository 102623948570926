import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { LoadingScreenService } from "../../core/services/loading-screen.service";
/**
 * Componente que obtiene el rstate, username y confirmation con davipuntos
 */
@Component({
  selector: "app-payment-davipuntos",
  templateUrl: "./payment-davipuntos.component.html",
  styleUrls: ["./payment-davipuntos.component.scss"],
})
export class PaymentDavipuntosComponent implements OnInit {
  public davipuntosCredentials: DavipuntosCredentials;

  constructor(
    private aRoute: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private loadingScreenService: LoadingScreenService
  ) {
    const rstate = this.aRoute.snapshot.queryParamMap.get("rstate");
    const confirmation = this.aRoute.snapshot.queryParamMap.get("confirmation");
    const username = this.aRoute.snapshot.queryParamMap.get("username");

    this.davipuntosCredentials = { rstate, confirmation, username };

    this.sessionStorageService.setItem(
      SessionStorageService.DAVIPUNTOS_CREDENTIALS,
      this.davipuntosCredentials
    );
  }

  ngOnInit(): void {
    this.loadingScreenService.startLoading();
    const paymentUrl: string = this.sessionStorageService.getItem(
      SessionStorageService.PAYMENT_URL
    );
    this.loadingScreenService.stopLoading();
    window.open(paymentUrl, "_self");
  }
}

export interface DavipuntosCredentials {
  rstate?: string;
  confirmation?: string;
  username?: string;
  /** Token to make requests to the Mixed Purchases API */ 
  token?: string;
}
