export enum DocumentType {
  CC = 'CC',
  CE = 'CE',
  PPN = 'PPN',
  NIT = 'NIT',
  TI = 'TI',
}

export const documentTypes: { code: DocumentType; name: string }[] = [
  { code: DocumentType.CE, name: 'Cédula de Ciudadanía' },
  { code: DocumentType.CE, name: 'Cédula de Extranjería' },
  { code: DocumentType.PPN, name: 'Cédula de Pasaporte' },
  { code: DocumentType.NIT, name: 'NIT' },
  { code: DocumentType.TI, name: 'Tarjeta de identidad' },
];
