import { Component, Input, OnInit } from '@angular/core';
import {StatusTransaction} from '../../../core/interfaces/payment-gateway-response.interface';

@Component({
  selector: 'app-purchase-summary-transaction-status',
  templateUrl: './purchase-summary-purchase-order.component.html',
  styleUrls: ['./purchase-summary-purchase-order.component.scss'],
})
export class PurchaseSummaryPurchaseOrderComponent implements OnInit {
  constructor() {}
  @Input() transaction: StatusTransaction;
  ngOnInit(): void {
  }
}
