
export class ScriptUtility {

  public static setGoogleTagManager(key : string): void {
    console.log('GoogleTagManager ', key);
    if(key){
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.text = `(function (w, d, s, l, i) {
                  w[l] = w[l] || []; w[l].push({
                    'gtm.start':
                      new Date().getTime(), event: 'gtm.js'
                  }); var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                      'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', '${key}');`;
      document.head.appendChild(script);
    }
  }

  public static setGoogleTagManagerNoScript(key: string): void {
    console.log('GoogleTagManager ', key);
    if(key) {
      let iframe;
      let noscript;

      const validateKey = this.encodeHTML(key);

      noscript = document.createElement('noscript');
      iframe = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${validateKey}`;
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';
      iframe.height = '0';
      iframe.width = '0';
      document.body.appendChild(noscript).appendChild(iframe);
    }
  }

  public static encodeHTML(s) {
    return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;').replace(/'/g, '&#x27;').replace(/>/g,'&gt;');
  }

}
