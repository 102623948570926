import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PurchaseSummaryClientInfoComponent
} from '@payment-app/components/purchase-summary/purchase-summary-client-info/purchase-summary-client-info.component';
import {
  PurchaseSummaryPurchaseOrderComponent
} from '@payment-app/components/purchase-summary/purchase-summary-purchase-order/purchase-summary-purchase-order.component';
import {
  PurchaseSummaryTransactionInfoComponent
} from '@payment-app/components/purchase-summary/purchase-summary-transaction-info/purchase-summary-transaction-info.component';
import {MatButtonModule} from '@angular/material/button';
import {ApplicationPipesModule} from '@core/pipes/application-pipes.module';
import {ApplicationComponModule} from '../../../shared/ApplicationComponModule';
import {MatGridListModule} from '@angular/material/grid-list';

@NgModule({
  declarations: [
    PurchaseSummaryClientInfoComponent,
    PurchaseSummaryPurchaseOrderComponent,
    PurchaseSummaryTransactionInfoComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    ApplicationPipesModule,
    ApplicationComponModule
  ],
  exports: [
    PurchaseSummaryClientInfoComponent,
    PurchaseSummaryPurchaseOrderComponent,
    PurchaseSummaryTransactionInfoComponent,
    MatGridListModule,
    ApplicationComponModule
  ],
})
export class PurchaseSummaryModule { }
