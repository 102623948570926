import { Component, Input, OnInit } from '@angular/core';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';
import { PaymentezResponseSdkModel } from 'src/app/core/models/paymentez/paymentez-response-sdk.model';

@Component({
  selector: 'app-purchase-puntos-pdf',
  templateUrl: './purchase-puntos-pdf.component.html',
  styleUrls: ['./purchase-puntos-pdf.component.scss']
})
export class PurchasePuntosPdfComponent implements OnInit {
  @Input() response: PaymentezResponseSdkModel;
  @Input() paymentRequest: PaymentConfigModel;
  @Input() logo : ''
  constructor() { }

  ngOnInit(): void {
  }

}
