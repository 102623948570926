import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class NequiService {
    @Output() nequiPendingEmitter = new EventEmitter<any>();
    constructor(private http: HttpClient) { }

    generateNequiQR(request, token: string) {
        const headers = new HttpHeaders()
            .set('Authorization', token)
            .set('Content-Type', 'application/json');
        return this.http.post(`${environment.apiUrl}/api/nequi/generateNequiQR`, { data: request }, { headers });
    }

    verifyPayment(request: object, token: string): Observable<NequiResponse> {
        const headers = new HttpHeaders()
            .set('Authorization', token)
            .set('Content-Type', 'application/json');

        return this.http.post<NequiResponse>(
            `${environment.apiUrl}/api/nequi/getStatusNequiPayment`, { data: request }, { headers: headers }
        );
    }

    unregisteredPayment(request: object, token: string): Observable<NequiResponse> {
        const headers = new HttpHeaders()
            .set('Authorization', token)
            .set('Content-Type', 'application/json');

        return this.http.post<NequiResponse>(
            `${environment.apiUrl}/api/nequi/unregisteredPayment`, { data: request }, { headers: headers }
        );
    }

    //getTokenFunction
    getToken(reference: string, subclient: string = null, token: string): Observable<NequiResponse> {
        
            const headers = new HttpHeaders()
            .set('Authorization', token);

            const params = new HttpParams()
            .append('reference', reference)
            .append('subclient', subclient)
            
      
          return this.http.get<NequiResponse>(`${environment.apiUrl}/api/nequi/getToken`, { params, headers });
    }

    reverseTransaction(request: object, token: string): Observable<NequiResponse>{
        const headers = new HttpHeaders()
        .set('Authorization', token)
        .set('Content-Type', 'application/json');

        return this.http.post<NequiResponse>(
            `${environment.apiUrl}/api/nequi/reverseNequiTransaction`, { data: request }, { headers: headers }
        );
    }

    errorHandler(error: HttpErrorResponse){
        return observableThrowError(error)
    }

    nequiPending(flag: boolean){
        this.nequiPendingEmitter.emit(flag);
    }

}

//interface NequiResponse

export interface NequiResponse {
    status: NequiStatus;
    code: null | string;
    amount: null | string;
    transactionID: null | string;
    paymentDate: null | string;
    error: null | string;
    token: null | string;
    response: Response;
}

export interface error {
    errorCode?: string;
    errorMessage?: string;
}

export enum NequiStatus {
    PENDING = 'pending',
    APPROVED = 'approved',
    TIMEOUT = 'timeout',
    UNEXIST = 'unexist',
    CANCELED = 'canceled'
}

interface Response {
    paymentDate: any;
    transactionID: any;
    code: any;
    status: NequiStatus;
    unregisteredPaymentRS: { transactionId: string },
    ResponseMessage : {ResponseHeader:{Status:{StatusCode: string}}}
}