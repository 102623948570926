export class CreditCardPaymentezUtility {

  private static map: any[] = [
    { key: 'vi', value: 'VISA'},
    { key: 'mc', value: 'MASTERCARD'},
    { key: 'ax', value: 'AMERICAN EXPRESS'},
    { key: 'di', value: 'DINERS'},
    { key: 'dc', value: 'DISCOVER'},
    { key: 'el', value: 'ELO'},
    { key: 'cs', value: 'CREDISENSA'},
    { key: 'so', value: 'SOLIDARIO'},
    { key: 'ex', value: 'EXITO'},
    { key: 'ak', value: 'ALKOSTO'},
    { key: 'cd', value: 'CODENSA'},
    { key: 'sx', value: 'SODEXO'},
    { key: 'vr', value: 'VRBENEFICIOS'},
    { key: 'jc', value: 'JCB'},
    { key: 'au', value: 'AURA'}
  ];

  private static mapResult: any[] = [
    { key: 'pending', value: 'pendiente'},
    { key: 'failure', value: 'fallida'},
    { key: 'approved', value: 'aprobada'},
    { key: 'success', value: 'aprobada'},
    { key: 'cancelled', value: 'cancelada'},
    { key: 'rejected', value: 'rechazada'},
    { key: 'rechazado', value: 'rechazada'},
    { key: 'aprobado', value: 'aprobada'},  
    { key: 'pendiente', value: 'pendiente'},  
  ];

  public static getValue(key: string): string {
    let result = null;
    for(let m of this.map) {
      if(m.key === key){
        result = m.value;
        break;
      }
    }

    return result ? result : key;
  }

  public static getKey(value: string): string {
    let result = null;
    for(let m of this.map) {
      if(m.value === value){
        result = m.key;
        break;
      }
    }
    return result;
  }

  public static getValueResult(key: string): string {
    key = key.toLowerCase();
    let result = this.mapResult.find(x => x.key == key);
    if(result){
      return result.value;
    }
    return "";
  }

}
