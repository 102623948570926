import {Component, Input, OnInit} from '@angular/core';
import { DialogSummaryComponent } from './dialog-summary/dialog-summary.component';
import { MatDialog } from '@angular/material/dialog';
import {PaymentConfigModel} from "../../../core/models/payment-config.model";
import { EpaycoService } from 'src/app/core/services/epayco.service';

@Component({
  selector: 'app-mobile-product-data',
  templateUrl: './mobile-product-data.component.html',
  styleUrls: ['./mobile-product-data.component.scss']
})
export class MobileProductDataComponent implements OnInit {

  @Input() paymentRequest: PaymentConfigModel;

  constructor(public dialog: MatDialog, private epaycoService : EpaycoService) { }

  openDialog(): void {
    console.log(this.paymentRequest);
    const dialogRef = this.dialog.open(DialogSummaryComponent,  {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: this.paymentRequest
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  ngOnInit() {
  }

}
