import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';

import { PayDiraRequest, PayDiraResponse, DiraPayInvoiceResponse } from './pay-dira.interface';

@Injectable({
  providedIn: 'root'
})
export class PayDiraService {

  constructor(
    private readonly http: HttpClient
  ) {}

  createInvoice(body: PayDiraRequest, sessionToken): Observable<PayDiraResponse> {
    const urlBase = `${environment.apiUrl}/api/dira/invoice`;

    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${sessionToken}`)
      .set('Content-Type', 'application/json');

    return this.http.post<PayDiraResponse>(urlBase, body, { headers });
  }

  checkInvoice(diraId, sessionToken): Observable<DiraPayInvoiceResponse> {
    const urlBase = `${environment.apiUrl}/api/dira/invoice?diraId=${diraId}`;

    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${sessionToken}`)
      .set('Content-Type', 'application/json');

    return this.http.get<DiraPayInvoiceResponse>(urlBase, { headers });
  }
}
