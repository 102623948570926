import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { InfoPaymentMethodData } from './info-payment-method-dialog.service';
import { KeyValuePipe, NgForOf } from '@angular/common';

@Component({
  selector: 'info-payment-method-dialog',
  templateUrl: './info-payment-method-dialog.component.html',
  styleUrls: ['./info-payment-method-dialog.component.scss'],
  standalone: true,
  imports: [NgForOf, KeyValuePipe],
})
export class InfoPaymentMethodDialogComponent implements OnInit {
  title!: string;
  body!: SafeHtml;
  buttonText!: string;
  type!: string;
  isDebit = false;

  constructor(
    public dialog: MatDialogRef<InfoPaymentMethodDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected data: InfoPaymentMethodData,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.title = this.data.dialog.title;
    this.body = this.sanitizer.bypassSecurityTrustHtml(this.data.dialog.body);
    this.buttonText = this.data.dialog.button;
    this.isDebit = this.data.isDebit;
  }

  close() {
    this.dialog.close();
  }

  get cardImages() {
    const card = {
      AMERICAN_EXPRESS: 'assets/images/american-express.png',
      DINERS_CLUB: 'assets/images/diners.png',
      MASTERCARD: 'assets/images/mastercard.png',
      MASTERCARD_DEBIT: 'assets/images/mastercard_debit.png',
      VISA: 'assets/images/visa.png',
    };

    return Object.keys(this.data.cardFranchises).reduce((acc, key) => {
      const isDebit = key === 'MASTERCARD' && this.isDebit;

      if (this.data.cardFranchises[key]) {
        if (isDebit) {
          acc.push(card.MASTERCARD_DEBIT);
          return acc;
        }
        acc.push(card[key]);
      }
      return acc;
    }, []);
  }
}
