import { Component, OnInit, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { PaymentConfigModel } from '../../../../core/models/payment-config.model';
import { Base64 } from 'js-base64';
import { LoadingScreenService } from 'src/app/core/services/loading-screen.service';
import { SessionStorageService } from '../../../../core/services/session-storage.service';
import { EpaycoService } from 'src/app/core/services/epayco.service';
import { AuthModel } from '../../../../core/models/auth.model';
import { ValidateBonusService } from "../../../../core/services/validate-bonus.service";
import Swal from 'sweetalert2';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { PaymentTransactionModel } from 'src/app/core/models/paymentez/payment-transaction.model';
import { ChangeStatusService } from 'src/app/core/services/change-status.service';

declare const ePayco: any;
const url = environment.urlCdnPaymentez;
declare var window: any;
var nameCategory: any;
var btnPay: any;

@Component({
  selector: 'app-pay-checkout-epayco',
  templateUrl: './pay-checkout-epayco.component.html',
  styleUrls: ['./pay-checkout-epayco.component.scss'],
})
export class PayCheckoutEpaycoComponent implements OnInit {
  loadAPI: Promise<any>;
  enviado = false;
  urlReturn: any;
  urlConfirm: any;
  defaultPaymentMethods = ['SP', 'CASH', 'PSE', 'TDC', 'DP'];
  loading = false;

  @Input() paymentRequest: PaymentConfigModel;
  @Input() selectedOption: number;

  clicked = false;
  validatorRetentions: boolean = false;
  public auth;
  constructor(
    @Inject(DOCUMENT) document: any,
    private epaycoService: EpaycoService,
    private loadingScreenService: LoadingScreenService,
    private sessionStorageService: SessionStorageService,
    private validateBonusService: ValidateBonusService,
    private datalayerService: DatalayerService,
    private changeStatusService: ChangeStatusService,
  ) {
    this.urlReturn = environment.apiUrl + '/load/redirect-epayco-checkout';
    this.urlConfirm = environment.apiUrl + '/webhook/result-epayco';
    this.clicked = true;
    setTimeout(() => {
      this.loadAPI = new Promise((resolve) => {
        this.loadScript();
        resolve(true);
        this.clicked= false;
      });
    }, 5000);
  }

  public loadScript() {
    const nodeCheckout2 = document.createElement('script');
    nodeCheckout2.src = `https://checkout.epayco.co/checkout.js`;
    nodeCheckout2.type = 'text/javascript';
    nodeCheckout2.async = true;
    nodeCheckout2.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(nodeCheckout2);
  }

  ngOnInit(): void {
    this.epaycoService.validatedRetentions.subscribe(retentions =>{
      if (this.paymentRequest.retentions && !this.paymentRequest.retentions.message) {
        this.validatorRetentions = retentions;
      }
      if (this.paymentRequest.retentions && this.paymentRequest.retentions.message) {
        this.paymentRequest.retentions = null;
      }
    })
   }

  async openCheckout() {
    const bonusResult = await this.validarBono(this.paymentRequest);
    const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    const body = {
      dev_reference: this.paymentRequest.data.reference ? this.paymentRequest.data.reference : null
    }
    await this.changeStatusService.changeStatus(auth ,this.paymentRequest, this.selectedOption == 6 ? "Daviplata" : "DAVIPUNTOS_Y_O_CREDITO", body);
    this.loading = true;

    //datalayer boton de pago
    let amountDatalayer = this.paymentRequest.retentions ? this.paymentRequest.retentions.total_amount : this.paymentRequest.data.amount
    let textButton = `PAGAR ${amountDatalayer}`;
    this.datalayerService.AddInfoDataLayerPaymentButton(textButton);
    // this.loadingScreenService.startLoading();
    if(bonusResult || !(this.paymentRequest.manual_bonus && this.paymentRequest.manual_bonus_voucher)){

      this.clicked = true;

      const handler = ePayco.checkout.configure({
        key: this.paymentRequest.epayco_checkout_key,
        test: this.paymentRequest.epayco_checkout_is_test,
      });
      const vat = this.paymentRequest.data.vat;
      let tax = 0;
      let taxBase = 0;

      if (vat > 0) {
        let aux_amount = (this.paymentRequest.retentions ? this.paymentRequest.retentions.total_amount : this.paymentRequest.data.amount);
        taxBase = aux_amount / (1 + (vat / 100));
        tax = aux_amount - taxBase;
      }

      // const invoice = btoa(this.paymentRequest.data.payment_reference + ':' + this.paymentRequest.name + ':' + Math.floor(Date.now() / 1000));
      const invoice = btoa(this.paymentRequest.data.payment_reference + '-' + '0' + ':' + this.paymentRequest.name);

      let disabledPaymentMethods = this.paymentRequest.epayco_checkout_disabled_methods;
      if (this.selectedOption == 6) {
        disabledPaymentMethods = ['SP', 'CASH', 'PSE', 'TDC'];
      }
      this.addInfoDataLayerPay()
      const data = {
        // Parametros compra (obligatorio)
        name: `${this.paymentRequest.data.name} ${this.paymentRequest.data.last_name}`,
        description: this.paymentRequest.data.description,
        invoice,
        currency: 'COP',
        amount: this.paymentRequest.retentions ? this.paymentRequest.retentions.total_amount : this.paymentRequest.data.amount,
        tax_base: taxBase,
        tax,
        country: 'CO',
        lang: 'es',

        // Onpage="false" - Standard="true"
        external: 'false',
        confirmation: this.urlConfirm,
        response: this.urlReturn,
        extra1: "isCheckout",
        extra6 :this.paymentRequest.data.client,
        extra7 : this.paymentRequest.data.subclient,
        // Atributos cliente
        name_billing: `${this.paymentRequest.data.name} ${this.paymentRequest.data.last_name}`,
        type_doc_billing: this.paymentRequest.data.id_type,
        mobilephone_billing: this.paymentRequest.data.phone,
        number_doc_billing: this.paymentRequest.data.id,

        // atributo deshabilitación metodo de pago
        methodsDisable: disabledPaymentMethods ? disabledPaymentMethods : this.defaultPaymentMethods,
        email_billing: this.paymentRequest.data.email
      };
      handler.open(data);

      setTimeout(() => {
        this.clicked = false;
        this.loadingScreenService.stopLoading();
      }, 1000);
      this.loading = false;
    }
    else{
      this.epaycoService.cancelBonus();
      this.loading = false;
      return false;
    }
  }

  public addInfoDataLayerPay(){
    nameCategory = this.paymentRequest.data.client;
    btnPay = this.paymentRequest.data.description;

    window.dataLayer.push({
      eventCategory: nameCategory + " - Intención de pago",
      eventAction: 'Pago con DAVIPUNTOS',
      eventLabel: btnPay,
      eventValue: '',
      event:'eventClick'
    });

  }

  private validarBono(paymentRequest){
    return new Promise<any>((resolve, reject) => {
      this.auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
      const req = {
        client: paymentRequest.name,
        amount: paymentRequest.aux_init_amount,
        dev_reference: paymentRequest.data.reference,
        bonus_voucher: paymentRequest.manual_bonus_voucher,
        id_type: paymentRequest.data.id_type,
        vat: paymentRequest.data.vat,
        rete_ica: paymentRequest.data.rete_ica,
        rete_iva : paymentRequest.data.rete_iva,
        rete_fuente : paymentRequest.data.rete_fuente,
        // id_product : paymentRequest.data.id_producto,
        external_user_id : paymentRequest.data.external_user_id,
        token_external : paymentRequest.data.token_external,
        retentions : paymentRequest.data.retentions,
        name : paymentRequest.data.name,
        last_name : paymentRequest.data.last_name,
        email : paymentRequest.data.email,
        phone : paymentRequest.data.phone,
        manual_bonus : true,
        external_user_doc : paymentRequest.data.id,
        external_user_email : paymentRequest.data.email,
        products : paymentRequest.data.products ? paymentRequest.data.products : null
      }
      if(paymentRequest.manual_bonus_voucher){
        this.validateBonusService.validBonus(req, "Bearer " + this.auth.token).subscribe(res =>{
          if (res.bonus.code == 200) {
            paymentRequest.retentions = res.retentions;
            this.epaycoService.validatedRetentions.subscribe(retentions =>{
              if (this.paymentRequest.retentions && !this.paymentRequest.retentions.message) {
                this.validatorRetentions = retentions;
              }
              if (this.paymentRequest.retentions && this.paymentRequest.retentions.message) {
                this.paymentRequest.retentions = null;
              }
              if(retentions){
                paymentRequest.data.amount = res.retentions ? res.retentions.total_amount : res.bonus.result.total;
              }
              else{
                paymentRequest.data.amount = res.bonus.result.total;
              }
            })
            paymentRequest.aplly_bonus = true;
            paymentRequest.voucher_name = res.bonus.result.type_discount;
            paymentRequest.manual_bonus = true;
            resolve(true);
         } else {
            Swal.fire({
              title: 'Bono expirado',
              text: 'Este bono ya expiró, por favor intente con uno nuevo o realice el pago total de la transacción',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-custom-primary'
              },
              confirmButtonText: 'Confirmar'
            })
            resolve(false);
          }
        })
      }
      else {
        resolve(false);
      }
    })
  }

  addToDataLayerCheckoutEvent(method: string, paymentRequest){
    this.datalayerService.addToDataLayerCheckoutEvent(method, paymentRequest);
  }

}
