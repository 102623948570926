export const PAYMENT = "pagos."
export const DEV = "dev-";
export const QA = "qa-";

export const icons = {
  "APROBADO": "check_circle",
  "PENDIENTE": "warning",
  "RECHAZADO": "error",
  "CANCELADO": "settings_backup_restore",
  "REVERSADO": "settings_backup_restore",
  "INTENTADO": "do_not_disturb_on",
  "ENVIADO A PSE": "av_timer",
  'REVERSO_PENDIENTE': 'history',
  'LOADED': 'cloud_done',
  true: "check_circle",
  false: "error"
}

export const url_bolivar = 'https://www.serviciosbolivar.com';