import {
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForOf } from '@angular/common';
import {
  MatExpansionModule,
  MatExpansionPanel,
} from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { Subscription } from 'rxjs';

import { PaymentRequestService } from '../../../../payment/payment-request/payment-request.service';
import { FormPayment, PaymentMethod } from '../../../core/models';
import { RetentionsService } from '../../info-amount-to-pay/components/retentions/retentions.service';
import { InfoPaymentMethodDialogService } from '../../info-payment-method-dialog/info-payment-method-dialog.service';

@Component({
  selector: 'expansion-panel',
  standalone: true,
  imports: [NgForOf, MatExpansionModule, MatRadioModule, MatIconModule],
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent implements OnInit, OnDestroy {
  @Input() paymentMethod!: PaymentMethod;
  @ViewChild(MatExpansionPanel, { static: true })
  expansionPanel!: MatExpansionPanel;
  #paymentRequestService = inject(PaymentRequestService);
  #retentionsService = inject(RetentionsService);
  #infoPaymentMethodDialogService = inject(InfoPaymentMethodDialogService);
  #expansionPanelSubscription!: Subscription;

  ngOnInit(): void {
    this.expansionPanelListener();
  }

  ngOnDestroy(): void {
    this.#expansionPanelSubscription?.unsubscribe();
  }

  onOpenHelpDialog(): void {
    this.#infoPaymentMethodDialogService.open({
      dialog:
        this.#paymentRequestService.paymentRequest.dialogs[
          this.paymentMethod.helpText
        ],
      cardFranchises:
        this.#paymentRequestService.paymentRequest.card_franchises,
      isDebit:
        this.paymentMethod.name === FormPayment.DEBIT_CARD ||
        this.paymentMethod.name === FormPayment.BANK_TRANSFER,
    });
  }

  private expansionPanelListener() {
    this.#expansionPanelSubscription = this.expansionPanel.opened.subscribe(
      () => {
        this.#retentionsService.showInfo =
          this.paymentMethod.name !== FormPayment.CREDIT_CARD;
      },
    );
  }
}
