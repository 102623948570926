import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'bin-bonus',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bin-bonus.component.html',
  styleUrls: ['./bin-bonus.component.scss'],
})
export class BinBonusComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
