import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PaymentRequestComponent } from "./payment-request/payment-request.component";
import { PaymentComponent } from "./payment.component";
import { PaymentLoadComponent } from "./payment-load/payment-load.component";
import { PaymentMessageComponent } from "./payment-message/payment-message.component";
import { PaymentResponseComponent } from "./payment-response/payment-response.component";
import { PurchaseCreditCardComponent } from "./payment-response/purchase-credit-card/purchase-credit-card.component";
import { PaymentDavipuntosComponent } from './payment-davipuntos/payment-davipuntos.component';
import { PaymentReturnUrlComponent } from './payment-return-url/payment-return-url.component';
import {ReturnBrowserGuard} from "../guard/return-browser.guard";
import {ReturnProcessGuard} from "../guard/return-process.guard";

const paymentRoutes: Routes = [
  {
    path: '',
    component: PaymentComponent,
    children: [
      {
        path: '',
        children: [
          { path: 'solicita', component: PaymentLoadComponent },
          { path: 'procesa', component: PaymentRequestComponent, canDeactivate: [ReturnProcessGuard]},
          { path: 'mensaje', component: PaymentMessageComponent },
          { path: 'respuesta', component: PaymentResponseComponent, canDeactivate: [ReturnBrowserGuard] },
          { path: 'prueba', component: PurchaseCreditCardComponent },
          // Payment Davipuntos Mixed Purchase
          { path: 'davipuntos-redirect', component: PaymentDavipuntosComponent },
          { path: '', component: PaymentLoadComponent },
          { path: 'payment-return', component: PaymentReturnUrlComponent}
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(paymentRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PaymentRoutingModule {
}
