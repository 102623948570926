import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import {ClaroResponse, ClaroRequest, DataResponse} from './pay-claro-pay.interface';
import { EncryptService } from '../../../../core/services/encrypt.service';

@Injectable({
  providedIn: 'root'
})

export class PayClaroPayService {
  private readonly sessionToken: string;

  constructor(
    private readonly http: HttpClient,
    private encryptService: EncryptService
  ) {
  }

  createPaymentRequest(body: ClaroRequest, sessionToken): Observable<ClaroResponse> {
    const urlBase = `${environment.apiUrl}/api/claro_pay/create`;
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${sessionToken}`)
      .set('Content-Type', 'application/json');

    const data = this.encryptService.encryptCipherData(body);
    return this.http.post<ClaroResponse>(urlBase, { data }, { headers });
  }

  confirmStatus(idempotencyToken: string, sessionToken) {
    const urlBase = `${environment.apiUrl}/api/claro_pay/confirm_status`;
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${sessionToken}`)
      .set('Content-Type', 'application/json');

    return this.http.get<ClaroResponse>(urlBase, {
      headers, params: {
        idempotencyToken
      }
    });
  }

  confirmDelivery(idempotencyToken: string, sessionToken) {
    const urlBase = `${environment.apiUrl}/api/claro_pay/confirm_delivery`;
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${sessionToken}`)
      .set('Content-Type', 'application/json');

    return this.http.post<ClaroResponse>(urlBase, { 'idempotencyToken': idempotencyToken }, {
      headers
    });
  }

}
