import {Injectable} from '@angular/core';
import {SessionStorageService} from './session-storage.service';

interface Message {
  fn: string;
  message: {
    flagGateway: string,
    tokenpm: string
  };
}

@Injectable({
  providedIn: 'root'
})

export class PostMessageService {
  timeOut = 5000;
  attempts = 0;
  intervals = [];

  constructor(private sessionStorageService: SessionStorageService) {
  }

  send(flag = false, postMessage = null, keepAlive = true) {
    const tokenPm = this.sessionStorageService.getItem<string>(SessionStorageService.TOKEN_PM);

    if (keepAlive) {
      if (tokenPm) {
        if (postMessage) {
          postMessage["message"]["tokenpm"] = tokenPm;
          this.sendMessage(postMessage);
        } else {
          const message: Message = {
            fn: "appActivity",
            message: {
              flagGateway: flag.toString(),
              tokenpm: tokenPm,
            },
          };

          this.attempts = 0;

          this.sendMessage(message);

          if (!flag) {
            this.intervals.push(this.interval(message));
          }
        }


        if (flag) {
          this.intervals.forEach(interval => {
            clearInterval(interval);
          });
        }
      }
    } else {
      this.intervals.forEach(interval => {
        clearInterval(interval);
      });
    }


  }

  private interval(message: Message) {
    return setInterval(() => {
      this.sendMessage(message);
    }, this.timeOut);
  }

  private sendMessage(message: Message) {
    this.attempts++;
    const targetw = window as any;
    const emitter = (targetw.ReactNativeWebView as unknown as any) || targetw;
    try {
      emitter.postMessage(JSON.stringify(message));
    } catch (e) {
      alert(`error lanzando el mensaje ${e}`);
      console.log('error ' + e);
    }
    console.log('message ' + this.attempts, message);
  }
}
