import { Component, inject, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

import { EpaycoService } from '@core/services/epayco.service';
import { PaymentConfigModel } from '@core/models/payment-config.model';
import { PaymentTransactionModel } from '@core/models/payment-transaction.model';
import { DetailModel } from '@core/models/detail-payment.model';
import { ValidateBonusService } from '@core/services/validate-bonus.service';
import { SessionStorageService } from '@core/services/session-storage.service';
import { AuthModel } from '@core/models/auth.model';
import { LoadingScreenService } from '@core/services/loading-screen.service';
import { DaviplataService } from '@core/services/daviplata.service';
import { EpaycoApifyService } from '@core/services/epayco-apify.service';

import { RetentionsService } from '@payment-app/components/info-amount-to-pay/components/retentions/retentions.service';

@Component({
  selector: 'app-product-data',
  templateUrl: './product-data.component.html',
  styleUrls: ['./product-data.component.scss'],
})
export class ProductDataComponent implements OnInit {
  @Input() paymentRequest: PaymentConfigModel;
  @Input() infoRequest: DetailModel;
  public description;
  activateChip: boolean;
  activateMixed: boolean;
  mixed_purchase: boolean = false;
  showManualBonus: boolean;
  disableButton: boolean = false;
  activateExternalToken: boolean = false;
  activateBonus: boolean = false;
  validatorRetentions: boolean = false;
  hideButton: boolean = false;
  public bonoManual: string = null;
  public validateMixedPurchase: number;

  #service = inject(RetentionsService);
  showInfo = false;

  response: PaymentTransactionModel = {
    transaction: null,
    user: null,
    err: null,
    message: null,
  };
  public auth;
  paymentTransactionService: any;

  constructor(
    private validateBonusService: ValidateBonusService,
    private loadingScreenService: LoadingScreenService,
    private epaycoService: EpaycoService,
    private daviplataService: DaviplataService,
    private sessionStorageService: SessionStorageService,
    private epaycoApify: EpaycoApifyService,
  ) {}

  ngOnInit() {
    if (this.paymentRequest.aplly_bonus) {
      this.activateChip = true;
    }
    if (this.paymentRequest.mixed_purchase_data) {
      this.mixed_purchase = this.paymentRequest.data.mixed_purchase;
    }
    if (
      this.paymentRequest.data.token_external &&
      this.paymentRequest.data.origen
    ) {
      this.activateExternalToken = true;
    }
    this.description = this.paymentRequest.data.description;
    this.epaycoService.activateMixed.subscribe((activate) => {
      this.activateMixed = activate;
    });

    this.epaycoService.activateChip.subscribe((activate) => {
      this.activateChip = activate;
    });
    this.epaycoService.showManualBonus.subscribe((activate) => {
      this.showManualBonus = activate;
    });
    this.epaycoService.changeAmountInitial.subscribe((initialAmount) => {
      this.paymentRequest.data.amount = initialAmount;
    });

    this.epaycoService.changeAmountInitialMixedPurchase.subscribe(
      (initialAmountMixedPurchase) => {
        this.paymentRequest.data.amount_aux_mixed_purchase =
          initialAmountMixedPurchase;
      },
    );

    this.epaycoService.validatedRetentions.subscribe((retentions) => {
      if (
        this.paymentRequest.retentions &&
        !this.paymentRequest.retentions.message
      ) {
        this.validatorRetentions = retentions;
      }
      if (
        this.paymentRequest.retentions &&
        this.paymentRequest.retentions.message
      ) {
        this.paymentRequest.retentions = null;
      }
      if (this.validatorRetentions) {
        // this.paymentRequest.data.amount = this.paymentRequest.retentions.amount;
      }
      // else{
      // this.paymentRequest.data.amount = this.paymentRequest.data.amount;
      // }
    });
    this.epaycoService.cancel_bonus.subscribe((res) => {
      if (res) {
        this.cancelBonus();
      }
    });
    this.epaycoApify.cancel_bonus.subscribe((res) => {
      if (res) {
        this.cancelBonus();
      }
    });
    this.daviplataService.cancel_bonus.subscribe((res) => {
      if (res) {
        this.cancelBonus();
      }
    });
    let bonus_manual = document.getElementById('bonoManual');

    this.#service.showInfo$.subscribe((showInfo) => {
      this.showInfo = !showInfo;
    });
  }

  aplicateBonus() {
    this.disableButton = true;
    this.loadingScreenService.startLoading();
    this.paymentRequest.aplly_bonus = true;
    this.paymentRequest.manual_bonus = true;
    let bonus = document.getElementById('bonoManual');
    this.paymentRequest.manual_bonus_voucher = (<HTMLInputElement>bonus).value;
    this.auth = this.sessionStorageService.getItem<AuthModel>(
      SessionStorageService.AUTH,
    );
    const req = {
      client: this.paymentRequest.name,
      amount: this.paymentRequest.aux_init_amount,
      dev_reference: this.paymentRequest.data.reference,
      bonus_voucher: this.paymentRequest.manual_bonus_voucher,
      id_type: this.paymentRequest.data.id_type,
      vat: this.paymentRequest.data.vat,
      rete_ica: this.paymentRequest.data.rete_ica,
      rete_iva: this.paymentRequest.data.rete_iva,
      rete_fuente: this.paymentRequest.data.rete_fuente,
      // id_product : this.paymentRequest.data.id_producto,
      external_user_id: this.paymentRequest.data.external_user_id,
      token_external: this.paymentRequest.data.token_external,
      retentions: this.paymentRequest.data.retentions,
      name: this.paymentRequest.data.name,
      last_name: this.paymentRequest.data.last_name,
      email: this.paymentRequest.data.email,
      phone: this.paymentRequest.data.phone,
      manual_bonus: true,
      external_user_doc: this.paymentRequest.data.id,
      external_user_email: this.paymentRequest.data.email,
      products: this.paymentRequest.data.products
        ? this.paymentRequest.data.products
        : null,
    };

    this.validateBonusService
      .validBonus(req, 'Bearer ' + this.auth.token)
      .subscribe((res) => {
        if (res.bonus.code == 200) {
          this.paymentRequest.retentions = res.retentions;
          if (res.retentions) {
            this.epaycoService.validatedRetentions.emit(true);
          } else {
            this.epaycoService.validatedRetentions.emit(false);
          }
          if (this.validatorRetentions) {
            this.paymentRequest.data.amount = res.retentions
              ? res.retentions.total_amount
              : res.bonus.result.total;
          } else {
            this.paymentRequest.data.amount = res.bonus.result.total;
          }
          this.paymentRequest.aplly_bonus = true;
          this.paymentRequest.voucher_name = res.bonus.result.type_discount;
          this.epaycoService.activateChip.emit(true);
          this.epaycoApify.activateChip.emit(true);
          this.paymentRequest.manual_bonus = true;
          this.epaycoService.BuildJsonCard(res.bonus, this.paymentRequest);
        } else if (res.bonus.code == 422) {
          let trollyMessage = '';
          this.paymentRequest.manual_bonus = false;
          this.epaycoService.activateChip.emit(false);
          this.epaycoApify.activateChip.emit(false);
          for (const key in res.bonus.messages) {
            trollyMessage += res.bonus.messages[key] + '\n';
          }
          Swal.fire({
            title: 'Problemas con el Bono',
            text: trollyMessage.toString(),
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-custom-primary',
            },
            confirmButtonText: 'Confirmar',
          });
        } else {
          this.paymentRequest.manual_bonus = false;
          this.epaycoService.activateChip.emit(false);
          this.epaycoApify.activateChip.emit(false);
          Swal.fire({
            title: 'Problemas con el bono',
            text: 'Parece que este bono ya no puede ser aplicado, por favor intente con uno nuevo o realice el pago total de la transacción',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-custom-primary',
            },
            confirmButtonText: 'Confirmar',
          });
        }
        this.loadingScreenService.stopLoading();
        this.disableButton = false;
      });
  }

  bonusManual() {
    if (this.bonoManual && this.bonoManual.length > 0) {
      this.hideButton = true;
    } else {
      this.hideButton = false;
    }
  }

  cancelBonus() {
    this.paymentRequest.aplly_bonus = false;
    this.paymentRequest.manual_bonus = false;
    this.hideButton = true;
    this.bonoManual = null;
    let bonus = document.getElementById('bonoManual');
    this.auth = this.sessionStorageService.getItem<AuthModel>(
      SessionStorageService.AUTH,
    );
    const req = {
      client: this.paymentRequest.name,
      amount: this.paymentRequest.aux_init_amount,
      dev_reference: this.paymentRequest.data.reference,
      bonus_voucher: (<HTMLInputElement>bonus).value,
      id_type: this.paymentRequest.data.id_type,
      vat: this.paymentRequest.data.vat,
      rete_ica: this.paymentRequest.data.rete_ica,
      rete_iva: this.paymentRequest.data.rete_iva,
      rete_fuente: this.paymentRequest.data.rete_fuente,
      id_product: this.paymentRequest.data.id_producto,
      external_user_id: this.paymentRequest.data.external_user_id,
      token_external: this.paymentRequest.data.token_external,
      retentions: this.paymentRequest.data.retentions,
      name: this.paymentRequest.data.name,
      last_name: this.paymentRequest.data.last_name,
      email: this.paymentRequest.data.email,
      phone: this.paymentRequest.data.phone,
      manual_bonus: false,
    };
    this.validateBonusService
      .validBonus(req, 'Bearer ' + this.auth.token)
      .subscribe((res) => {
        // this.paymentRequest.retentions = res.retentions;
        this.paymentRequest.data.amount = this.paymentRequest.aux_init_amount;
        this.paymentRequest.aplly_bonus = false;
        this.paymentRequest.voucher_name = res.bonus.result.type_discount;
        this.epaycoService.activateChip.emit(false);
        this.epaycoApify.activateChip.emit(false);
        this.paymentRequest.manual_bonus = false;
        this.paymentRequest.manual_bonus_voucher = null;
        this.epaycoService.BuildJsonCard(res.bonus, this.paymentRequest);
      });
    this.bonusManual();
  }
}
