import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthModel } from 'src/app/core/models/auth.model';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';
import { PaymentezResponseSdkModel } from 'src/app/core/models/paymentez/paymentez-response-sdk.model';
import { AuditService } from 'src/app/core/services/audit.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { CreditCardPaymentezUtility } from 'src/app/core/utilities/credit-card-paymentez.utility';

@Component({
  selector: "app-purchase-puntos-colombia",
  templateUrl: "./purchase-puntos-colombia.component.html",
  styleUrls: ["./purchase-puntos-colombia.component.scss"],
})
export class PurchasePuntosColombiaComponent implements OnInit {
  @Input() paymentRequest: PaymentConfigModel;

  @Input() response: PaymentezResponseSdkModel;

  @Output() imprimir: EventEmitter<any>;

  status: string = "";
  paymentStatus;
  authToken;
  url_retry;
  url_return;
  puntosRedim: boolean = true;
  backOrDetails: string = "Volver a comercio";
  urlProblem: string = "";

  constructor(
    private datalayerService: DatalayerService,
    private auditService: AuditService,
    private sessionStorageService: SessionStorageService
  ) {
    this.imprimir = new EventEmitter();
  }

  ngOnInit(): void {
    this.status = CreditCardPaymentezUtility.getValueResult(
      this.response.transaction.status
    );

    let auth = this.sessionStorageService.getItem(SessionStorageService.ORDEN);
    this.authToken = this.sessionStorageService.getItem<AuthModel>(
      SessionStorageService.AUTH
    );
    this.url_retry = this.sessionStorageService.getItem(
      SessionStorageService.URL_PAYMENT_REQUEST
    );
    this.url_return = auth["url_return"];
    if (
      this.response.transaction.status === "RECHAZADO" ||
      this.response.transaction.status === "failure"
    ) {
      this.urlProblem = this.sessionStorageService.getItem<string>(
        SessionStorageService.URL_RETURN_PROBLEM
      );
      this.backOrDetails = this.urlProblem
        ? "MAS DETALLES"
        : this.backOrDetails;
    }
  }

  returnClient() {
    this.datalayerService.addInfoDataLayerPurchaseResume(
      "Volver a comercio",
      this.paymentRequest.name,
      null,
      this.paymentRequest.name == "doctoraki"
        ? this.response.transaction.status
        : null
    );
    this.validateStatus();
    const request = {
      dev_reference: this.paymentRequest.data.reference,
      client: this.paymentRequest.data.client,
      subclient: this.paymentRequest.data.subclient,
      leave_type: "Salida por Boton",
      payment_status: this.paymentStatus,
      leave_page: true,
    };

    this.auditService
      .saveAudit(request, this.authToken.token)
      .subscribe((res) => {
        window.location.href = this.url_return;
      });
  }

  validateStatus() {
    if (this.response.transaction.status == "failure") {
      this.paymentStatus = "RECHAZADO";
      this.url_return = this.urlProblem ?? this.url_return;
    } else if (this.response.transaction.status == "pending") {
      this.paymentStatus = "PENDIENTE";
    } else {
      this.paymentStatus = "APROBADO";
    }
  }

  retryPayment() {
    this.datalayerService.addInfoDataLayerPurchaseResume(
      "Reintentar el pago",
      this.paymentRequest.name,
      null,
      this.paymentRequest.name == "doctoraki"
        ? this.response.transaction.status
        : null
    );
    window.location.href = this.url_retry + "&reload=" + +new Date().getTime();
  }

  printPdf() {
    this.imprimir.emit(true);
  }
}
