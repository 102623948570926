import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateSubclientDialogComponent } from 'src/app/subclient/create-subclient-dialog/create-subclient-dialog.component';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  @Input() titulo = '¿estas seguro?';
  @Input() mensaje: string = null;
  @Input() cancelButtonText = 'Cancelar';
  @Input() confirmButtonText = 'Confirmar';
  @Output() onConfirm = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<CreateSubclientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data) {
      this.titulo = this.data.titulo;
      this.mensaje = this.data.mensaje;
      this.cancelButtonText = this.data.cancelButtonText ? this.data.cancelButtonText : this.cancelButtonText;
      this.confirmButtonText = this.data.confirmButtonText ? this.data.confirmButtonText : this.confirmButtonText;
    }
  }

  ngOnInit() {
  }

  confirm(){
    const data = 'Your data';
    this.onConfirm.emit(data);
  }

  close(){
    this.dialogRef.close();
  }

}
