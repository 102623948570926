import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentezCheckoutComponent } from './paymentez-checkout.component';

@NgModule({
  declarations: [PaymentezCheckoutComponent],
  imports: [
    CommonModule
  ],
  exports: [
    PaymentezCheckoutComponent
  ]
})
export class PaymentezCheckoutModule { }
