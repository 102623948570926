import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from '../core/services/session-storage.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  cookie: boolean = true;
  isProd: boolean = false;
  constructor(
    private sessionStorageService: SessionStorageService

  ) {
    if (this.sessionStorageService.getLocalItem(SessionStorageService.COOKIE_PAYMENT) === 'true') {
      this.cookie = false;
    }
    this.loadPaymentRequest();
  }

  loadPaymentRequest() {
    if (environment.apiUrl == "https://api-gateway.solucionesbolivar.com") {
      this.isProd = true;
    }
  }

  ngOnInit() {

  }

  close() {
    this.cookie = false;
  }

  savecookie() {
    this.cookie = false;
    const dateNow = new Date();
    dateNow.setDate(dateNow.getDate() + 365); //guardar la cookie por un año
    this.sessionStorageService.setLocalItem(SessionStorageService.COOKIE_PAYMENT, 'true');
  }


}