import { Injectable,Output, EventEmitter } from '@angular/core';
import { PaymentezRequestModel } from '../models/paymentez/paymentez-request.model';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PaymentTransactionModel } from '../models/paymentez/payment-transaction.model';

@Injectable({
  providedIn: 'root'
})
export class DaviplataService {
  @Output() cancel_bonus: EventEmitter<any> = new EventEmitter();
  constructor(
    private http: HttpClient
  ) { }

  requestPayWithToken(paymentRequestModel: any, base64: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', base64)
      .set('Content-Type', 'application/json');
    return this.http.post(`${environment.apiUrl}/api/buy/daviplata`, paymentRequestModel, { headers });
  }

  confirmPayWithOTP(request, base64: string): any {

    const headers = new HttpHeaders()
      .set('Authorization', base64)
      .set('Content-Type', 'application/json');
    return this.http.post(`${environment.apiUrl}/api/confirm-buy/daviplata`, request, { headers });

    /*return new Observable<any>((observer) => {

      let response: any = {
        fechaTransaccion: "2013-03-01T12:41:06",
        numAprobacion: "604107",
        estado: "Aprobado",
        idTransaccionAutorizador: "4802"
      }

      setTimeout(() => {
        observer.next(
          response
        );
        observer.complete();
      }, 3000);
      return { unsubscribe() { } };
    });*/
  }
  cancelBonus(ref){
    this.cancel_bonus.emit(ref);
  }
}
