import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-animations-example-dialog',
  templateUrl: 'dira.iframe.component.html',
})
export class DiraIframeComponent implements OnInit {
  urlSafe: SafeResourceUrl;

  constructor(
    public dialogRef: MatDialogRef<DiraIframeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { url: string },
    private readonly sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }
}
