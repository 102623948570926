import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {PaymentConfigModel} from "../../../core/models/payment-config.model";
import {PaymentezComponent} from "../paymentez.component";

@Component ({
  selector: 'app-paymentez-dialog',
  templateUrl: './paymentez-dialog.component.html',
  styleUrls: ['./paymentez-dialog.component.scss']
})
export class PaymentezDialogComponent implements OnInit {

  paymentRequest: PaymentConfigModel;
  paymentMethod: number;

  constructor(
    public dialogRef: MatDialogRef<PaymentezComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.paymentRequest = this.data.payment;
    this.paymentMethod = this.data.methodPayment;
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
