import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'fury-snack-message',
  templateUrl: './snack-message.component.html',
  styleUrls: ['./snack-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SnackMessageComponent {

  constructor(
    public snackBarRef: MatSnackBarRef<SnackMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }

}
