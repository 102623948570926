import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PaymentConfigModel } from '../models/payment-config.model';
import { PaymentTransactionModel } from '../models/paymentez/payment-transaction.model';
import { LoadingScreenService } from './loading-screen.service';
import { PaymentTransactionService } from './payment-transaction.service';
import { Router } from '@angular/router';
import { SessionStorageService } from './session-storage.service';
import { AuthModel } from '../models/auth.model'
import { AuthService } from './auth.service';
import { AuditService } from './audit.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentezComponent } from '../../gateway/paymentez/paymentez.component';
import { PaymentezResponseStatusTransferModel } from '../models/paymentez/paymentez-response-status-transfer.model';
import { OrderPaymentezModel } from '../models/paymentez/order-paymentez.model';
import { EncryptService } from './encrypt.service';
import * as publicIp from "public-ip";
import { ValidateBonusService } from "./validate-bonus.service";
import Swal from 'sweetalert2';
import { ChangeStatusService } from './change-status.service';
import { AuditErrorService } from './audit-error.service';
import { PurchasePaymentMixComponent } from 'src/app/gateway/paymentez/paymentez-dialog/pay-mixed/purchase-payment-mix/purchase-payment-mix.component';
declare const ePayco: any;
declare var jQuery: any;
declare var $: any;


@Injectable({
  providedIn: "root",
})
export class EpaycoService {
  @Output() message: EventEmitter<any> = new EventEmitter();
  @Output() activateChip: EventEmitter<any> = new EventEmitter();
  @Output() activateMixed: EventEmitter<any> = new EventEmitter();
  @Output() showManualBonus: EventEmitter<any> = new EventEmitter();
  @Output() activeCreditCard: EventEmitter<any> = new EventEmitter();
  @Output() changeAmountInitial: EventEmitter<any> = new EventEmitter();
  @Output() changeAmountInitialMixedPurchase: EventEmitter<any> =
    new EventEmitter();
  @Output() savedCardExpanded: EventEmitter<any> = new EventEmitter();
  @Output() acordionCardsTokenize: EventEmitter<any> = new EventEmitter();
  @Output() cardBin: EventEmitter<any> = new EventEmitter();
  @Output() cardBinDebit: EventEmitter<any> = new EventEmitter();
  @Output() validatedRetentions: EventEmitter<any> = new EventEmitter();
  @Output() token_external: EventEmitter<any> = new EventEmitter();
  @Output() cancel_bonus: EventEmitter<any> = new EventEmitter();

  private dialogRef: MatDialogRef<PaymentezComponent>;
  private flagTimeout = false;
  private ipAddress = "";
  public auth;
  public auditError = {
    error: null,
    dev_message: null,
  };
  loading = false;
  constructor(
    private http: HttpClient,
    private loadingScreenService: LoadingScreenService,
    private paymentTransactionService: PaymentTransactionService,
    private sessionStorageService: SessionStorageService,
    private _router: Router,
    private auditService: AuditService,
    private auditErrorService: AuditErrorService,
    private authService: AuthService,
    private validateBonusService: ValidateBonusService,
    private changeStatusService: ChangeStatusService,
    private encryptService: EncryptService,
    public dialog: MatDialog
  ) {
    this.loadEpaycoApi();
    //*se obtiene la IP publica del usuario*
    publicIp
      .v4()
      .then((data) => {
        this.ipAddress = data;
      })
      .catch((failure) => {
        console.log(failure);
        this.ipAddress = environment.defaultIp;
      });
  }

  loadEpaycoApi() {
    let node2 = document.createElement("script");
    node2.src = `https://checkout.epayco.co/epayco.min.js?updated=${Math.random()
      .toString(16)
      .slice(2)}`;
    node2.type = "text/javascript";
    node2.async = true;
    node2.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node2);
  }

  setDialog(dialogRef) {
    this.dialogRef = dialogRef;
  }
  async transactionPSE(
    token: string,
    request: any,
    paymentRequest: PaymentConfigModel
  ) {
    this.loading = true;
    const bonusResult = await this.validarBono(paymentRequest);
    const auth = this.sessionStorageService.getItem<AuthModel>(
      SessionStorageService.AUTH
    );
    const body = {
      id_type: request.type_fis_number ? request.type_fis_number : null,
      id: request.fiscal_number,
      type_user: request.type ? request.type : null,
      bank: request.bank ? request.bank : null,
      dev_reference: paymentRequest.data.reference
        ? paymentRequest.data.reference
        : null,
    };
    await this.changeStatusService.changeStatus(
      auth,
      paymentRequest,
      "BANK_TRANSACTION",
      body
    );

    if (
      bonusResult ||
      !(paymentRequest.manual_bonus && paymentRequest.manual_bonus_voucher)
    ) {
      const emision = {
        message: "",
        origin: "PSE",
      };
      let transaction = this.BuildJson(request, paymentRequest);
      this.authService.getTimezone().subscribe((success) => {
        try {
          transaction.hash = this.encryptService.encryptCipherData(success);
        } catch (error) {
          console.log(error);
        }
        transaction.status = "loaded";
        transaction.payment_reference = paymentRequest.data.payment_reference;
        this.paymentTransactionService
          .postTransaction(transaction, "Bearer " + token)
          .subscribe(
            (res: any) => {
              this.loadingScreenService.stopLoading();
              if (!res.errors && !res.message) {
                this.sessionStorageService.setItem(
                  SessionStorageService.TRANSACTION_ID,
                  res.data.transactionID
                );
                window.location.href = res.data.urlbanco;
              } else {
                emision.message = res.message;
                this.message.emit(emision);
              }
              if (res.message) {
                emision.message = res.message;
                this.message.emit(emision);
              }
            },
            (err) => {
              this.loadingScreenService.stopLoading();
              emision.message =
                "Ocurrio un error al intentar realizar el pago, Intente nuevamente.";
              this.message.emit(emision);
              console.log("Error: ", err);
            }
          );
      });
      this.loading = false;
    } else {
      this.cancel_bonus.emit(true);
      this.loadingScreenService.stopLoading();
      this.loading = false;
      return false;
    }
  }

  BuildJson(data: any, paymentRequest: PaymentConfigModel): any {
    if (data.type == "N") {
      data.type = "0";
    } else if (data.type == "J") {
      data.type = "1";
    }
    let _tax = 0;
    let _tax_base = 0;
    let vat = paymentRequest.data.vat;
    if (vat > 0) {
      if (data.activate_mixed) {
        _tax =
          (paymentRequest.data.amount_aux_mixed_purchase
            ? paymentRequest.data.amount_aux_mixed_purchase
            : paymentRequest.data.amount) *
          (vat / 100);
        _tax_base =
          (paymentRequest.data.amount_aux_mixed_purchase
            ? paymentRequest.data.amount_aux_mixed_purchase
            : paymentRequest.data.amount) - _tax;
      } else {
        let aux_amount = paymentRequest.retentions
          ? paymentRequest.retentions.total_amount
          : paymentRequest.data.amount;
        _tax_base = aux_amount / (1 + vat / 100);
        _tax = aux_amount - _tax_base;
      }
    }

    let amount = paymentRequest.retentions
      ? paymentRequest.retentions.total_amount
      : paymentRequest.data.amount;

    if (data.activate_mixed) {
      amount = paymentRequest.data.amount;
    }

    let _request = {
      client: paymentRequest.name,
      payment_method: "BANK_TRANSACTION",
      bank: data.bank,
      invoice: paymentRequest.data.reference,
      description: paymentRequest.data.description,
      value: amount,
      tax: _tax,
      tax_base: _tax_base,
      currency: "COP",
      type_person: data.type,
      doc_type: data.type_fis_number,
      doc_number: data.fiscal_number,
      name: paymentRequest.data.name,
      last_name: paymentRequest.data.last_name
        ? paymentRequest.data.last_name
        : "‎ ",
      email: paymentRequest.data.email,
      country: "CO",
      cell_phone: paymentRequest.data.phone,
      url_response: data.urlReturnPSE,
      url_return: paymentRequest.data.url_return
        ? paymentRequest.data.url_return
        : null,
      url_return_problem: paymentRequest.data.url_return_problem
        ? paymentRequest.data.url_return_problem
        : null,
      subclient: paymentRequest.data.subclient
        ? paymentRequest.data.subclient
        : null,
      pay_link: paymentRequest.data.pay_link
        ? paymentRequest.data.pay_link
        : null,
      ip: this.ipAddress,
      plan: paymentRequest.data.plan ? paymentRequest.data.plan : null,
      retentions: paymentRequest.retentions ? paymentRequest.retentions : null,
      bonus: paymentRequest.aplly_bonus ? paymentRequest.aplly_bonus : null,
      manual_bonus: paymentRequest.manual_bonus
        ? paymentRequest.manual_bonus
        : null,
      id_producto: paymentRequest.data.id_producto
        ? paymentRequest.data.id_producto
        : null,
      aux_init_amount: paymentRequest.aux_init_amount
        ? paymentRequest.aux_init_amount
        : null,
      mixed_purchase: data.activate_mixed ? data.activate_mixed : null,
      aux_init_amount_mixed_purchase: paymentRequest.data
        .amount_aux_mixed_purchase
        ? paymentRequest.data.amount_aux_mixed_purchase
        : null,
      codeService: paymentRequest.data.codeService ? paymentRequest.data.codeService : null
    };

    return _request;
  }

  BuildJsonCard(request: any, paymentRequest: PaymentConfigModel): any {
    let transaction: PaymentTransactionModel;

    if (
      request.result &&
      request.result.total == paymentRequest.aux_init_amount
    ) {
      this.activateChip.emit(false);
    }

    let _tax = 0;
    let _tax_base = 0;
    let vat = paymentRequest.data.vat;
    if (vat > 0) {
      if (request.activate_mixed) {
        _tax =
          (paymentRequest.data.amount_aux_mixed_purchase
            ? paymentRequest.data.amount_aux_mixed_purchase
            : paymentRequest.data.amount) *
          (vat / 100);
        _tax_base =
          (paymentRequest.data.amount_aux_mixed_purchase
            ? paymentRequest.data.amount_aux_mixed_purchase
            : paymentRequest.data.amount) - _tax;
      } else {
        _tax =
          (paymentRequest.retentions
            ? paymentRequest.retentions.total_amount
            : paymentRequest.data.amount) *
          (vat / 100);
        _tax_base =
          (paymentRequest.retentions
            ? paymentRequest.retentions.total_amount
            : paymentRequest.data.amount) - _tax;
      }
    }
    if (paymentRequest.retentions) {
      paymentRequest.retentions.total_amount = paymentRequest.is_debit
        ? paymentRequest.retentions.total_amount
        : paymentRequest.data.amount;
    }

    transaction = {
      token_card: request.token_card, // no viene
      amount: paymentRequest.retentions
        ? paymentRequest.retentions.total_amount
        : paymentRequest.data.amount,
      client: paymentRequest.name,
      payment_method: "CARD",
      reference: paymentRequest.data.reference,
      description: paymentRequest.data.description,
      currency: "COP",
      name: paymentRequest.data.name,
      last_name: paymentRequest.data.last_name
        ? paymentRequest.data.last_name
        : "‎ ",
      email: paymentRequest.data.email,
      country: "COLOMBIA",
      doc_type: paymentRequest.data.id_type,
      type_user: paymentRequest.data.type_user,
      // doc_number: String(request.doc_number),
      doc_number: paymentRequest.data.id,
      tax: String(_tax),
      tax_base: String(_tax_base),
      bill: paymentRequest.data.reference,
      dues: request.dues,
      exist_token_card: request.exist_token_card
        ? request.exist_token_card
        : false,
      exist_validate_token: request.exist_validate_token
        ? request.exist_validate_token
        : false,
      phone: paymentRequest.data.phone,
      subclient: paymentRequest.data.subclient
        ? paymentRequest.data.subclient
        : null,
      url_return: paymentRequest.data.url_return
        ? paymentRequest.data.url_return
        : null,
      url_return_problem: paymentRequest.data.url_return_problem
        ? paymentRequest.data.url_return_problem
        : null,
      plan: paymentRequest.data.plan ? paymentRequest.data.plan : null,
      pay_link: paymentRequest.data.pay_link
        ? paymentRequest.data.pay_link
        : null,
      ip: this.ipAddress,
      save_card: request.save_card ? request.save_card : false,
      bonus: paymentRequest.aplly_bonus ? paymentRequest.aplly_bonus : false,
      card_number: paymentRequest.card_number
        ? paymentRequest.card_number
        : null,
      token_external: paymentRequest.data.client_token
        ? paymentRequest.data.client_token
        : null,
      callback_url: paymentRequest.data.callback_url
        ? paymentRequest.data.callback_url
        : null,
      manual_bonus: paymentRequest.manual_bonus
        ? paymentRequest.manual_bonus
        : null,
      id_producto: paymentRequest.data.id_producto
        ? paymentRequest.data.id_producto
        : null,
      external_user_id: paymentRequest.external_user_id
        ? paymentRequest.external_user_id
        : null,
      aux_init_amount: paymentRequest.aux_init_amount
        ? paymentRequest.aux_init_amount
        : null,
      payment_reference: paymentRequest.data.payment_reference
        ? paymentRequest.data.payment_reference
        : null,
      doc_type_holder: request.doc_type_holder ? request.doc_type_holder : null,
      doc_number_holder: request.doc_number_holder
        ? request.doc_number_holder
        : null,
      retentions: paymentRequest.retentions ? paymentRequest.retentions : null,
      mixed_purchase: request.activate_mixed ? request.activate_mixed : null,
      aux_init_amount_mixed_purchase: paymentRequest.data
        .amount_aux_mixed_purchase
        ? paymentRequest.data.amount_aux_mixed_purchase
        : null,
      codeService: paymentRequest.data.codeService ? paymentRequest.data.codeService : null

    };
    return transaction;
  }

  generateToken(data: any) {}

  responseSet(status: number) {
    if (status === 1) {
      return "success";
    } else {
      if (status === 3) {
        return "pending";
      } else {
        return "failure";
      }
    }
  }

  franchiseSet(franchise: string) {
    switch (franchise) {
      case "AM":
        return "AMEX";
      case "BA":
        return "BALOTO";
      case "CR":
        return "REDENCIAL";
      case "DC":
        return "DINERS CLUB";
      case "EF":
        return "EFECTY";
      case "GA":
        return "GANA";
      case "PR":
        return "PUNTO RED";
      case "RS":
        return "RED SERVI";
      case "MC":
        return "MASTERCARD";
      case "PSE":
        return "PSE";
      case "SP":
        return "SAFETYPAY";
      case "VS":
        return "VISA";
    }
  }

  recurrenceSubscription(recurrence: string) {
    switch (recurrence) {
      case "biweekly":
        return "Quincenal";
      case "week":
        return "Semanal";
      case "month":
        return "Mensual";
      case "trimester":
        return "Trimestral";
      case "semester":
        return "Semestral";
      case "year":
        return "Anual";
    }
  }

  async transactionCard(
    token: string,
    request: any,
    paymentRequest: PaymentConfigModel,
    attempts: number = 0
  ) {
    const bonusResult = await this.validarBono(paymentRequest);
    let audit = {
      reference: paymentRequest.data.reference,
      customer_email: paymentRequest.data.email,
      client: paymentRequest.name,
      payment_reference: paymentRequest.data.payment_reference,
      response_token: null,
      error: null,
      dev_message: null,
    };
    if (
      bonusResult ||
      !(paymentRequest.manual_bonus && paymentRequest.manual_bonus_voucher)
    ) {
      const emision = {
        message: "",
        origin: "CARD",
      };

      this.flagTimeout = false;
      ePayco.setPublicKey(paymentRequest.epayco_credentials.api_key);
      let $form = $('<form id="customer-form"> </form>');

      $form.append(
        $('<input type="text" data-epayco="card[name]">').val(request.card.name)
      );
      $form.append(
        $('<input type="text" data-epayco="card[email]">').val(request.email)
      );
      $form.append(
        $('<input type="text" data-epayco="card[number]">').val(
          request.card.number.replace(/\D+/g, "")
        )
      );
      $form.append(
        $('<input type="text" size="4" data-epayco="card[cvc]">').val(
          request.card.cvc
        )
      );
      $form.append(
        $('<input type="text" data-epayco="card[exp_month]">').val(
          request.card.month
        )
      );
      $form.append(
        $('<input type="text" data-epayco="card[exp_year]">').val(
          request.card.year
        )
      );
      let _this = this;
      const cnumber = request.card.number.replace(/\D+/g, "");
      let mask = cnumber.substr(cnumber.length - 4);

      try {
        mask = this.encryptService.encryptStringData(mask);
      } catch (error) {
        console.log("no se pudo enmascarar la tarjeta");
      }

      const timer = setTimeout(() => {
        if (!_this.flagTimeout) {
          audit.dev_message =
            "encrypt/tokenize de epayco no dio respuesta pasados 30 segundos";
          _this.auditEpaycoResponse(audit, token);
          _this.loadingScreenService.stopLoading();
          emision.message =
            "Parece haber problemas tokenizando tu tarjeta, intenta de nuevo mas tarde.";
          _this.message.emit(emision);
          this.changeStatusService.auditErrorStatusAttempted(
            audit,
            paymentRequest.data,
            token
          );
        }
      }, 30000);
      ePayco.token.create($form, function (error, token_card) {
        _this.flagTimeout = true;

        let errorMessage = "Fallo al tokenizar";

        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.data && error.data.description) {
          errorMessage = error.data.description;
        }

        audit.error = error;
        audit.response_token = token_card;
        audit.dev_message = "respondió encrypt/tokenize de epayco";
        audit.reference = paymentRequest.data.reference;
        audit.client = paymentRequest.data.client;
        audit.payment_reference = paymentRequest.data.payment_reference;

        _this.auditEpaycoResponse(audit, token);

        if (!error) {
          request.token_card = token_card;

          if (request.token_card) {
            let transaction = _this.BuildJsonCard(request, paymentRequest);

            _this.authService.getTimezone().subscribe((success) => {
              // tslint:disable-next-line: indent
              try {
                const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
                const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);

                const encrypted = CryptoJS.AES.encrypt(
                  JSON.stringify({ cipher: success }),
                  key,
                  {
                    mode: CryptoJS.mode.CTR,
                    iv: iv,
                    padding: CryptoJS.pad.NoPadding,
                  }
                );
                transaction.hash = encrypted.ciphertext.toString(
                  CryptoJS.enc.Base64
                );
                transaction.mask = mask;
                transaction.status = "loaded";

                _this.paymentTransactionService
                  .postTransaction(transaction, "Bearer " + token)
                  .subscribe(
                    (res: any) => {
                      if (res.errors) {
                        if (Array.isArray(res.errors)) {
                          emision.message = res.errors[0].errorMessage;
                        } else {
                          if (res.meta) {
                            _this.activateChip.emit(false);
                            _this.changeAmountInitial.emit(
                              paymentRequest.aux_init_amount
                            );
                          }
                          emision.message = res.errors;
                        }
                        _this.message.emit(emision);
                        _this.loadingScreenService.stopLoading();
                      } else {
                        _this.sessionStorageService.setItem(
                          SessionStorageService.TRANSACTION_ID,
                          res.data.transactionID
                        );

                        let _status = _this.responseSet(res.data.cod_respuesta);
                        let _franquicia = _this.franchiseSet(
                          res.data.franquicia
                        );

                        let _res = {
                          transaction: {
                            payment_date: moment(res.data.date).toISOString(),
                            installments: request.dues ? request.dues : "1",
                            trazability_code: res.data.autorizacion,
                            status: _status,
                            type_canceled: res.data.type_canceled,
                            authorization_code: res.data.autorizacion,
                            payment_reference: res.data.payment_reference,
                            plan: res.data.plan
                              ? {
                                  recurrence: res.data.plan.recurrence,
                                  next_payment:
                                    res.data.plan.current_period_end,
                                  vigence: {
                                    date_in: res.data.plan.vigence.date_in,
                                    date_end: res.data.plan.vigence.date_end,
                                  },
                                }
                              : undefined,
                            puntos_colombia: res.data.puntos_colombia,
                            mixed_purchase: res.data.mixed_purchase,
                          },
                          card: {
                            number: "",
                            type: _franquicia,
                          },
                        };
                        setTimeout(() => {
                          _this.loadingScreenService.stopLoading();
                          if (_res.transaction.mixed_purchase) {
                            this.openPurchasePaymentMix(paymentRequest, _res);
                          } else {
                            _this._router.navigate(["/pagos/respuesta"], {
                              state: {
                                paymentRequest: paymentRequest,
                                response: _res,
                              },
                            });
                          }
                        }, 100);
                      }
                    },
                    (err) => {
                      console.log(err);
                      audit.dev_message =
                        "Ocurrio un error al intentar realizar el pago, Intente nuevamente.";
                      emision.message =
                        "Ocurrio un error al intentar realizar el pago, Intente nuevamente.";
                      _this.message.emit(emision);
                      _this.changeStatusService.auditErrorStatusAttempted(
                        audit,
                        paymentRequest.data,
                        token
                      );
                      _this.loadingScreenService.stopLoading();
                    }
                  );
              } catch (error) {
                console.log(error);
                audit.dev_message =
                  "Ocurrio un error al intentar realizar el pago, Intente nuevamente.";
                emision.message =
                  "Ocurrio un error al intentar realizar el pago, Intente nuevamente.";
                _this.changeStatusService.auditErrorStatusAttempted(
                  audit,
                  paymentRequest.data,
                  token
                );
                _this.loadingScreenService.stopLoading();
                _this.message.emit(emision);
              }
            });
          } else {
            _this.loadingScreenService.stopLoading();
            try {
              emision.message = errorMessage.toString();
            } catch (error) {
              emision.message = JSON.stringify(errorMessage);
            }
            audit.dev_message = error ? error : "Fallo al tokenizar tu tarjeta";
            _this.changeStatusService.auditErrorStatusAttempted(
              audit,
              paymentRequest.data,
              token
            );
            _this.message.emit(emision);
          }
        } else {
          _this.loadingScreenService.stopLoading();
          try {
            emision.message = errorMessage.toString();
          } catch (error) {
            emision.message = JSON.stringify(errorMessage);
          }
          audit.dev_message = error
            ? error
            : "Ha ocurrido un error al generar el pago!!!";
          _this.changeStatusService.auditErrorStatusAttempted(
            audit,
            paymentRequest.data,
            token
          );
          _this.message.emit(emision);
        }
        clearTimeout(timer);
      });
    } else {
      this.cancel_bonus.emit(true);
      this.loadingScreenService.stopLoading();
      return false;
    }
  }

  transactionTokenizedCard(
    token: string,
    request: any,
    paymentRequest: PaymentConfigModel,
    token_card
  ) {
    request.token_card = token_card;

    const emision = {
      message: "",
      origin: "TOKENCARD",
      errorsArray: [],
      errorsString: "",
    };

    let transaction = this.BuildJsonCard(request, paymentRequest);
    this.authService.getTimezone().subscribe((success) => {
      // tslint:disable-next-line: indent
      try {
        const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
        const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);

        const encrypted = CryptoJS.AES.encrypt(
          JSON.stringify({ cipher: success }),
          key,
          {
            mode: CryptoJS.mode.CTR,
            iv: iv,
            padding: CryptoJS.pad.NoPadding,
          }
        );
        transaction.hash = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
        transaction.status = "loaded";

        this.paymentTransactionService
          .postTransaction(transaction, token)
          .subscribe(
            (res: any) => {
              if (res.errors) {
                if (res.errorsMapping) {
                  emision.message = res.errorsMapping.description;
                  if (Array.isArray(res.errorsMapping.errors)) {
                    emision.errorsArray = res.errorsMapping.errors;
                  }
                  if (!Array.isArray(res.errorsMapping.errors)) {
                    emision.errorsString = res.errorsMapping.errors;
                  }
                } else if (Array.isArray(res.errors)) {
                  emision.message = res.errors[0].errorMessage;
                } else {
                  if (res.meta) {
                    this.activateChip.emit(false);
                    this.changeAmountInitial.emit(
                      paymentRequest.aux_init_amount
                    );
                  }
                  emision.message = res.errors;
                }
                this.message.emit(emision);
                this.loadingScreenService.stopLoading();
              } else {
                let _status = this.responseSet(res.data.cod_respuesta);
                let _franquicia = this.franchiseSet(res.data.franquicia);

                let _res = {
                  transaction: {
                    payment_date: moment(res.data.date).toISOString(),
                    installments: request.dues ? request.dues : "1",
                    trazability_code: res.data.autorizacion,
                    status: _status,
                    type_canceled: res.data.type_canceled,
                    authorization_code: res.data.autorizacion,
                    payment_reference: res.data.payment_reference,
                    plan: res.data.plan
                      ? {
                          recurrence: res.data.plan.recurrence,
                          next_payment: res.data.plan.current_period_end,
                          vigence: {
                            date_in: res.data.plan.vigence.date_in,
                            date_end: res.data.plan.vigence.date_end,
                          },
                        }
                      : undefined,
                    puntos_colombia: res.data.puntos_colombia,
                    mixed_purchase: res.data.mixed_purchase,
                  },
                  card: {
                    number: "",
                    type: _franquicia,
                  },
                };
                setTimeout(() => {
                  this.loadingScreenService.stopLoading();
                  if (
                    _res.transaction.mixed_purchase &&
                    _res.transaction.mixed_purchase.pending_value != 0
                  ) {
                    this.openPurchasePaymentMix(paymentRequest, _res);
                  } else {
                    this._router.navigate(["/pagos/respuesta"], {
                      state: { paymentRequest: paymentRequest, response: _res },
                    });
                  }
                }, 100);
              }
            },
            (err) => {
              console.log(err);
              emision.message =
                "Ocurrio un error al intentar realizar el pago, Intente nuevamente.";
              this.auditError.dev_message =
                "Ocurrio un error al intentar realizar el pago, Intente nuevamente.";
              this.changeStatusService.auditErrorStatusAttempted(
                this.auditError,
                paymentRequest.data,
                token
              );
              this.message.emit(emision);
              this.loadingScreenService.stopLoading();
            }
          );
      } catch (error) {
        console.log(error);
        this.loadingScreenService.stopLoading();
        emision.message =
          "Ocurrio un error al intentar realizar el pago, Intente nuevamente.";
        this.auditError.dev_message =
          "Ocurrio un error al intentar realizar el pago, Intente nuevamente.";
        this.changeStatusService.auditErrorStatusAttempted(
          this.auditError,
          paymentRequest.data,
          token
        );
        this.message.emit(emision);
      }
    });
  }

  seeTransactionStatus(
    token: string,
    paymentRequest: PaymentConfigModel,
    transactionId: string
  ): Promise<any> {
    let subclient = paymentRequest.data.subclient
      ? "&subclient=" + paymentRequest.data.subclient
      : "";
    const reference = paymentRequest.data.reference
      ? "&reference=" + paymentRequest.data.reference
      : "";

    const codeService = paymentRequest.data.codeService
      ? '&codeService=' + paymentRequest.data.codeService
      : "";

    return new Promise((resolve, reject) => {
      this.getStatusBankTransfer(
        token,
        transactionId,
        reference,
        subclient,
        codeService,
      ).subscribe(
        (res) => {
          this.loadingScreenService.stopLoading();
          res.transaction.paid_date = moment(
            res.transaction.paid_date
          ).toISOString();
          resolve(res);
        },
        (err) => {
          this.loadingScreenService.stopLoading();
          reject(err);
        }
      );
    });
  }

  getStatusBankTransfer(
    token: string,
    transaction_id: string,
    reference: string,
    subclient: string,
    service_code?: string
  ): Observable<PaymentezResponseStatusTransferModel> {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Content-Type", "application/json");
    return this.http.get(
      environment.apiUrl +
        "/api/epayco-status-transaction?transaction_id=" +
        transaction_id +
        reference +
        subclient +
        service_code,
      { headers }
    );
  }

  public auditEpaycoResponse(audit, token) {
    this.auditService.saveAudit(audit, token).subscribe(
      (success) => {
        console.log("token guardado exitosamente");
      },
      (failure) => {
        console.log(failure);
      }
    );
  }

  public getCustomerCards(token: string, request: OrderPaymentezModel) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Content-Type", "application/json");
    return this.http.post(
      environment.apiUrl + "/api/get-epayco-cards",
      request,
      { headers }
    );
  }

  public forgetCustomerCard(request: any, token: string) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Content-Type", "application/json");
    return this.http.post(
      environment.apiUrl + "/api/forget-epayco-card",
      request,
      { headers }
    );
  }

  public checkStatusTransaction(refPayco: string, token: string) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Content-Type", "application/json");

    const body = {
      ref_payco: refPayco,
    };
    return this.http.post(
      environment.apiUrl + "/api/epayco-checkout-transaction",
      body,
      { headers }
    );
  }

  public validarBono(paymentRequest) {
    return new Promise<any>((resolve, reject) => {
      this.auth = this.sessionStorageService.getItem<AuthModel>(
        SessionStorageService.AUTH
      );
      const req = {
        client: paymentRequest.name,
        amount: paymentRequest.aux_init_amount,
        dev_reference: paymentRequest.data.reference,
        bonus_voucher: paymentRequest.manual_bonus_voucher,
        id_type: paymentRequest.data.id_type,
        vat: paymentRequest.data.vat,
        rete_ica: paymentRequest.data.rete_ica,
        rete_iva: paymentRequest.data.rete_iva,
        rete_fuente: paymentRequest.data.rete_fuente,
        // id_product : paymentRequest.data.id_producto,
        external_user_id: paymentRequest.data.external_user_id,
        token_external: paymentRequest.data.token_external,
        retentions: paymentRequest.data.retentions,
        name: paymentRequest.data.name,
        last_name: paymentRequest.data.last_name,
        email: paymentRequest.data.email,
        phone: paymentRequest.data.phone,
        manual_bonus: true,
        external_user_doc: paymentRequest.data.id,
        external_user_email: paymentRequest.data.email,
        products: paymentRequest.data.products
          ? paymentRequest.data.products
          : null,
      };
      if (paymentRequest.manual_bonus_voucher) {
        this.validateBonusService
          .validBonus(req, "Bearer " + this.auth.token)
          .subscribe((res) => {
            if (res.bonus.code == 200) {
              // paymentRequest.retentions = res.retentions ? res.retentions : null;
              paymentRequest.data.amount = res.bonus.result.total;
              paymentRequest.aplly_bonus = true;
              paymentRequest.voucher_name = res.bonus.result.type_discount;
              this.activateChip.emit(true);
              paymentRequest.manual_bonus = true;
              this.BuildJson(res.bonus, paymentRequest);
              resolve(true);
            } else if (res.bonus.code == 422) {
              this.activateChip.emit(false);
              Swal.fire({
                title: "Bono redimido",
                text: "Este bono ya fue redimido, por favor intente con uno nuevo o realice el pago total de la transacción",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-custom-primary",
                },
                confirmButtonText: "Confirmar",
              });
              resolve(false);
            } else {
              this.activateChip.emit(false);
              Swal.fire({
                title: "Bono expirado",
                text: "Este bono ya expiró, por favor intente con uno nuevo o realice el pago total de la transacción",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-custom-primary",
                },
                confirmButtonText: "Confirmar",
              });
              resolve(false);
            }
          });
      } else {
        resolve(false);
      }
    });
  }
  cancelBonus() {
    this.cancel_bonus.emit(true);
  }

  sendValidMask(paymentRequest, card_number) {
    return new Promise<any>((resolve, reject) => {
      const auth = this.sessionStorageService.getItem<AuthModel>(
        SessionStorageService.AUTH
      );
      let verifyMask = this.maskCard(card_number);
      let token_card = null;

      const token = "Bearer " + auth.token;
      const requestValidMask = {
        mask: verifyMask,
        client: paymentRequest.name,
        subclient: paymentRequest.data.subclient,
        email: paymentRequest.data.email,
      };

      this.paymentTransactionService
        .validateMaskCard(token, requestValidMask)
        .subscribe(
          (res) => {
            if (res.success) {
              token_card = res.token_card;
              resolve(token_card);
            } else {
              resolve(token_card);
            }
          },
          (error) => {
            this.auditError.dev_message = "Error validando la mascara.";
            this.changeStatusService.auditErrorStatusAttempted(
              this.auditError,
              paymentRequest.data,
              token
            );
            reject(error);
          }
        );
    });
  }

  maskCard(card_number) {
    const cnumber = card_number.replace(/\D+/g, "");
    let mask = cnumber.substr(cnumber.length - 4);

    try {
      mask = this.encryptService.encryptStringData(mask);
      return mask;
    } catch (error) {
      console.log("no se pudo enmascarar la tarjeta");
    }
  }

  openPurchasePaymentMix(paymentRequest: PaymentConfigModel, res: any) {
    const dialogRef = this.dialog.open(PurchasePaymentMixComponent, {
      width: "600px",
      data: {
        paymentRequest: paymentRequest,
        res: res,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      let url_retry = this.sessionStorageService.getItem(
        SessionStorageService.URL_PAYMENT_REQUEST
      );
      window.location.href = url_retry + "&reload=" + +new Date().getTime();
    });
  }

  getIpUser() {
    return this.ipAddress;
  }

  backToCommerce(token: string, client_token: string, dev_reference: string, url_return: string, client: string, origin?: string) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Content-Type", "application/json");

    const body = {
      client_token: client_token,
      dev_reference: dev_reference,
      url_return: url_return,
      client: client,
      origin: origin
    };
    return this.http.post(
      environment.apiUrl + "/api/back-to-commerce",
      body,
      {headers}
    );
  }
}

