import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';

const UP_ARROW_ICON = `
  <svg viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.05511 0.35526C8.72881 0.0086808 8.27199 0.00868078 7.94569 0.285944L0.375583 8.46521C0.0492849 8.81178 0.0492849 9.36631 0.375583 9.64357L0.8324 10.1288C1.1587 10.4754 1.61551 10.4754 1.94181 10.1288L8.53303 3.05858L15.059 10.1288C15.3853 10.4754 15.8421 10.4754 16.1684 10.1288L16.6252 9.64357C16.9515 9.36631 16.9515 8.81178 16.6252 8.46521L9.05511 0.35526Z"
      fill="#038450" />
  </svg>
`;
const DOWN_ARROW_ICON = `
<svg viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.44587 10.1213C8.77217 10.4679 9.22898 10.4679 9.55528 10.1906L17.1254 2.01136C17.4517 1.66478 17.4517 1.11025 17.1254 0.832989L16.6686 0.347778C16.3423 0.00119972 15.8855 0.00119972 15.5592 0.347778L8.96795 7.41799L2.44199 0.347778C2.11569 0.00119972 1.65887 0.00119972 1.33258 0.347778L0.875759 0.832989C0.549461 1.11025 0.549461 1.66478 0.875759 2.01136L8.44587 10.1213Z" fill="#9B9B9B"/>
</svg>
`;
const LEFT_ARROW_ICON = `
<svg viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.902344 7.15234L7.65234 0.4375C7.96875 0.0859375 8.49609 0.0859375 8.84766 0.4375C9.16406 0.753906 9.16406 1.28125 8.84766 1.59766L2.66016 7.75L8.8125 13.9375C9.16406 14.2539 9.16406 14.7812 8.8125 15.0977C8.49609 15.4492 7.96875 15.4492 7.65234 15.0977L0.902344 8.34766C0.550781 8.03125 0.550781 7.50391 0.902344 7.15234Z" fill="#038450"/>
</svg>
`;
const CALENDAR_ICON = `
<svg viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.375 0.8125V2.5H12.125V0.8125C12.125 0.53125 12.3711 0.25 12.6875 0.25C12.9688 0.25 13.25 0.53125 13.25 0.8125V2.5H14.375C15.6055 2.5 16.625 3.51953 16.625 4.75V5.875V7V16C16.625 17.2656 15.6055 18.25 14.375 18.25H3.125C1.85938 18.25 0.875 17.2656 0.875 16V7V5.875V4.75C0.875 3.51953 1.85938 2.5 3.125 2.5H4.25V0.8125C4.25 0.53125 4.49609 0.25 4.8125 0.25C5.09375 0.25 5.375 0.53125 5.375 0.8125ZM2 7V16C2 16.6328 2.49219 17.125 3.125 17.125H14.375C14.9727 17.125 15.5 16.6328 15.5 16V7H2ZM3.125 3.625C2.49219 3.625 2 4.15234 2 4.75V5.875H15.5V4.75C15.5 4.15234 14.9727 3.625 14.375 3.625H3.125ZM4.53125 9.25C4.35547 9.25 4.25 9.39062 4.25 9.53125V12.3438C4.25 12.5195 4.35547 12.625 4.53125 12.625H7.34375C7.48438 12.625 7.625 12.5195 7.625 12.3438V9.53125C7.625 9.39062 7.48438 9.25 7.34375 9.25H4.53125ZM3.125 9.53125C3.125 8.75781 3.72266 8.125 4.53125 8.125H7.34375C8.11719 8.125 8.75 8.75781 8.75 9.53125V12.3438C8.75 13.1523 8.11719 13.75 7.34375 13.75H4.53125C3.72266 13.75 3.125 13.1523 3.125 12.3438V9.53125Z" fill="#9B9B9B"/>
</svg>
`;
const ID_CARD_ICON = `
<svg  viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.125 1.5C2.49219 1.5 2 2.02734 2 2.625H20C20 2.02734 19.4727 1.5 18.875 1.5H3.125ZM0.875 3.1875V2.625C0.875 1.39453 1.85938 0.375 3.125 0.375H18.875C20.1055 0.375 21.125 1.39453 21.125 2.625V3.1875V13.875C21.125 15.1406 20.1055 16.125 18.875 16.125H3.125C1.85938 16.125 0.875 15.1406 0.875 13.875V3.1875ZM20 3.75H2V13.875C2 14.5078 2.49219 15 3.125 15H18.875C19.4727 15 20 14.5078 20 13.875V3.75ZM7.625 8.25C8.22266 8.25 8.75 7.75781 8.75 7.125C8.75 6.52734 8.22266 6 7.625 6C6.99219 6 6.5 6.52734 6.5 7.125C6.5 7.75781 6.99219 8.25 7.625 8.25ZM7.625 4.875C8.39844 4.875 9.13672 5.33203 9.55859 6C9.94531 6.70312 9.94531 7.58203 9.55859 8.25C9.13672 8.95312 8.39844 9.375 7.625 9.375C6.81641 9.375 6.07812 8.95312 5.65625 8.25C5.26953 7.58203 5.26953 6.70312 5.65625 6C6.07812 5.33203 6.81641 4.875 7.625 4.875ZM6.5 11.625C5.55078 11.625 4.8125 12.3984 4.8125 13.3125C4.8125 13.6289 4.53125 13.875 4.25 13.875C3.93359 13.875 3.6875 13.6289 3.6875 13.3125C3.6875 11.7656 4.91797 10.5 6.5 10.5H8.75C10.2969 10.5 11.5625 11.7656 11.5625 13.3125C11.5625 13.6289 11.2812 13.875 11 13.875C10.6836 13.875 10.4375 13.6289 10.4375 13.3125C10.4375 12.3984 9.66406 11.625 8.75 11.625H6.5ZM12.6875 6.5625C12.6875 6.28125 12.9336 6 13.25 6H17.75C18.0312 6 18.3125 6.28125 18.3125 6.5625C18.3125 6.87891 18.0312 7.125 17.75 7.125H13.25C12.9336 7.125 12.6875 6.87891 12.6875 6.5625ZM12.6875 8.8125C12.6875 8.53125 12.9336 8.25 13.25 8.25H17.75C18.0312 8.25 18.3125 8.53125 18.3125 8.8125C18.3125 9.12891 18.0312 9.375 17.75 9.375H13.25C12.9336 9.375 12.6875 9.12891 12.6875 8.8125ZM12.6875 11.0625C12.6875 10.7812 12.9336 10.5 13.25 10.5H17.75C18.0312 10.5 18.3125 10.7812 18.3125 11.0625C18.3125 11.3789 18.0312 11.625 17.75 11.625H13.25C12.9336 11.625 12.6875 11.3789 12.6875 11.0625Z" fill="#9B9B9B"/>
</svg>
`;

@Component({
  selector: 'payment-icon',
  template: ` <mat-icon [svgIcon]="svgIcon"></mat-icon>`,
  styles: ['mat-icon { width: 19px;  height: 24px; margin-right: 5px; }'],
  standalone: true,
  imports: [MatIconModule],
})
export class PaymentIconComponent {
  @Input() svgIcon!: 'up-arrow' | 'down-arrow' | 'left-arrow' | 'right' | 'calendar' | 'id-card';

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral(
      'up-arrow',
      sanitizer.bypassSecurityTrustHtml(UP_ARROW_ICON),
    );
    iconRegistry.addSvgIconLiteral(
      'left-arrow',
      sanitizer.bypassSecurityTrustHtml(LEFT_ARROW_ICON),
    );
    iconRegistry.addSvgIconLiteral(
      'down-arrow',
      sanitizer.bypassSecurityTrustHtml(DOWN_ARROW_ICON),
    );
    iconRegistry.addSvgIconLiteral(
      'calendar',
      sanitizer.bypassSecurityTrustHtml(CALENDAR_ICON),
    );
    iconRegistry.addSvgIconLiteral(
      'id-card',
      sanitizer.bypassSecurityTrustHtml(ID_CARD_ICON),
    );
  }
}
