import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { SessionStorageService } from '@core/services/session-storage.service';
import { AuthModel } from '@core/models/auth.model';
import { environment } from '@environment/environment';
import { LoadingScreenService } from '@core/services/loading-screen.service';
import { finalize } from 'rxjs/operators';

const WHITE_LIST = ['api/payments/refund'];

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  #sessionStorageService = inject(SessionStorageService);
  #loadingScreenService = inject(LoadingScreenService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.split('/').includes('payments', 0) &&
      !WHITE_LIST.find((url) => request.url.includes(url))
    ) {
      this.#loadingScreenService.startLoading();
      const authModel = this.#sessionStorageService.getItem<AuthModel>(
        SessionStorageService.AUTH,
      );

      request = request.clone({
        url: `${environment.urlApi}/${request.url}`,
        setHeaders: {
          Authorization: `Bearer ${authModel?.token}`,
          'Content-Type': 'application/json',
        },
      });

      return next
        .handle(request)
        .pipe(finalize(() => this.#loadingScreenService.stopLoading()));
    }

    return next.handle(request);
    // .pipe(finalize(() => this.#loadingScreenService.stopLoading()));
  }
}
