import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ValidateBonusService {

  constructor(
    private http: HttpClient
  ) { }

  validBonus(obj : any, token: string) : Observable<any>{
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.http.post<any>(environment.apiUrl + "/api/validate-bonus",  obj,{headers});
  }
}
