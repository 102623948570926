import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  public static readonly AUTH = 'auth_user';
  public static readonly DOMAIN = 'dom';
  public static readonly TRANSACTION_ID = "t_id";
  public static readonly PLAIN_TRX_ID = "plain_trx_id";
  public static readonly ORDEN = "ord-u";
  public static readonly URL_RETURN_PROBLEM = "url_return_problem";
  public static readonly GOOGLE_TAG_MANAGER = "g_tag_m";
  public static readonly MENU_ITEMS = "mu_itm";
  public static readonly NAMECLIENT = "name";
  public static readonly URL_PAYMENT_REQUEST = "py_rqst";
  public static readonly PAYMENT_URL = "payment_url";
  public static readonly COOKIE_PAYMENT = 'payments';
  // key to save rstore, username, confirmation and token
  public static readonly DAVIPUNTOS_CREDENTIALS = "davipuntos_credentials";
  public static readonly PRODUCTSANALYTICS = "products_analytics";
  public static readonly PUNTOS_TOKEN = 'puntos_token';
  //constant NEQUI_TOKEN
  public static readonly NEQUI_TOKEN = "nequi_token";
  public static readonly TOKEN_PM = 'token_pm';

  constructor() { }

  public setItem(key: string, value: any): void {

    if (!window.sessionStorage) {
      this.showNotSupport();
      return;
    }

    if (key == SessionStorageService.PLAIN_TRX_ID) {
      sessionStorage.setItem(key, value);
      return;
    }

    const keyEncr = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), keyEncr, { mode: CryptoJS.mode.CTR, iv: iv, padding: CryptoJS.pad.NoPadding }).toString();

    sessionStorage.setItem(key, encrypted);
  }

  public setLocalItem(key: string, value: any): void {

    if (!window.localStorage) {
      this.showNotSupport();
      return;
    }

    const keyEncr = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), keyEncr, { mode: CryptoJS.mode.CTR, iv: iv, padding: CryptoJS.pad.NoPadding }).toString();

    localStorage.setItem(key, encrypted);
  }

  public getItem<T>(key: string): T {
    if (!window.sessionStorage) {
      this.showNotSupport();
      return null;
    }

    const keyEncr = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);
    let _obj = sessionStorage.getItem(key);
    try {
      const decrypted = CryptoJS.AES.decrypt(_obj, keyEncr, { mode: CryptoJS.mode.CTR, iv: iv, padding: CryptoJS.pad.NoPadding })
      if (decrypted.toString()) {
        return <T>JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      }
      //return false;
    } catch (e) {
      console.log('Validando sesión');
    }
  }

  public getLocalItem<T>(key: string): T {
    if (!window.localStorage) {
      this.showNotSupport();
      return null;
    }

    const keyEncr = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);
    let _obj = localStorage.getItem(key);
    try {
      const decrypted = CryptoJS.AES.decrypt(_obj, keyEncr, { mode: CryptoJS.mode.CTR, iv: iv, padding: CryptoJS.pad.NoPadding })
      if (decrypted.toString()) {
        return <T>JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      }
      //return false;
    } catch (e) {
      console.log('Validando sesión');
    }
  }

  public removeItem(key: string): void {
    if (!window.sessionStorage) {
      this.showNotSupport();
      return null;
    }
    sessionStorage.removeItem(key);
  }

  public removeLocalItem(key: string): void {
    if (!window.localStorage) {
      this.showNotSupport();
      return null;
    }
    localStorage.removeItem(key);
  }


  private showNotSupport(): void {
    alert('El navegador no soporta almacenamiento local');

  }

  public clearSessionStorage(): void {
    sessionStorage.clear();
  }

  public clearLocalStorage(): void {
    localStorage.clear();
  }

  //Cross site scripting when using windows.location.href
  public encodeHTML(s) {
    return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;').replace(/'/g, '&#x27;').replace(/>/g, '&gt;');
  }

}
