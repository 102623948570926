import {Component, Input, OnInit} from '@angular/core';
import {PaymentConfigModel} from "../../core/models/payment-config.model";

import * as CryptoJS from 'crypto-js';
import {PaymentezService} from "../../core/services/paymentez.service";
import {SessionStorageService} from "../../core/services/session-storage.service";
import {AuthModel} from "../../core/models/auth.model";
import {UserCardService} from "../../core/services/user-card.service";

@Component({
  selector: 'gateway-paymentez-link-to-pay',
  templateUrl: './paymentez-link-to-pay.component.html',
  styleUrls: ['./paymentez-link-to-pay.component.scss']
})
export class PaymentezLinkToPayComponent implements OnInit {

  @Input() paymentRequest: PaymentConfigModel;

  constructor(
    private _paymentezService: PaymentezService,
    private sessionStorageService: SessionStorageService,
    private userCardService : UserCardService,
  ) { }

  ngOnInit() {
  }

  payToLink() {

    let _request = {
      user: {
        id: '117',
        email: 'test@paymentez.com',
        name: this.paymentRequest.data.name,
        last_name: this.paymentRequest.data.last_name
      },
      order: {
        dev_reference: this.paymentRequest.data.reference,
        amount: this.paymentRequest.data.amount,
        description: this.paymentRequest.data.description,
        installments_type: 0,
        currency: 'COP'
      },
      configuration: {
        partial_payment: true,
        expiration_days: 1,
        allowed_payment_methods: ["BankTransfer", "Card"],
        success_url: "https://url-to-success.com",
        failure_url: "https://url-to-failure.com",
        pending_url: "https://url-to-pending.com",
        review_url: "https://url-to-review.com"
      }
    };

    /*
    let appCode = this.paymentRequest.code_app_server;
    let timestamp = Date.now();
    let _uniqToken =  this.paymentRequest.secret_app_server + timestamp;
    let hash = CryptoJS.SHA256(_uniqToken);
    let uniqTokenHash = hash.toString(CryptoJS.enc.Hex);

    let words = CryptoJS.enc.Utf8.parse(appCode + ';' + timestamp + ';' + uniqTokenHash);
    let base64 = CryptoJS.enc.Base64.stringify(words);
    */

    let auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    this.userCardService.getAuth("Bearer " + auth.token, this.paymentRequest.name).subscribe(
      res => {
            const base64 = res.token;
            this._paymentezService.linkToPay(_request, base64).subscribe(
              res => {
        
                const _url = res.data.payment.payment_url;
        
                window.location.href = _url;
        
              },
              err => {
                console.log("Error: ", err);
              }
          )
      },
      err => {
        //this.loadingScreenService.stopLoading();
        console.log("error with token: ", err);
      }
    );

    

  }

}
