
export default [
    {   
        "name":"AMAZONAS",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "EL ENCANTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA CHORRERA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA PEDRERA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA VICTORIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LETICIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MIRITI - PARANA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO ALEGRIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO ARICA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO NARIÑO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO SANTANDER"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TARAPACA"
                }
            }
        ]
    },
    {
        "name": "ANTIOQUIA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ABEJORRAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ABRIAQUI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ALEJANDRIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "AMAGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "AMALFI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ANDES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ANGELOPOLIS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ANGOSTURA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ANORI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ANZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "APARTADO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARBOLETES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARGELIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARMENIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BARBOSA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BELLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BELMIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BETANIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BETULIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BRICEÑO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BURITICA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CACERES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAICEDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CALDAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAMPAMENTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAÑASGORDAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CARACOLI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CARAMANTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAREPA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CARMEN DE VIBORAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAROLINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAUCASIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHIGORODO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CISNEROS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CIUDAD BOLIVAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COCORNA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CONCEPCION"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CONCORDIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COPACABANA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "DABEIBA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "DON MATIAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EBEJICO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL BAGRE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ENTRERRIOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ENVIGADO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FREDONIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FRONTINO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GIRALDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GIRARDOTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GOMEZ PLATA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GRANADA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUADALUPE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUARNE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUATAPE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "HELICONIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "HISPANIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ITAGUI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ITUANGO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "JARDIN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "JERICO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA CEJA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA ESTRELLA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA PINTADA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA UNION"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LIBORINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MACEO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MARINILLA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MEDELLIN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MONTEBELLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MURINDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MUTATA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NARIÑO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NECHI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NECOCLI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "OLAYA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PEÑOL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PEQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUEBLORRICO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO BERRIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO NARE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO TRIUNFO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "REMEDIOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RETIRO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RIONEGRO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SABANALARGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SABANETA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SALGAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN ANDRES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN CARLOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN FRANCISCO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JERONIMO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JOSE DE LA MONTAÑA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JUAN DE URABA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN LUIS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN PEDRO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN PEDRO DE URABA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN RAFAEL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN ROQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN VICENTE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA BARBARA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA.ROSA DE OSOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTAFE DE ANTIOQUIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTO DOMINGO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTUARIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SEGOVIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SONSON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOPETRAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TAMESIS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TARAZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TARSO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TITIRIBI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TOLEDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TURBO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "URAMITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "URRAO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VALDIVIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VALPARAISO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VEGACHI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VENECIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VIGIA DEL FUERTE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "YALI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "YARUMAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "YOLOMBO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "YONDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ZARAGOZA"
                }
            }
        ]
    },
    {
        "name": "ARAUCA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ARAUCA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARAUQUITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CRAVO NORTE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FORTUL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO RONDON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SARAVENA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TAME"
                }
            }
        ]
    },
    {
         "name":"ATLANTICO",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "BARANOA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BARRANQUILLA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAMPO DE LA CRUZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CANDELARIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GALAPA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "JUAN DE ACOSTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LURUACO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MALAMBO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MANATI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PALMAR DE VARELA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PIOJO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "POLONUEVO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PONEDERA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO COLOMBIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "REPELON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SABANAGRANDE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SABANALARGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA LUCIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTO TOMAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOLEDAD"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TUBARA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "USIACURI"
                }
            }
        ]
    },
    {
         "name":"BOGOTA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "BOGOTA"
                }
            }
        ]
    },
    {
        "name": "BOLIVAR",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ACHI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ALTOS DEL ROSARIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARENAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARJONA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARROYOHONDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BARRANCO DE LOBA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CALAMAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CANTAGALLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CARTAGENA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CICUCO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CLEMENCIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CORDOBA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL CARMEN DE BOLIVAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL GUAMO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL PEÑON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "HATILLO DE LOBA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MAGANGUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MAHATES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MARGARITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MARIA LA BAJA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MOMPOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MONTECRISTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MORALES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PINILLOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "REGIDOR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RIO VIEJO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN CRISTOBAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN ESTANISLAO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN FERNANDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JACINTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JACINTO DEL CAUCA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JUAN NEPOMUCENO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN MARTIN DE LOBA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN PABLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA CATALINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA ROSA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA ROSA DEL SUR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SIMITI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOPLAVIENTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TALAIGUA NUEVO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TIQUISIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TURBACO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TURBANA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLANUEVA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ZAMBRANO"
                }
            }
        ]
    },
    {
        "name": "BOYACA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ALMEIDA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "AQUITANIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARCABUCO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BELEN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BERBEO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BETEITIVA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BOAVITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BOYACA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BRICEÑO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BUENAVISTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BUSBANZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CALDAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAMPOHERMOSO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CERINZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHINAVITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHIQUINQUIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHIQUIZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHISCAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHITARAQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHIVATA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHIVOR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CIENEGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COMBITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COPER"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CORRALES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COVARACHIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CUBARA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CUCAITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CUITIVA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "DUITAMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL COCUY"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL ESPINO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FIRAVITOBA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FLORESTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GACHANTIVA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GAMEZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GARAGOA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUACAMAYAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUATEQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUAYATA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUICAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "IZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "JENESANO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "JERICO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA CAPILLA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA UVITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA VICTORIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LABRANZAGRANDE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MACANAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MARIPI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MIRAFLORES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MONGUA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MONGUI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MONIQUIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MOTAVITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MUZO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NOBSA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NUEVO COLON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "OICATA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "OTANCHE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PACHAVITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAEZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAIPA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAJARITO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PANQUEBA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAUNA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAYA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAZ DE RIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PESCA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PISBA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO BOYACA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "QUIPAMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RAMIRIQUI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RAQUIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RONDON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SABOYA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SACHICA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAMACA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN EDUARDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JOSE DE PARE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN LUIS DE GACENO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN MATEO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN MIGUEL DE SEMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN PABLO BORBUR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN ROSA VITERBO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA MARIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA SOFIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTANA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SATIVANORTE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SATIVASUR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SIACHOQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOATA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOCHA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOCOTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOGAMOSO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOMONDOCO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SORA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SORACA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOTAQUIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUSACON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUTAMARCHAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUTATENZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TASCO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TENZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TIBANA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TIBASOSA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TINJACA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TIPACOQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TOCA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TOGUI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TOPAGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TOTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TUNJA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TUNUNGUA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TURMEQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TUTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TUTAZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "UMBITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VENTAQUEMADA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLA DE LEYVA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VIRACACHA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ZETAQUIRA"
                }
            }
        ]
    },
    {
        "name": "CALDAS",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "AGUADAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ANSERMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARANZAZU"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BELALCAZAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHINCHINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FILADELFIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA DORADA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA MERCED"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MANIZALES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MANZANARES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MARMATO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MARQUETALIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MARULANDA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NEIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NORCASIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PACORA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PALESTINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PENSILVANIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RIOSUCIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RISARALDA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SALAMINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAMANA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JOSE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUPIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VICTORIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLA MARIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VITERBO"
                }
            }
        ]
    },
    {
        "name": "CAQUETA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ALBANIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BELEN DE LOS ANDAQUIES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CARTAGENA DEL CHAIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CURRILLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL DONCELLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL PAUJIL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FLORENCIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA MONTAÑITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MILAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MORELIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO RICO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JOSE DEL FRAGUA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN VICENTE DEL CAGUAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOLANO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOLITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VALPARAISO"
                }
            }
        ]
    },
    {
        "name": "CASANARE",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "AGUAZUL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHAMEZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "HATO COROZAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA SALINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MANI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MONTERREY"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NUNCHIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "OROCUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAZ DE ARIPORO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PORE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RECETOR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SABANALARGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SACAMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN LUIS DE PALENQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TAMARA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TAURAMENA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TRINIDAD"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLANUEVA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "YOPAL"
                }
            }
        ]
    },
    {
        "name": "CAUCA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ALMAGUER"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARGELIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BALBOA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BOLIVAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BUENOS AIRES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAJIBIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CALDONO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CALOTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CORINTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL TAMBO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FLORENCIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUAPI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "INZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "JAMBALO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA SIERRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA VEGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LOPEZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MERCADERES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MIRANDA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MORALES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PADILLA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAEZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PATIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PIAMONTE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PIENDAMO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "POPAYAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO TEJADA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PURACE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ROSAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN SEBASTIAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA ROSA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTANDER DE QUILICHAO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SILVIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOTARA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUAREZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUCRE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TIMBIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TIMBIQUI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TORIBIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TOTORO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLA RICA"
                }
            }
        ]
    },
    {
        "name": "CESAR",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "AGUACHICA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "AGUSTIN CODAZZI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ASTREA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BECERRIL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BOSCONIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHIMICHAGUA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHIRIGUANA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CURUMANI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL COPEY"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL PASO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GAMARRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GONZALEZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA GLORIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA JAGUA DE IBIRICO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA PAZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MANAURE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAILITAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PELAYA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUEBLO BELLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RIO DE ORO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN ALBERTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN DIEGO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN MARTIN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TAMALAMEQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VALLEDUPAR"
                }
            }
        ]
    },
    {
         "name":"CHOCO",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ACANDI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ALTO BAUDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ATRATO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BAGADO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BAHIA SOLANO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BAJO BAUDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BOJAYA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CANTON DE SAN PABLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CARMEN DEL DARIEN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CERTEGUI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CONDOTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL CARMEN DE ATRATO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL LITORAL DEL SAN JUAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ITSMINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "JURADO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LLORO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MEDIO ATRATO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MEDIO BAUDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MEDIO SAN JUAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NOVITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NUQUI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "QUIBDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RIO IRO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RIO QUITO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RIOSUCIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JOSE DEL PALMAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SIPI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TADO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "UNGUIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "UNION PANAMERICANA"
                }
            }
        ]
    },
    {
        "name": "CORDOBA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "AYAPEL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BUENAVISTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CANALETE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CERETE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHINU"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CIENAGA DE ORO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COTORRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA APARTADA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LORICA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LOS CORDOBAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MO?ITOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MOMIL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MONTELIBANO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MONTERIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PLANETA RICA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUEBLO NUEVO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO ESCONDIDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO LIBERTADOR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PURISIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAHAGUN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN ANDRES SOTAVENTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN ANTERO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN BERNARDO DEL VIENTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN CARLOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN PELAYO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TIERRALTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VALENCIA"
                }
            }
        ]
    },
    {
        "name": "CUNDINAMARCA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "AGUA DE DIOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ALBAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ANAPOIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ANOLAIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "APULO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARBELAEZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BELTRAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BITUIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BOJACA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CABRERA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CACHIPAY"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAJICA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAPARRAPI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAQUEZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CARMEN DE CARUPA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHAGUANI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHIPAQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHOACHI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHOCONTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COGUA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CUCUNUBA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL COLEGIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL PEÑON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL ROSAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FACATATIVA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FOMEQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FOSCA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FUNZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FUQUENE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FUSAGASUGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GACHALA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GACHANCIPA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GACHETA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GAMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GIRARDOT"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GRANADA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUACHETA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUADUAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUASCA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUATAQUI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUATAVITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUAYABAL DE SIQUIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUAYABETAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUTIERREZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "JERUSALEN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "JUNIN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA CALERA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA MESA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA PALMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA PEÑA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA VEGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LENGUAZAQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MACHETA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MADRID"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MANTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MEDINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MOSQUERA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NARIÑO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NEMOCON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NILO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NIMAIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NOCAIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PACHO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAIME"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PANDI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PARATEBUENO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PASCA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO SALGAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PULI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "QUEBRADANEGRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "QUETAME"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "QUIPILE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RICAURTE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN ANTONIO DE TEQUENDAMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN BERNARDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN CAYETANO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN FRANCISCO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JUAN DE RIO SECO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SASAIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SESQUILE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SIBATE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SILVANIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SIMIJACA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOACHA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOPO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUBACHOQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUESCA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUPATA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUSA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUTATAUSA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TABIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TAUSA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TENA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TENJO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TIBACUY"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TIBIRITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TOCAIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TOCANCIPA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TOPAIPI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "UBALA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "UBAQUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "UBATE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "UNE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "UTICA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VENECIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VERGARA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VIANI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLAGOMEZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLAPINZON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLETA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VIOTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "YACOPI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ZIPACON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ZIPAQUIRA"
                }
            }
        ]
    },
    {
        "name": "GUAINIA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "BARRANCO MINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CACAHUAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "INIRIDA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA GUADALUPE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MAPIRIPANA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MORICHAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PANA PANA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO COLOMBIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN FELIPE"
                }
            }
        ]
    },
    {
        "name": "VICHADA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "CUMARIBO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA PRIMAVERA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO CARREÑO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA ROSALIA"
                }
            }
        ]
    },
    {
         "name":"VAUPES",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "CARURU"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MITU"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PACOA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAPUNAHUA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TARAIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "YAVARATE"
                }
            }
        ]
    },
    {
         "name":"VALLE DEL CAUCA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ALCALA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ANDALUCIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ANSERMANUEVO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARGELIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BOLIVAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BUENAVENTURA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BUGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BUGALAGRANDE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAICEDONIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CALI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CALIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CANDELARIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CARTAGO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "DAGUA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL AGUILA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL CAIRO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL CERRITO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL DOVIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FLORIDA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GINEBRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUACARI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "JAMUNDI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA CUMBRE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA UNION"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA VICTORIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "OBANDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PALMIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PRADERA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RESTREPO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RIOFRIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ROLDANILLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN PEDRO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SEVILLA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TORO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TRUJILLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TULUA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ULLOA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VERSALLES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VIJES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "YOTOCO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "YUMBO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ZARZAL"
                }
            }
        ]
    },
    {
        "name": "TOLIMA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ALPUJARRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ALVARADO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "AMBALEMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ANZOATEGUI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARMERO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ATACO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAJAMARCA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CARMEN DE APICALA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CASABIANCA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHAPARRAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COELLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COYAIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CUNDAY"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "DOLORES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ESPINAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FALAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FLANDES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FRESNO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUAMO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "HERVEO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "HONDA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "IBAGUE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ICONONZO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LERIDA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LIBANO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MARIQUITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MELGAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MURILLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NATAGAIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ORTEGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PALOCABILDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PIEDRAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PLANADAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PRADO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PURIFICACION"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RIOBLANCO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RONCESVALLES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ROVIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SALDAÑA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN ANTONIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN LUIS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA ISABEL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUAREZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VALLE DE SAN JUAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VENADILLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLAHERMOSA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLARRICA"
                }
            }
        ]
    },
    {
        "name": "SUCRE",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "BUENAVISTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAIMITO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHALAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COLOSO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COROZAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COVEÑAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL ROBLE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GALERAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUARANDA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA UNION"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LOS PALMITOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MAJAGUAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MORROA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "OVEJAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PALMITO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAMPUES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN BENITO ABAD"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JUAN BETULIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN MARCOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN ONOFRE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN PEDRO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTIAGO DE TOLU"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SINCE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SINCELEJO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUCRE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TOLU VIEJO"
                }
            }
        ]
    },
    {
        "name": "SANTANDER",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "AGUADA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ALBANIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARATOCA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BARBOSA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BARICHARA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BARRANCABERMEJA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BETULIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BOLIVAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BUCARAMANGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CABRERA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CALIFORNIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAPITANEJO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CARCASI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CEPITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CERRITO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHARALA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHARTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHIMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHIPATA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CIMITARRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CONCEPCION"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CONFINES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CONTRATACION"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COROMORO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CURITI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL CARMEN DE CHUCURI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL GUACAMAYO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL PEÑON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL PLAYON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ENCINO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ENCISO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FLORIAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FLORIDABLANCA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GALAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GAMBITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GIRON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUACA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUADALUPE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUAPOTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUAVATA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUEPSA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "HATO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "JESUS MARIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "JORDAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA BELLEZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA PAZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LANDAZURI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LEBRIJA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LOS SANTOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MACARAVITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MALAGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MATANZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MOGOTES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MOLAGAVITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "OCAMONTE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "OIBA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ONZAGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PALMAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PALMAS DEL SOCORRO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PARAMO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PIEDECUESTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PINCHOTE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUENTE NACIONAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO PARRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO WILCHES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RIONEGRO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SABANA DE TORRES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN ANDRES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN BENITO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN GIL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JOAQUIN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JOSE DE MIRANDA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN MIGUEL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN VICENTE DE CHUCURI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA BARBARA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA HELENA DEL OPON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SIMACOTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SOCORRO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUAITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUCRE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SURATA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TONA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VALLE DE SAN JOSE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VELEZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VETAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLANUEVA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ZAPATOCA"
                }
            }
        ]
    },
    {
        "name": "SAN ANDRES,PROVIDENCIA Y SANTA CATALINA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "PROVIDENCIA Y SANTA CATALINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN ANDRES"
                }
            }
        ]
    },
    {
        "name": "RISARALDA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "APIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BALBOA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BELEN DE UMBRIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "DOSQUEBRADAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUATICA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA CELIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA VIRGINIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MARSELLA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MISTRATO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PEREIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUEBLO RICO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "QUINCHIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA ROSA DE CABAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTUARIO"
                }
            }
        ]
    },
    {
        "name": "QUINDIO",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ARMENIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BUENAVISTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CALARCA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CIRCASIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CORDOBA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FILANDIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GENOVA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA TEBAIDA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MONTENEGRO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PIJAO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "QUIMBAYA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SALENTO"
                }
            }
        ]
    },
    {
        "name": "PUTUMAYO",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "COLON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LEGUIZAMO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MOCOA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ORITO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO ASIS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO CAICEDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO GUZMAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN FRANCISCO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN MIGUEL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTIAGO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SIBUNDOY"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VALLE DEL GUAMUEZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLAGARZON"
                }
            }
        ]
    },
    {
        "name": "NORTE DE SANTANDER",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ABREGO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARBOLEDAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BOCHALEMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BUCARASICA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CACHIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CACOTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHINACOTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHITAGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CONVENCION"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CUCUTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CUCUTILLA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "DURANIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL CARMEN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL TARRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL ZULIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GRAMALOTE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "HACARI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "HERRAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA ESPERANZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA PLAYA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LABATECA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LOS PATIOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LOURDES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MUTISCUA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "OCAÑA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAMPLONA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAMPLONITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO SANTANDER"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RAGONVALIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SALAZAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN CALIXTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN CAYETANO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTIAGO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SARDINATA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SILOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TEORAMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TIBU"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TOLEDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLA CARO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLA DEL ROSARIO"
                }
            }
        ]
    },
    {
        "name": "NARIÑO",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ALBAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ALDANA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ANCUYA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARBOLEDA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BARBACOAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BELEN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BUESACO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHACHAGUI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COLON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CONSACA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CONTADERO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CORDOBA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CUASPUD"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CUMBAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CUMBITARA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL CHARCO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL PEÑOL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL ROSARIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL TABLON DE GOMEZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL TAMBO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FRANCISCO PIZARRO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FUNES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUACHUCAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUAITARILLA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUALMATAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ILES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "IMUES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "IPIALES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA CRUZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA FLORIDA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA LLANADA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA TOLA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA UNION"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LEIVA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LINARES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LOS ANDES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MAGUI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MALLAMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MOSQUERA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NARINO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "OLAYA HERRERA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "OSPINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PASTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "POLICARPA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "POTOSI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PROVIDENCIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERRES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUPIALES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RICAURTE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ROBERTO PAYAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAMANIEGO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN BERNARDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN LORENZO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN PABLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN PEDRO DE CARTAGO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANDONA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA BARBARA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTACRUZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAPUYES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TAMINANGO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TANGUA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TUMACO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TUQUERRES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "YACUANQUER"
                }
            }
        ]
    },
    {
        "name": "META",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ACACIAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BARRANCA DE UPIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CABUYARO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CASTILLA LA NUEVA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CUMARAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL CALVARIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL CASTILLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL DORADO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FUENTE DE ORO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GRANADA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUAMAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA MACARENA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA URIBE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LEJANIAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MAPIRIPAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MESETAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO CONCORDIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO GAITAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO LLERAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO LOPEZ"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUERTO RICO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RESTREPO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN CARLOS GUAROA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JUAN DE ARAMA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JUANITO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN LUIS DE CUBARRAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN MARTIN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLAVICENCIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VISTA HERMOSA"
                }
            }
        ]
    },
    {
        "name": "MAGDALENA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ALGARROBO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARACATACA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ARIGUANI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CERRO SAN ANTONIO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CHIVOLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CIENAGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CONCORDIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL BANCO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL PIÑON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL RETEN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FUNDACION"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUAMAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NUEVA GRANADA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PEDRAZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PIJIÑO DEL CARMEN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PIVIJAY"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PLATO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PUEBLOVIEJO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "REMOLINO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SABANAS DE SAN ANGEL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SALAMINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN SEBASTIAN DE BUENAVISTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN ZENON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA ANA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA BARBARA DE PINTO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA MARTA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SITIONUEVO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TENERIFE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ZAPAYAN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ZONA BANANERA"
                }
            }
        ]
    },
    {
        "name": "LA GUAJIRA",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ALBANIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BARRANCAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "DIBULLA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "DISTRACCION"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL MOLINO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "FONSECA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "HATONUEVO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA JAGUA DEL PILAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MAICAO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MANAURE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RIOHACHA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JUAN DEL CESAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "URIBIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "URUMITA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLANUEVA"
                }
            }
        ]
    },
    {
        "name": "HUILA", 
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "ACEVEDO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "AGRADO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "AIPE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ALGECIRAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ALTAMIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "BARAYA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "CAMPOALEGRE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "COLOMBIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ELIAS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GARZON"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GIGANTE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "GUADALUPE"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "HOBO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "IQUIRA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "ISNOS"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA ARGENTINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "LA PLATA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NATAGA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "NEIVA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "OPORAPA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PAICOL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PALERMO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PALESTINA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PITAL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "PITALITO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "RIVERA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SALADOBLANCO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN AGUSTIN"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SANTA MARIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SUAZA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TARQUI"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TELLO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TERUEL"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TESALIA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "TIMANA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "VILLAVIEJA"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "YAGUARA"
                }
            }
        ]
    },
    {
        "name": "GUAVIARE",
        "displayFieldName": "DPTO_CCDGO",
        "fieldAliases": {
            "MPIO_CNMBR": "Municipio"
        },
        "fields": [
            {
                "name": "MPIO_CNMBR",
                "type": "esriFieldTypeString",
                "alias": "Municipio",
                "length": 60
            }
        ],
        "features": [
            {
                "attributes": {
                    "MPIO_CNMBR": "CALAMAR"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "EL RETORNO"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "MIRAFLORES"
                }
            },
            {
                "attributes": {
                    "MPIO_CNMBR": "SAN JOSE DEL GUAVIARE"
                }
            }
        ]
    }
]