import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-app-dialog',
  templateUrl: './confirm-app-dialog.component.html',
  styleUrls: ['./confirm-app-dialog.component.scss']
})
export class ConfirmAppDialogComponent implements OnInit {

  constructor(
    public dialogo: MatDialogRef<ConfirmAppDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public mensaje: string) { }

  ngOnInit(): void {
  }

  cerrarDialogo(): void {
    this.dialogo.close(false);
  }
  confirmado(): void {
    this.dialogo.close(true);
  }

}
