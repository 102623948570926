import {Component, Input, OnInit} from '@angular/core';
import {PaymentConfigModel} from "../../../core/models/payment-config.model";

@Component({
  selector: 'app-owner-data',
  templateUrl: './owner-data.component.html',
  styleUrls: ['./owner-data.component.scss']
})
export class OwnerDataComponent implements OnInit {

  @Input() paymentRequest: PaymentConfigModel;

  constructor() { }

  ngOnInit() {}

}
