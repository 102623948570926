import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BankTransferResponseModel } from "../models/paymentez/bank-transfer-response.model";
import { PaymentezRequestModel } from "../models/paymentez/paymentez-request.model";
import { PaymentezResponseSdkModel } from "../models/paymentez/paymentez-response-sdk.model";
import { PaymentezResponseStatusTransferModel } from "../models/paymentez/paymentez-response-status-transfer.model";

import { environment } from "../../../environments/environment";
import { PaymentConfigModel } from "../models/payment-config.model";

import { UserCardService } from "./user-card.service";
import { PaymentTransactionModel } from "../models/paymentez/payment-transaction.model";
import { SessionStorageService } from "./session-storage.service";
import { PaymentTransactionService } from "./payment-transaction.service";
import { LoadingScreenService } from "./loading-screen.service";
import { AuthService } from 'src/app/core/services/auth.service';
import * as CryptoJS from 'crypto-js';
import { v4 as uuid } from 'uuid';
import { MatDialogRef } from '@angular/material/dialog';
import { PaymentezComponent } from 'src/app/gateway/paymentez/paymentez.component';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ValidateBonusService } from "./validate-bonus.service";
import { EpaycoService } from 'src/app/core/services/epayco.service';
import { AuthModel } from 'src/app/core/models/auth.model';
import { PaymentezCheckoutService } from './paymentez-checkout.service';
import { LoginModel } from '../models/login.model';
import { ChangeStatusService } from './change-status.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentezService {

  @Output() message: EventEmitter<string> = new EventEmitter();
  @Output() responsePointsColombiaPse: EventEmitter<any> = new EventEmitter();
  validatorRetentions: boolean = false;
  public auth;
  public auditError;
  constructor(
    private http: HttpClient,
    private userCardService: UserCardService,
    private sessionStorageService: SessionStorageService,
    private paymentTransactionService: PaymentTransactionService,
    private loadingScreenService: LoadingScreenService,
    private authService: AuthService,
    private validateBonusService: ValidateBonusService,
    protected _router: Router,
    private epaycoService: EpaycoService,
    private changeStatusService: ChangeStatusService,
    private paymentezCheckoutService: PaymentezCheckoutService
  ) { }

  async transactionPSE(token: string, request: any, paymentRequest: PaymentConfigModel, dialogRef: MatDialogRef<PaymentezComponent>) {
    this.auditError = {
      "client" : paymentRequest.name,
      "reference" : paymentRequest.data.reference,
      "payment_reference" : paymentRequest.data.payment_reference
    }
    const bonusResult = await this.validarBono(paymentRequest);
    const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    const body = {
      id_type: request.type_fis_number ? request.type_fis_number : null,
      id: request.fiscal_number,
      type_user: request.type ? request.type : null,
      bank: request.bank ? request.bank : null,
      dev_reference: paymentRequest.data.reference ? paymentRequest.data.reference : null
    }
    await this.changeStatusService.changeStatus(auth, paymentRequest, 'BANK_TRANSACTION', body);

    if(bonusResult || !(paymentRequest.manual_bonus && paymentRequest.manual_bonus_voucher)){
      request = this.BuildJson(request, paymentRequest);
      this.userCardService.getAuth("Bearer " + token, paymentRequest.name, paymentRequest.data.subclient ? paymentRequest.data.subclient : null).subscribe(
        res => {
          /*---------------*/
          const authPay = res.token;
          this.createBankTransfer(request, authPay).subscribe(
            res => {
              let amount = paymentRequest.retentions ? paymentRequest.retentions.total_amount : res.transaction.amount;
              if(request.order.mixed_purchase){
                amount = paymentRequest.data.amount ? paymentRequest.data.amount: res.transaction.amount;
              }
              let respuestaBanco = res;
              let transaction: PaymentTransactionModel;
              transaction = {
                client: paymentRequest.name,
                payment_method: 'BANK_TRANSACTION',
                name: res.user.name,
                email: res.user.email,
                currency: res.transaction.currency,
                country: res.transaction.country,
                dev_reference: paymentRequest.data.reference,
                amount: amount,
                description: res.transaction.description,
                status: res.transaction.status,
                id_transaction: res.transaction.id,
                trazability_code: res.transaction.trazability_code,
                ticket_id: res.transaction.ticket_id,
                subclient: paymentRequest.data.subclient ? paymentRequest.data.subclient : null,
                message: res.transaction.message,
                token_external: paymentRequest.token_external ? paymentRequest.token_external : null,
                external_user_id : paymentRequest.external_user_id ? paymentRequest.external_user_id : null,
                payment_reference: paymentRequest.payment_reference_status ? paymentRequest.payment_reference_status : paymentRequest.data.payment_reference,
                mixed_purchase: request.order.mixed_purchase
              };


              this.sessionStorageService.setItem(SessionStorageService.TRANSACTION_ID, res.transaction.id);

              this.authService.getTimezone().subscribe(
                success => {

                  try {
                    const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
                    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);

                    const encrypted = CryptoJS.AES.encrypt(JSON.stringify({ cipher: success }), key, { mode: CryptoJS.mode.CTR, iv: iv, padding: CryptoJS.pad.NoPadding });
                    transaction.hash = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
                  } catch (error) {
                    console.log(error);
                  }

                  this.paymentTransactionService.postTransaction(transaction, "Bearer " + token).subscribe(
                    response => {
                      this.loadingScreenService.stopLoading();
                      window.location.href = this.sessionStorageService.encodeHTML(res.transaction.bank_url);
                      this.message.emit("");
                    },
                    err => {
                      this.message.emit("Ocurrio un error al intentar realizar el pago, Intente nuevamente.");
                      this.loadingScreenService.stopLoading();
                      //console.log("Error: ", err);
                    }
                  );
                }
              )

            },
            err => {
              this.message.emit("Todos los campos deben ser diligenciados");
              this.loadingScreenService.stopLoading();
              this.changeStatusService.auditErrorStatusAttempted(err, this.auditError, token);
              console.log("Error: ", err);
            }
          )

          /*---------------*/
        },
        err => {
          this.changeStatusService.auditErrorStatusAttempted(err, this.auditError, token);
          console.log("Error: ", err);
        }
      );
    }
    else{
      this.epaycoService.cancelBonus();
      this.loadingScreenService.stopLoading();
      return false;
    }
  }

  createBankTransfer(paymentezRequestModel: PaymentezRequestModel, base64: string): Observable<BankTransferResponseModel> {
    const headers = new HttpHeaders()
      .set('Auth-Token', base64)
      .set('Content-Type', 'application/json');
    //return this.http.post("https://noccapi-stg.paymentez.com/order/", paymentezRequestModel, {headers});
    return this.http.post(environment.urlPaymentezConsult + "/order/", paymentezRequestModel, { headers });
  }

  createCreditCard(paymentezRequestModel: PaymentezRequestModel, base64: string): Observable<BankTransferResponseModel> {
    const headers = new HttpHeaders()
      .set('Auth-Token', base64)
      .set('Content-Type', 'application/json');
      //return this.http.post("https://ccapi-stg.paymentez.com/v2/transaction/debit_cc", paymentezRequestModel, {headers});
    return this.http.post(environment.urlPaymentezTransaction + "/debit_cc", paymentezRequestModel, { headers });
  }

  linkToPay(paymentezRequestModel: any, base64: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Auth-Token', base64)
      .set('Content-Type', 'application/json');
    //return this.http.post("https://noccapi-stg.paymentez.com/linktopay/init_order/", paymentezRequestModel, {headers});
    return this.http.post(environment.urlPaymentezConsult + "/linktopay/init_order/", paymentezRequestModel, { headers });
  }

  debitWithToken(paymentezRequestModel: PaymentezRequestModel, base64: string): Observable<PaymentezResponseSdkModel> {
    const headers = new HttpHeaders()
      .set('Auth-Token', base64)
      .set('Content-Type', 'application/json');
    //return this.http.post("https://ccapi-stg.paymentez.com/v2/transaction/debit/", paymentezRequestModel, {headers});
    return this.http.post(environment.urlPaymentezTransaction + "/debit/", paymentezRequestModel, { headers });
  }

  getStatusBankTransfer(transactionId: string, base64: string): Observable<PaymentezResponseStatusTransferModel> {
    const headers = new HttpHeaders()
      .set('Auth-Token', base64)
      .set('Content-Type', 'application/json');
    //return this.http.get("https://noccapi-stg.paymentez.com/pse/order/" + transactionId, {headers});
    return this.http.get(environment.urlPaymentezConsult + "/order/" + transactionId, { headers });
  }

  BuildJson(data: any, paymentRequest: PaymentConfigModel) {

    let amount = paymentRequest.retentions ? paymentRequest.retentions.total_amount : paymentRequest.data.amount;

    if(data.activate_mixed){
      amount = paymentRequest.data.amount_aux_mixed_purchase ? paymentRequest.data.amount_aux_mixed_purchase : paymentRequest.data.amount;
    }

    let _uuid = uuid();
    let _request = {
      carrier: {
        id: 'PSE',
        extra_params: {
          bank_code: data.bank,
          response_url: data.urlReturnPSE, //environment.urlResponsePSE,
          user: {
            name: paymentRequest.data.name + " " + paymentRequest.data.last_name,
            fiscal_number: data.fiscal_number,
            type: data.type,
            type_fis_number: data.type_fis_number,
            ip_address: paymentRequest.data.ip
          }
        }
      },
      user: {
        id: _uuid,
        email: paymentRequest.data.email
      },
      order: {
        dev_reference: data.base64Reference,
        amount: amount,
        bonus: paymentRequest.aplly_bonus ? paymentRequest.aplly_bonus : null,
        manual_bonus : paymentRequest.manual_bonus ? paymentRequest.manual_bonus : null,
        vat: paymentRequest.data.vat,
        description: this.normalizeText(paymentRequest.data.description), //autorizado por Julian calvo
        mixed_purchase: data.activate_mixed
      }
    };

    return _request;
  }

  seeTransactionStatus(token: string, paymentRequest: PaymentConfigModel, transactionId: string): Promise<any> {
    this.auditError = {
      "client" : paymentRequest.name,
      "reference" : paymentRequest.data.reference,
      "payment_reference" : paymentRequest.data.payment_reference
    }
    let subclient = paymentRequest.data.subclient ? paymentRequest.data.subclient : null;

    return new Promise((resolve, reject) => {
      this.userCardService.getAuth("Bearer " + token, paymentRequest.name, subclient).subscribe(
        res => {
          const base64 = res.token;


          this.getStatusBankTransfer(transactionId, base64).subscribe(
            res => {
              this.loadingScreenService.stopLoading();

              this.authService.getTimezone().subscribe(
                success => {

                  try {
                    const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
                    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);

                    const encrypted = CryptoJS.AES.encrypt(JSON.stringify({ cipher: success }), key, { mode: CryptoJS.mode.CTR, iv: iv, padding: CryptoJS.pad.NoPadding });
                    res.hash = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
                  } catch (error) {
                    this.changeStatusService.auditErrorStatusAttempted(error, this.auditError, token);
                    console.log(error);
                    reject(error);
                  }


                  this.userCardService.addResponsePaymentez(res, "Bearer " + token).subscribe(
                    res => {
                      if(res!= null){
                        this.responsePointsColombiaPse.emit(res);
                      }
                    },
                    err => {
                      this.changeStatusService.auditErrorStatusAttempted(err, this.auditError, token);
                      console.log("Se ha presentando un inconveniente: ", err);
                    }
                  );

                },
                error => {
                  console.log(error);
                }
              )

              resolve(res);


            },
            err => {
              this.loadingScreenService.stopLoading();
              console.log("Se ha presentando un inconveniente: ", err);
              this.changeStatusService.auditErrorStatusAttempted(err, this.auditError, token);
              reject(err);
            }
          );

        },
        err => {
          //this.loadingScreenService.stopLoading();
          console.log("error with token: ", err);
          this.changeStatusService.auditErrorStatusAttempted(err, this.auditError, token);
          reject(err);
        }
      );

    });
  }

  obtainStatusPayment(token: string, data: any) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.http.post<any>(environment.apiUrl + '/api/obtain-status-payment', data, { headers });
  }

  normalizeText(text) {

    let textNormalized = text.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    if (textNormalized.length > 50) {
      textNormalized = textNormalized.substring(0, 50);
    }
    return textNormalized;
  }

  private validarBono(paymentRequest){
    return new Promise<any>((resolve, reject) => {
      this.auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
      const req = {
        client: paymentRequest.name,
        amount: paymentRequest.aux_init_amount,
        dev_reference: paymentRequest.data.reference,
        bonus_voucher: paymentRequest.manual_bonus_voucher,
        id_type: paymentRequest.data.id_type,
        vat: paymentRequest.data.vat,
        rete_ica: paymentRequest.data.rete_ica,
        rete_iva : paymentRequest.data.rete_iva,
        rete_fuente : paymentRequest.data.rete_fuente,
        // id_product : paymentRequest.data.id_producto,
        external_user_id : paymentRequest.data.external_user_id,
        token_external : paymentRequest.data.token_external,
        retentions : paymentRequest.data.retentions,
        name : paymentRequest.data.name,
        last_name : paymentRequest.data.last_name,
        email : paymentRequest.data.email,
        phone : paymentRequest.data.phone,
        manual_bonus : true,
        external_user_doc : paymentRequest.data.id,
        external_user_email : paymentRequest.data.email,
        products : paymentRequest.data.products ? paymentRequest.data.products : null
      }
      if (paymentRequest.manual_bonus_voucher && paymentRequest.retentions && !paymentRequest.retentions.message){
        this.epaycoService.validatedRetentions.subscribe(retentions =>{
          this.validatorRetentions = retentions;

        })
        if (paymentRequest.retentions && paymentRequest.retentions.message) {
          paymentRequest.retentions = null;
        }
        this.validateBonusService.validBonus(req, "Bearer " + this.auth.token).subscribe(res =>{
          if (res.bonus.code == 200) {
            paymentRequest.retentions = res.retentions;
            if(this.validatorRetentions){
              paymentRequest.data.amount = res.retentions ? res.retentions.total_amount : res.bonus.result.total;
            }
            else{
              paymentRequest.data.amount = res.bonus.result.total;
            }
            paymentRequest.aplly_bonus = true;
            paymentRequest.voucher_name = res.bonus.result.type_discount;
            paymentRequest.manual_bonus = true;
            this.BuildJson(res.bonus,paymentRequest);
            resolve(true);
          } else {
            Swal.fire({
              title: 'Bono expirado',
              text: 'Este bono ya expiró, por favor intente con uno nuevo o realice el pago total de la transacción',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-custom-primary'
              },
              confirmButtonText: 'Confirmar'
            })
            this.loadingScreenService.stopLoading();
            resolve(false);
          }
        })
      }
      else {
        resolve(false);
      }
    })
  }


}
