import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PaymentConfigModel } from '@core/models/payment-config.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentRequestService {
  #paymentRequest = new BehaviorSubject<PaymentConfigModel>(
    {} as PaymentConfigModel,
  );

  get paymentRequest$(): Observable<PaymentConfigModel> {
    return this.#paymentRequest.asObservable();
  }

  get paymentRequest() {
    return this.#paymentRequest.getValue();
  }

  set paymentRequest(value: PaymentConfigModel) {
    this.#paymentRequest.next(value);
  }
}
