import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieGatewayService } from '../core/services/cookie.service';
import { AuthService } from '../core/services/auth.service';

import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackMessageComponent } from '../commons/snack-message/snack-message.component';
import { SessionStorageService } from '../core/services/session-storage.service';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
    constructor(
        private router: Router,
        private cookieService: CookieGatewayService,
        private aut: AuthService,
        private sessionStorageService: SessionStorageService,
        private _snackBar: MatSnackBar
    ) {
    }


    errorLogin(message: any) {

        this._snackBar.openFromComponent(SnackMessageComponent, {
            data: message,
            duration: 5000,
            panelClass: ['snackError']
        });


    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let data = this.cookieService.getCookie(CookieGatewayService.COOKIE_LOGGED);

        if (!data) {
            let mess = 'Necesita iniciar sesión.';
            this.errorLogin(mess);
            this.router.navigate(['/login-admin']);
            return false;
        }

        data = data.replace(/%2F/g, '/');
        data = data.replace(/%3D/g, '=');
        const isLogged = this.aut.decryptData(data);

        // se valida que el login sea hash correcto
        if (!isLogged) {
            let mess = 'Inicio de sesión incorrecto...';
            this.errorLogin(mess);
            //this.cookieService.removeCookie(CookieGatewayService.COOKIE_LOGGED);
            this.router.navigate(['/login-admin']);
            return false;
        }

        const logg = isLogged.split("|");

        if (logg[0] !== "isLogged") {
            let mess = 'Inicio de sesión incorrecto.';
            this.errorLogin(mess);
            this.cookieService.removeCookie(CookieGatewayService.COOKIE_LOGGED);
            this.router.navigate(['/login-admin']);
            return false;
        }

        const startDate = new Date(logg[1]).getTime();
        const endDate = new Date();
        const min = (endDate.getTime() - startDate) / (1000 * 60);

        if (min < 55) {
            return true;
        } else {
            let mess = 'Lo sentimos su sesión expiró, por favor vuelva a iniciar sesión.';
            this.errorLogin(mess);
            this.cookieService.removeCookie(CookieGatewayService.COOKIE_LOGGED);
            this.sessionStorageService.removeItem(SessionStorageService.NAMECLIENT);
            this.router.navigate(['/login-admin']);
            return false;
        }

    }
}
