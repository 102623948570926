import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';


@NgModule({
	imports: [
		CommonModule
	],

	declarations: [ 
		HeaderComponent,
		FooterComponent
	],

	exports: [
		HeaderComponent,
		FooterComponent
	],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ApplicationComponModule {}