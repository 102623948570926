import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';
import { LoadingScreenService } from 'src/app/core/services/loading-screen.service';
import { PaymentTransactionModel } from 'src/app/core/models/paymentez/payment-transaction.model';
import { AuthModel } from 'src/app/core/models/auth.model';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { DaviplataService } from 'src/app/core/services/daviplata.service';
import { error } from 'protractor';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { PaymentezComponent } from '../../paymentez.component';
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/services/auth.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { EpaycoService } from 'src/app/core/services/epayco.service';
import { ValidateBonusService } from "../../../../core/services/validate-bonus.service";
import Swal from 'sweetalert2';
import { LoginModel } from 'src/app/core/models/login.model';
import { ChangeStatusService } from 'src/app/core/services/change-status.service';


declare var window: any;
var nameCategory: any;
var description: any;

@Component({
  selector: 'app-pay-daviplata',
  templateUrl: './pay-daviplata.component.html',
  styleUrls: ['./pay-daviplata.component.scss']
})
export class PayDaviplataComponent implements OnInit {

  @Input() paymentRequest: PaymentConfigModel;
  @Output() activateChip: EventEmitter<any> = new EventEmitter();
  valor = null;
  subclient = null;

  OTP = null;

  enviado = false;
  message = null;

  buyResponse = null;
  confirmation = null;

  documentType = null;
  documentNumber = null;

  loading = false;

  isProd: boolean = false;

  documents = [
    { code: "01", name: "Cédula de Ciudadania" },
    { code: "02", name: "Cédula de Extranjeria" },
    { code: "03", name: "Nit" },
    { code: "04", name: "Tarjeta de Identidad" },
    { code: "05", name: "Pasaporte" },
    { code: "06", name: "Trj. Seguro Social Extranjero" }
  ]

  validatorRetentions: boolean = false;
  public auth;
  public auditError;
  constructor(
    private sessionStorageService: SessionStorageService,
    private loadingScreenService: LoadingScreenService,
    private daviplataService: DaviplataService,
    private datalayerService: DatalayerService,
    private _router: Router,
    public dialogRef: MatDialogRef<PaymentezComponent>,
    private authService: AuthService,
    private epaycoService: EpaycoService,
    private validateBonusService: ValidateBonusService,
    private changeStatusService: ChangeStatusService,
  ) {

    this.auth = this.sessionStorageService.getItem<LoginModel>(SessionStorageService.AUTH);
    this.loadPaymentRequest();
  }

  loadPaymentRequest() {
    if (environment.apiUrl == "https://api-gateway.solucionesbolivar.com") {
      this.isProd = true;
    }
  }

  ngOnInit() {
    this.valor = this.paymentRequest.data.amount;
    this.subclient = this.paymentRequest.data.subclient;

    this.epaycoService.validatedRetentions.subscribe(retentions => {
      if (this.paymentRequest.retentions && !this.paymentRequest.retentions.message) {
        this.validatorRetentions = retentions;
      }
      if (this.paymentRequest.retentions && this.paymentRequest.retentions.message) {
        this.paymentRequest.retentions = null;
      }
    })
  }

  //Se envia la prmera petición que da la orden de genera el otp
  async payWithDaviplata() {

    this.addToDataLayerCheckoutEvent('Daviplata', this.paymentRequest);
    this.datalayerService.AddInfoDataLayerPaymentMethod('Daviplata');

    const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    const request ={
      type: this.documentType,
      fiscal_number: this.documentNumber,
      dev_reference: this.paymentRequest.data.reference
    }
    await this.changeStatusService.changeStatus(auth, this.paymentRequest, 'Daviplata', request);

    if (this.paymentRequest.manual_bonus && this.paymentRequest.manual_bonus_voucher) {
      const bonusResult = await this.validarBono();
      this.paymentRequest.aplly_bonus = false;
      this.paymentRequest.manual_bonus = false;
      this.auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
      if (!bonusResult) {
        this.loading = false;
        this.daviplataService.cancelBonus(true);
        return false;
      }
      this.loading = false;
    }

    //sin bono manual
    this.datalayerService.checkoutEvent("3", this.paymentRequest);

    //datalayer boton de pago
    let amountDatalayer = this.paymentRequest.retentions ? this.paymentRequest.retentions.total_amount : this.paymentRequest.data.amount
    let textButton = `PAGAR ${amountDatalayer}`;
    if (this.paymentRequest.name == 'ciencuadras') {
      this.datalayerService.AddInfoDataLayerPaymentButton(textButton);
    }
    if (this.paymentRequest.name == 'doctoraki') {
      this.datalayerService.addInfoDataLayerPurchaseResume(textButton, this.paymentRequest.name, 'daviplata')
    }
    if (!this.validarDatos()) {
      return false;
    }

    let transaction: any;
    transaction = {
      dev_reference: this.paymentRequest.data.reference,
      document_type: this.documentType,
      document: this.documentNumber,
      subclient: this.subclient,
      client: this.paymentRequest.name ? this.paymentRequest.name : null,
      amount: this.paymentRequest.retentions ? this.paymentRequest.retentions.total_amount : this.paymentRequest.data.amount,
      payment_reference: this.paymentRequest.payment_reference_status ? this.paymentRequest.payment_reference_status : this.paymentRequest.data.payment_reference,
    };
    const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);

    const encrypted = CryptoJS.AES.encrypt(JSON.stringify({ cipher: transaction }), key, { mode: CryptoJS.mode.CTR, iv, padding: CryptoJS.pad.NoPadding });
    const dataEncrypt = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

    const req = {
      data: dataEncrypt
    };

    this.loadingScreenService.startLoading();
    this.daviplataService.requestPayWithToken(req, "Bearer " + auth.token).subscribe(
      data => {
        if (data.codigoError) {
          this.message = data.mensajeError;
        } else {
          this.enviado = !this.enviado;
          this.buyResponse = data;
        }
        this.loadingScreenService.stopLoading();
      },

      error => {
        this.message = "parece haber problemas con la conexión";
        this.loadingScreenService.stopLoading();
      }

    );

  }

  //si hay un envio correcto se enia el otp recibido por el usuario en su telefono
  async sendOTPConfirmation() {

    this.loading = true;

    if (!this.validarOtp()) {
      this.loading = false;
      return false;
    }

    let auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    this.loadingScreenService.startLoading();

    setTimeout(() => { this.loading = false; }, 1500);

    const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);
    this.auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    let request = {
      name: `${this.paymentRequest.data.name} ${this.paymentRequest.data.last_name}`,
      payment_method: 'DAVIPLATA',
      amount: this.paymentRequest.retentions ? this.paymentRequest.retentions.total_amount : this.paymentRequest.data.amount,
      dev_reference: this.paymentRequest.data.reference,
      description: this.paymentRequest.data.description,
      email: this.paymentRequest.data.email,
      document_type: this.documentType,
      document: this.documentNumber,
      principal_document_type: this.paymentRequest.data.id_type,
      principal_document: this.paymentRequest.data.id,
      plan: this.paymentRequest.data.plan,
      pay_link: this.paymentRequest.data.pay_link,
      otp: this.OTP,
      idSessionToken: this.buyResponse.idSessionToken,
      tokenOAuthDaviplata: this.buyResponse.tokenOAuthDaviplata,
      subclient: this.subclient,
      manual_bonus: this.paymentRequest.manual_bonus,
      bonus_voucher: this.paymentRequest.manual_bonus_voucher,

      client: this.paymentRequest.data.name,
      id_type: this.paymentRequest.data.id_type,
      vat: this.paymentRequest.data.vat,
      rete_ica: this.paymentRequest.retentions ? this.paymentRequest.retentions.rete_ica : null,
      rete_iva: this.paymentRequest.retentions ? this.paymentRequest.retentions.rete_iva : null,
      rete_fuente: this.paymentRequest.retentions ? this.paymentRequest.retentions.rete_fuente : null,
      id_product: this.paymentRequest.data.id_producto,
      external_user_doc : this.paymentRequest.data.id,
      external_user_email : this.paymentRequest.data.email,
      external_user_id: this.paymentRequest.data.external_user_id,
      token_external: this.paymentRequest.data.token_external,
      retentions: this.paymentRequest.data.retentions,
      last_name: this.paymentRequest.data.last_name,
      phone: this.paymentRequest.data.phone,
      validation: true
    };
    const bonusResult = await this.validarBono();
    if (!bonusResult) {
      this.loading = false;
      this.daviplataService.cancelBonus(true);
      return false;
    }

    const reqEncrypted = CryptoJS.AES.encrypt(JSON.stringify({ cipher: request }), key, { mode: CryptoJS.mode.CTR, iv, padding: CryptoJS.pad.NoPadding });
    const dataEncrypt = reqEncrypted.ciphertext.toString(CryptoJS.enc.Base64);

    this.authService.getTimezone().subscribe(
      success => {

        const encrypted = CryptoJS.AES.encrypt(JSON.stringify({ cipher: success }), key, { mode: CryptoJS.mode.CTR, iv: iv, padding: CryptoJS.pad.NoPadding });

        const req = {
          data: dataEncrypt,
          hash: encrypted.ciphertext.toString(CryptoJS.enc.Base64)
        }

        this.daviplataService.confirmPayWithOTP(req, "Bearer " + auth.token).subscribe(
          data => {

            //Desencriptamos respuesta a voluntad y gracia de los señores de davivienda
            data = this.authService.decryptDataPlain(data);
            if (data.codigoError) {
              this.message = data.mensajeError;
            } else {
              data.transaction.amount = this.paymentRequest.data.amount;
              data.transaction.payment_date = moment(data.transaction.payment_date).toISOString();
              this.confirmation = data;
              setTimeout(() => {
                this._router.navigate(['/pagos/respuesta'], { state: { paymentRequest: this.paymentRequest, response: this.confirmation } });
              }, 100);
            }
            this.loadingScreenService.stopLoading();
          },
          error => {
            this.message = "parece haber problemas con la conexión";
            this.loadingScreenService.stopLoading();
          }
        )
      }
    )
  }

  validarDatos() {
    if (!this.valor || !this.documentType || !this.documentNumber) {
      const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
      const token = 'Bearer ' + auth.token;
      this.message = "Por favor ingrese todos los campos";
      let audit = {
        reference: this.paymentRequest.data.reference,
        customer_email: this.paymentRequest.data.email,
        client: this.paymentRequest.name,
        payment_reference: this.paymentRequest.data.payment_reference,
        response_token: null,
        error: null,
        dev_message: null
      };
      audit.dev_message = 'No se han llenado todos los campos';
      audit.error = 'No se han llenado todos los campos';
      this.auditError = {
        "client" : this.paymentRequest.name,
        "reference" : this.paymentRequest.data.reference,
        "payment_reference" : this.paymentRequest.data.payment_reference
      }
      this.changeStatusService.auditErrorStatusAttempted(audit, this.auditError, auth.token);
      return false
    }
    this.message = null;
    return true;
  }

  validarOtp() {
    if (!this.OTP || this.OTP == "") {
      const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
      const token = 'Bearer ' + auth.token;
      this.message = "Debe ingresar un código OTP valido";
      let audit = {
        reference: this.paymentRequest.data.reference,
        customer_email: this.paymentRequest.data.email,
        client: this.paymentRequest.name,
        payment_reference: this.paymentRequest.data.payment_reference,
        response_token: null,
        error: null,
        dev_message: null
      };
      audit.dev_message = 'Debe ingresar un código OTP valido';
      audit.error = 'Debe ingresar un código OTP valido';
      this.auditError = {
        "client" : this.paymentRequest.name,
        "reference" : this.paymentRequest.data.reference,
        "payment_reference" : this.paymentRequest.data.payment_reference
      }
      this.changeStatusService.auditErrorStatusAttempted(audit, this.auditError, auth.token);
      return false
    }
    this.message = null;
    this.addInfoDataLayerPay();
    return true;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  public addInfoDataLayerPay() {
    nameCategory = this.paymentRequest.data.client
    description = this.paymentRequest.data.description

    window.dataLayer.push({
      eventCategory: nameCategory + " - Intención de pago",
      eventAction: 'Pago con DAVIPLATA',
      eventLabel: description,
      eventValue: '',
      event: 'eventClick'
    });

  }

  private validarBono() {
    return new Promise<any>((resolve, reject) => {
      this.auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
      let request = {
        client: this.paymentRequest.name,
        amount: this.paymentRequest.aux_init_amount,
        dev_reference: this.paymentRequest.data.reference,
        bonus_voucher: this.paymentRequest.manual_bonus_voucher,
        id_type: this.paymentRequest.data.id_type,
        vat: this.paymentRequest.data.vat,
        rete_ica: this.paymentRequest.retentions ? this.paymentRequest.retentions.rete_ica : null,
        rete_iva : this.paymentRequest.retentions? this.paymentRequest.retentions.rete_iva : null,
        rete_fuente : this.paymentRequest.retentions ? this.paymentRequest.retentions.rete_fuente : null,
        // id_product : this.paymentRequest.data.id_producto,
        external_user_id : this.paymentRequest.data.external_user_id,
        token_external : this.paymentRequest.data.token_external,
        retentions : this.paymentRequest.data.retentions ? this.paymentRequest.data.retentions : null,
        name : this.paymentRequest.data.name,
        last_name : this.paymentRequest.data.last_name,
        email : this.paymentRequest.data.email,
        phone : this.paymentRequest.data.phone,
        manual_bonus : true,
        external_user_doc : this.paymentRequest.data.id,
        external_user_email : this.paymentRequest.data.email,
        products : this.paymentRequest.data.products ? this.paymentRequest.data.products : null
      };
      if (this.paymentRequest.manual_bonus_voucher) {
        this.validateBonusService.validBonus(request, "Bearer " + this.auth.token).subscribe(res => {
          if (res.bonus.code == 200) {
            this.paymentRequest.retentions = res.retentions;
            if (res.retentions) {
              this.paymentRequest.data.amount = res.retentions ? res.retentions.total_amount : res.bonus.result.total;
            }
            else {
              this.paymentRequest.data.amount = res.bonus.result.total;
            }
            this.paymentRequest.aplly_bonus = true;
            this.paymentRequest.voucher_name = res.bonus.result.type_discount;
            this.activateChip.emit(true);
            this.paymentRequest.manual_bonus = true;
            // this.paymentRequest.retentions.total_amount = res.bonus.amount;
            this.epaycoService.BuildJsonCard(res.bonus, this.paymentRequest);
            resolve(true);
          } else {
            this.activateChip.emit(false);
            Swal.fire({
              title: 'Bono expirado',
              text: 'Este bono ya expiró, por favor intente con uno nuevo o realice el pago total de la transacción',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-custom-primary'
              },
              confirmButtonText: 'Confirmar'
            })
            resolve(false);
          }
        })
      }else{
        resolve(true);
      }
    });
  }


  addToDataLayerCheckoutEvent(method: string, paymentRequest){
    this.datalayerService.addToDataLayerCheckoutEvent(method, paymentRequest);
  }

}
