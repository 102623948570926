import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class AuditErrorService {
  constructor(private http: HttpClient) {}

  public AuditError(error, request, token){
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    return this.http.post<any>(environment.apiUrl + '/api/audit-error', { error, request }, { headers });
  }

}
