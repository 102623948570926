export enum CustomerType {
  N = 'N',
  J = 'J',
}

export const customerTypes: { code: CustomerType; name: string }[] = [
  { code: CustomerType.N, name: 'Persona natural' },
  { code: CustomerType.J, name: 'Persona Jurídica' },

];
