import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface GeneralAuditRequest {
  description: string;
  reference: string;
  request: object;
  response: object;
  url: string;
}

export interface GeneralAuditResponse {
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class GeneralAuditService {
  readonly #http = inject(HttpClient);

  save(body: GeneralAuditRequest): Observable<any> {
    return this.#http.post<GeneralAuditResponse>(
      'payments/general-audit',
      body,
    );
  }
}
