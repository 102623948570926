import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {HttpClient} from '@angular/common/http';

import { RequestNuveiReference } from './interfaces/interfaces-states.component';
import { PaymentGatewayResponse } from '../../../../core/interfaces/payment-gateway-response.interface';


@Injectable({
  providedIn: 'root',
})
export class NuveiCardService {
  readonly #http = inject(HttpClient);

  getData(reference: string): Observable<PaymentGatewayResponse> {
    const urlBase = `payments/nuvei-status-transaction/card/${reference}`;

    return this.#http.get<any>(urlBase);
  }

  getReference(body: RequestNuveiReference) {
    return this.#http.post<{ checkout_url: string; reference: string }>(
      'payments/nuvei-init-reference',
      body,
    );
  }

  postUpdateNuveiTransaction(body: object) {
    return this.#http.post<any>('payments/nuvei-update-transaction', body);
  }
}
