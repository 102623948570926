import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PaymentConfigModel } from "../../../core/models/payment-config.model";
import { CurrencyPipe } from '@angular/common';
import { ConsumerPaymentezModel } from "../../../core/models/paymentez/consumer-paymentez.model";

import { PaymentezResponseSdkModel } from "../../../core/models/paymentez/paymentez-response-sdk.model";
import { CreditCardPaymentezUtility } from "../../../core/utilities/credit-card-paymentez.utility";
import { SessionStorageService } from "../../../core/services/session-storage.service";

declare var jsPDF;
declare var window: any;
//import * as jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { AuthModel } from 'src/app/core/models/auth.model';
import { AuditService } from 'src/app/core/services/audit.service';
import { PaymentezCheckoutService } from 'src/app/core/services/paymentez-checkout.service';

@Component({
  selector: 'app-purchase-credit-card',
  templateUrl: './purchase-credit-card.component.html',
  styleUrls: ['./purchase-credit-card.component.scss'],
  providers: [CurrencyPipe]
})

export class PurchaseCreditCardComponent implements OnInit {

  @Input() paymentRequest: PaymentConfigModel;

  @Input() response: PaymentezResponseSdkModel;

  @Output() imprimir: EventEmitter<any>;

  constructor(protected _router: Router,
    private activatedRoute: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private sessionStorageService: SessionStorageService,
    private auditService: AuditService,
    private datalayerService: DatalayerService,
    private paymentezCheckoutService : PaymentezCheckoutService) {

    this.imprimir = new EventEmitter();

  }
  status: string = '';
  url_return: string = '';
  url_retry: string = '';
  logo: string;
  authToken;
  paymentStatus;
  puntosColombia: boolean = false;
  nPuntosColombia: number = 0;
  statusPayment: boolean = false;
  registerPuntosColombia = false;
  puntosColombiaPromotion: string;
  hasPaymentReattempt:boolean;
  mixedPurchase: boolean;
  backOrDetails: string = "Volver a comercio";
  urlProblem: string = "";

  ngOnInit() {
    this.status = CreditCardPaymentezUtility.getValueResult(this.response.transaction.status);
    let logo = this.paymentRequest.url_icon.split('/');
    // local
    //this.logo = '../../../../assets/img/' + logo[(logo.length - 1)];
    // server
    this.logo = 'assets/img/' + logo[(logo.length - 1)];
    //this.logo = this.paymentRequest.url_icon;
    let auth = this.sessionStorageService.getItem(SessionStorageService.ORDEN);
    this.authToken = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    this.url_retry = this.sessionStorageService.getItem(SessionStorageService.URL_PAYMENT_REQUEST);
    this.paymentRequest.productsAnalyticsObject = this.sessionStorageService.getItem(SessionStorageService.PRODUCTSANALYTICS);
    this.url_return = auth['url_return'];
    let methodPayment = this.response.card ? null : "Daviplata";

    if(this.paymentRequest.name === 'doctoraki'){
      this.datalayerService.addInfoDataLayerPurchaseResumeConfirmation(this.response);
    }

    if (
      this.response.transaction.status === "RECHAZADO" ||
      this.response.transaction.status === "failure"
    ) {
      this.urlProblem = this.sessionStorageService.getItem<string>(
        SessionStorageService.URL_RETURN_PROBLEM
      );
      this.backOrDetails = this.urlProblem
        ? "MAS DETALLES"
        : this.backOrDetails;
    }

    // this.sessionStorageService.clearSessionStorage();
    this.hasPaymentReattempt = this.paymentRequest.payment_reattempt;
    // Puntos colombia
    if (this.paymentRequest.has_puntoscolombia) {
      if (this.response && this.response.transaction && this.response.transaction.puntos_colombia) {
        if (this.response.transaction.puntos_colombia.success && this.response.transaction.puntos_colombia.user_exist) {
          this.puntosColombia = true;
          this.nPuntosColombia = this.response.transaction.puntos_colombia.body.pointsEarned;
          this.puntosColombiaPromotion = this.paymentRequest.colombia_points_promotion;
        } else {
          this.registerPuntosColombia = true;
        }
      }
    }

    if (this.response && this.response.transaction.status === 'success') {
      this.statusPayment = true;
      this.datalayerService.purchaseEvent("", this.paymentRequest, auth, methodPayment);
    }

    this.paymentezCheckoutService.responsePointsColombiaCard.subscribe(
      res => {
        if ((res && res.transaction && res.transaction.puntos_colombia) && (res != null && this.paymentRequest.has_puntoscolombia) ) {
          if (res.transaction.puntos_colombia.success && res.transaction.puntos_colombia.user_exist) {
            this.puntosColombia = true;
            this.nPuntosColombia = res.transaction.puntos_colombia.body.pointsEarned;
            this.puntosColombiaPromotion = this.paymentRequest.colombia_points_promotion;
          } else {
            this.registerPuntosColombia = true;
          }
        }
      }
    );

    if(this.response.transaction.mixed_purchase){
      this.mixedPurchase = true;
    }

    this.paymentezCheckoutService.responseMixedPurchase.subscribe(res =>{
      if(res.mixed_purchase){
        this.mixedPurchase = true;
        this.response.transaction.mixed_purchase = res.mixed_purchase;
      }
    })


  }

  returnClient() {
    this.datalayerService.addInfoDataLayerPurchaseResume('Volver a comercio', this.paymentRequest.name, null, this.paymentRequest.name == 'doctoraki'? this.response.transaction.status : null)
    this.validateStatus();
    const request = {
      dev_reference: this.paymentRequest.data.reference,
      client: this.paymentRequest.data.client,
      subclient: this.paymentRequest.data.subclient,
      leave_type: "Salida por Boton",
      payment_status: this.paymentStatus,
      leave_page: true
    }

    this.auditService.saveAudit(request, this.authToken.token).subscribe(res => {
      this.sessionStorageService.clearSessionStorage();
      window.location.href = this.url_return;
    });

  }

  validateStatus() {

    if (this.response.transaction.status == 'failure') {
      this.paymentStatus = 'RECHAZADO';
      this.url_return = this.urlProblem ?? this.url_return;
    } else if (this.response.transaction.status == 'pending') {
      this.paymentStatus = 'PENDIENTE';
    } else {
      this.paymentStatus = 'APROBADO';

    }
  }

  retryPayment() {
    this.datalayerService.addInfoDataLayerPurchaseResume("Reintentar el pago", this.paymentRequest.name, null, this.paymentRequest.name == 'doctoraki'? this.response.transaction.status : null);
    window.location.href = this.url_retry + "&reload=" + + new Date().getTime();
  }

  printPdf() {
    this.imprimir.emit(true);
  }


  /*print(): void {
    let printContents, popupWin;
    //printContents = document.getElementById('pdf').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>${this.paymentRequest.data.reference}_${this.response.transaction.payment_date}</title>
          <link rel="stylesheet" href="${this.paymentRequest.url_style}">
        </head>
        <body onload="window.print();window.close()">
          <div id="pdf">
              <table id="page">
                <tr>
                  <td id="contentPDF">
                    <table id="header">
                      <tr>
                        <td><img src="${this.paymentRequest.url_icon}" alt=""></td>
                      </tr>
                    </table>
                    <table id="content">
                      <tr>
                        <td colspan="2">
                          <h1>Resumen de compra</h1>
                        </td>
                      </tr>
                      <tr>
                        <td class="owner">
                          <table>
                            <tr>
                              <td colspan="2">
                                <h2 class="owner-data">Datos del cliente</h2>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Nombre:</p>
                              </td>
                              <td>
                                <p><strong>${this.paymentRequest.data.name} ${this.paymentRequest.data.last_name}</strong></p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Correo:</p>
                              </td>
                              <td>
                                <p><strong>${this.paymentRequest.data.email}</strong></p>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <h2 class="pay-order owner-data">Orden de compra</h2>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Descripción:</p>
                              </td>
                              <td>
                                <p><strong>${this.paymentRequest.data.description}</strong></p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Número de referencia:</p>
                              </td>
                              <td>
                                <p><strong>${this.paymentRequest.data.reference}</strong></p>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td class="trans">
                          <table>
                            <tr>
                              <td colspan="2">
                                <h2 class="owner-data">Informaci&oacute;n de la transacci&oacute;n</h2>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Estado:</p>
                              </td>
                              <td>
                                <p [ngClass]="{
                                  'aprobado': this.response.transaction.status.toLowerCase() === 'success',
                                  'pendiente':this.response.transaction.status.toLowerCase() === 'pending',
                                  'rechazada':this.response.transaction.status.toLowerCase() === 'failure'
                                  }"><strong>${ CreditCardPaymentezUtility.getValueResult(this.response.transaction.status) } </strong></p>
                              </td>
                            </tr>
                            <tr>
                              <td><p>Valor pagado:</p></td>
                              <td><p [ngClass]="{
                                'aprobado': this.response.transaction.status.toLowerCase() === 'success',
                                'pendiente':this.response.transaction.status.toLowerCase() === 'pending',
                                'rechazada':this.response.transaction.status.toLowerCase() === 'failure'
                                }"><strong>${ this.currencyPipe.transform(this.paymentRequest.data.amount) }</strong></p></td>
                            </tr>
                            <tr>
                              <td><p>Fecha y hora:</p></td>
                              <td><p><strong>${this.response.transaction.payment_date}</strong></p></td>
                            </tr>
                            <tr>
                              <td><p>Medio de pago:</p></td>
                              <td><p><strong>Tarjeta de crédito</strong></p></td>
                            </tr>
                            <tr>
                              <td><p>Número de tarjeta</p></td>
                              <td><p><strong>************${this.response.card.number}</strong></p></td>
                            </tr>
                            <tr>
                              <td><p>Franquicia:</p></td>
                              <td><p><strong>${this.response.card.type}</strong></p></td>
                            </tr>
                            <tr>
                              <td><p>Número de cuotas:</p></td>
                              <td><p><strong>${this.response.transaction.installments}</strong></p></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tfoot>
                <td>
                  <p class="copyright">© 2019 Grupo Bolívar - Todos los derechos reservados</p>
                </td>
                </tfoot>
              </table>
              </div>

        </body>
      </html>`
    );
    popupWin.document.close();
  }*/
}
