import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { PaymentezResponseStatusTransferModel } from "../../../core/models/paymentez/paymentez-response-status-transfer.model";
import { PaymentConfigModel } from "../../../core/models/payment-config.model";
import { ActivatedRoute, Router } from "@angular/router";
import { CreditCardPaymentezUtility } from "../../../core/utilities/credit-card-paymentez.utility";
import { SessionStorageService } from "../../../core/services/session-storage.service";
import { CurrencyPipe } from '@angular/common';
import { AuthModel } from 'src/app/core/models/auth.model';
import { AuditService } from 'src/app/core/services/audit.service';
import { PaymentezService } from 'src/app/core/services/paymentez.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';


@Component({
  selector: "app-purchase-pse",
  templateUrl: "./purchase-pse.component.html",
  styleUrls: ["./purchase-pse.component.scss"],
  providers: [CurrencyPipe],
})
export class PurchasePseComponent implements OnInit {
  @Input() response: PaymentezResponseStatusTransferModel;

  @Input() paymentRequest: PaymentConfigModel;

  @Output() imprimir: EventEmitter<any>;
  public classStatus: string;

  constructor(
    protected _router: Router,
    private activatedRoute: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private auditService: AuditService,
    private paymentezService: PaymentezService,
    private datalayerService: DatalayerService,
    private sessionStorageService: SessionStorageService
  ) {
    this.imprimir = new EventEmitter();
  }

  status: string = "";
  url_return: string = "";
  url_retry: string = "";
  logo: string;
  authToken;
  paymentStatus;
  puntosColombia: boolean = false;
  nPuntosColombia: number = 0;
  statusPayment: boolean = false;
  registerPuntosColombia = false;
  puntosColombiaPromotion: string;
  hasPaymentReattempt: boolean;
  mixedPurchase: boolean = false;
  backOrDetails: string = "Volver a comercio";
  urlProblem: string = "";

  ngOnInit() {
    let auth = this.sessionStorageService.getItem(SessionStorageService.ORDEN);
    this.authToken = this.sessionStorageService.getItem<AuthModel>(
      SessionStorageService.AUTH
    );
    this.url_return = auth["url_return"];
    this.url_retry = this.sessionStorageService.getItem(
      SessionStorageService.URL_PAYMENT_REQUEST
    );
    this.hasPaymentReattempt = this.paymentRequest.payment_reattempt;

    this.paymentezService.responsePointsColombiaPse.subscribe((res) => {
      if (
        res != null &&
        this.paymentRequest.has_puntoscolombia &&
        res &&
        res.transaction &&
        res.transaction.puntos_colombia
      ) {
        if (
          res.transaction.puntos_colombia.success &&
          res.transaction.puntos_colombia.user_exist
        ) {
          this.puntosColombia = true;
          this.nPuntosColombia =
            res.transaction.puntos_colombia.body.pointsEarned;
          this.puntosColombiaPromotion =
            this.paymentRequest.colombia_points_promotion;
        } else {
          this.registerPuntosColombia = true;
        }
      }
    });
    if (this.response.transaction.status === "RECHAZADO" || this.response.transaction.status === "failure") {
      this.urlProblem = this.sessionStorageService.getItem<string>(
        SessionStorageService.URL_RETURN_PROBLEM
      );
      this.backOrDetails = this.urlProblem ? "MAS DETALLES" : this.backOrDetails;
    }
    this.searchStatus(this.response);
    this.validateStatus();
  }

  ngOnChanges() {
    //Puntos colombia
    if (this.paymentRequest.has_puntoscolombia) {
      if (
        this.response &&
        this.response.transaction &&
        this.response.transaction.puntos_colombia
      ) {
        if (
          this.response.transaction.puntos_colombia.success &&
          this.response.transaction.puntos_colombia.user_exist
        ) {
          this.puntosColombia = true;
          this.nPuntosColombia =
            this.response.transaction.puntos_colombia.body.pointsEarned;
          this.puntosColombiaPromotion =
            this.paymentRequest.colombia_points_promotion;
        } else {
          this.registerPuntosColombia = true;
        }
      }
    }

    if (
      this.response &&
      (this.response.transaction.status === "approved" ||
        this.response.transaction.status === "success")
    ) {
      this.statusPayment = true;
    }

     if (
       this.response.transaction.status === "RECHAZADO" ||
       this.response.transaction.status === "failure"
     ) {
       this.urlProblem = this.sessionStorageService.getItem<string>(
         SessionStorageService.URL_RETURN_PROBLEM
       );
       this.backOrDetails = this.urlProblem
         ? "MAS DETALLES"
         : this.backOrDetails;
     }
  }

  searchStatus(data: any) {
    if (data) {
      this.status = CreditCardPaymentezUtility.getValueResult(
        data.transaction.status
      );
      this.mixedPurchase = this.response.transaction.mixed_purchase
        ? true
        : false;

      if (this.paymentRequest.name === "doctoraki") {
        this.datalayerService.addInfoDataLayerPurchaseResumeConfirmation(
          this.response
        );
      }
    } else {
      setTimeout(() => {
        this.searchStatus(this.response);
      }, 200);
    }
  }

  returnClient() {
    this.datalayerService.addInfoDataLayerPurchaseResume(
      "Volver a comercio",
      this.paymentRequest.name,
      null,
      this.paymentRequest.name == "doctoraki"
        ? this.response.transaction.status
        : null
    );
    this.validateStatus();
    const request = {
      dev_reference: this.paymentRequest.data.reference,
      client: this.paymentRequest.client,
      subclient: this.paymentRequest.data.subclient,
      leave_type: "Salida por Boton",
      payment_status: this.paymentStatus,
      leave_page: true,
    };
    console.log("buscando el token: " + this.authToken);
    this.auditService
      .saveAudit(request, this.authToken.token)
      .subscribe((res) => {
        window.location.href = this.url_return;
      });
  }

  validateStatus() {
    if (
      this.response.transaction.status == "failure" ||
      this.response.transaction.status == "RECHAZADO"
    ) {
      this.paymentStatus = "RECHAZADO";
      this.classStatus = "rechazada";
      this.url_return = this.urlProblem ?? this.url_return;
    } else if (
      this.response.transaction.status == "pending" ||
      this.response.transaction.status == "PENDIENTE"
    ) {
      this.paymentStatus = "PENDIENTE";
      this.classStatus = "pendiente";
    } else {
      this.paymentStatus = "APROBADO";
      this.classStatus = "aprobado";

    }
  }
  retryPayment() {
    this.datalayerService.addInfoDataLayerPurchaseResume(
      "Reintentar el pago",
      this.paymentRequest.name,
      null,
      this.paymentRequest.name == "doctoraki"
        ? this.response.transaction.status
        : null
    );
    window.location.href = this.url_retry + "&reload=" + +new Date().getTime();
  }

  printPdf() {
    this.imprimir.emit(true);
  }
}
