import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationComponModule } from '../../../shared/ApplicationComponModule';
import { MatCardModule } from '@angular/material/card';
import { PurchaseSummaryModule } from '@payment-app/components/purchase-summary/purchase-summary.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, PurchaseSummaryModule],
  exports: [ApplicationComponModule, MatCardModule, PurchaseSummaryModule],
})
export class PaymentMethodsRedirectionModule {}
