import { Component, OnInit, Input, Inject, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';


@Component({
  selector: 'app-default-dialog',
  templateUrl: './default-dialog.component.html',
  styleUrls: ['./default-dialog.component.scss']
})
export class DefaultDialogComponent implements OnInit {

  @Input() paymentRequest: PaymentConfigModel = {
    card_franchises: {},
    platform_url: null
  };
  @Input() title = '¿estas seguro?';
  @Input() message: any = null;
  @Input() defaultBtnText = 'Aceptar';
  @Input() type: string = null;
  @Input() debit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DefaultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    if (this.data) {
      this.paymentRequest = this.data.paymentRequest ? this.data.paymentRequest : this.paymentRequest;
      this.type = this.data.type ? this.data.type : null;
      this.title = this.data.title;
      this.message = this.data.message;
      this.message = this.sanitizer.bypassSecurityTrustHtml(this.message);
      this.defaultBtnText = this.data.defaultBtnText ? this.data.defaultBtnText : this.defaultBtnText;
      this.debit =  this.data.debit ? this.data.debit : false;
    }
  }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

}
