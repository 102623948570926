import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import _departamentos from './_departamentos';
import _ciudades from './_ciudades';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaylinkService {

  constructor(
    private http: HttpClient
  ) {
  }

  public loadPaylinks(token: string, page, limit, request) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    let url = environment.apiUrl + '/api/get-links?';

    if (page) {
      url += `&page=${page}`;
    }
    if (limit) {
      url += `&limit=${limit}`;
    }
    return this.http.post<any>(url, request, { headers });
  }

  public redirectPayLink(id: string, token: string) {
    const headers = new HttpHeaders()
      .set('Authorization', token);
    return this.http.get<any>(environment.apiUrl + `/paylink?q=${id}`, { headers });
  }

  public reportPaylinksExcel(request, token) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');

    const url = environment.apiUrl + '/api/download-paylinks-excel';

    return this.http.post(url, request, { responseType: 'blob', headers });
  }

  cancelOrReactivePayLink(request, token) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');

    const url = environment.apiUrl + '/api/cancel-paylink';

    return this.http.post(url, request, { headers });
  }

  getDepartments() {
    // return this.http.get<any>('https://ags.esri.co/arcgis/rest/services/DatosAbiertos/SERVICIOS_PUBLICOS_2005_DPTO/MapServer/0/query?where=1%3D1&outFields=DEPTO&returnGeometry=false&orderByFields=DEPTO ASC&outSR=4326&f=json');
    return of(_departamentos)
  }

  getMunicipalities(department: String) {
    const ciudad = _ciudades.find(ciudad => ciudad.name === department)
    return of(ciudad)
    // return this.http.get<any>(`https://ags.esri.co/arcgis/rest/services/DatosAbiertos/SERVICIOS_PUBLICOS_2005_MPIO/MapServer/0/query?where=DEPTO='${department}'&outFields=MPIO_CNMBR&returnGeometry=false&orderByFields=MPIO_CNMBR ASC&outSR=4326&f=json`);
  }

  public massiveLinks(request, token) {
    const formData = new FormData();
    formData.append("file", request.file);
    formData.append("client", request.client);
    formData.append("subclient", request.subclient);

    const headers = new Headers();
    headers.append("Authorization", token);

    const url = environment.apiUrl + "/api/upload-excel-file";

    return fetch(url, {
      method: "POST",
      headers: headers,
      body: formData,
      redirect: "follow",
    });
  }
}
