import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { DateFormatPipe } from './dateFormat/date-format.pipe';
import { DateFormatEsPipe } from './dateFormatES/date-format-es.pipe';


@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    DateFormatPipe, DateFormatEsPipe
  ],
  exports: [
    DateFormatPipe,
    DateFormatEsPipe
  ]
})
export class ApplicationPipesModule {}