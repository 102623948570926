import { AfterViewInit, Component, inject } from '@angular/core';
import { CurrencyPipe, NgIf } from '@angular/common';

import { PaymentRequestService } from '../../../../../payment/payment-request/payment-request.service';
import { Retention, RetentionsService } from './retentions.service';

@Component({
  selector: 'info-retentions',
  standalone: true,
  imports: [NgIf, CurrencyPipe],
  templateUrl: './retentions.component.html',
  styleUrls: ['./retentions.component.scss'],
})
export class RetentionsComponent implements AfterViewInit {
  #paymentRequestService = inject(PaymentRequestService);
  #service = inject(RetentionsService);
  data = this.#paymentRequestService.paymentRequest.data;
  protected retention!: Retention;
  protected showInfo = false;

  ngAfterViewInit(): void {
    const realAmount = this.data.amount;

    if (this.data.retentions?.calculaRetenciones) {
      this.#service
        .getRetentions({
          calculateRetentions: this.data.retentions.calculaRetenciones
            ? '1'
            : '0',
          applyReteVat: this.data.retentions.aplicaReteIva ? '1' : '0',
          applyReteIca: this.data.retentions.aplicaReteIca ? '1' : '0',
          feeReteFuente: this.data.retentions.tarifaReteFuente,
          cityCode: this.data.retentions.ciudad
            ? this.data.retentions.ciudad.codigo
            : null,
          cityName: this.data.retentions.ciudad
            ? this.data.retentions.ciudad.name
            : null,
          amount: this.data.amount,
          reference: this.data.reference,
          amountIva: this.data.amount_iva ?? null,
          vat: this.data.vat,
          idType: this.data.id_type,
        })
        .subscribe((retention) => {
          this.retention = retention;
        });
    }

    this.#service.showInfo$.subscribe((showInfo) => {
      this.showInfo =
        this.#service.showInfo && this.retention?.calculateRetentions;

      if (this.showInfo) {
        this.#paymentRequestService.paymentRequest = {
          ...this.#paymentRequestService.paymentRequest,
          dataRetentions: this.retention,
          data: { ...this.data, amount: this.retention.totalAmount },
        };
      } else {
        this.#paymentRequestService.paymentRequest = {
          ...this.#paymentRequestService.paymentRequest,
          dataRetentions: null,
          data: { ...this.data, amount: realAmount },
        };
      }
    });
  }
}
