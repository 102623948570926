import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SavedCardComponent } from './saved-card.component';
import { RouterModule } from '@angular/router';
import { ComponentsMaterial } from 'src/app/components-material';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ComponentsMaterial,
    FormsModule
  ],
  declarations: [SavedCardComponent],
  providers: [],
  exports: [SavedCardComponent]
})
export class SavedCardModule {
}
