import { Injectable } from '@angular/core';
import {EpaycoService} from "./epayco.service";
import {PaymentezService} from "./paymentez.service";

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  constructor(private epaycoService: EpaycoService,
    private paymentezService: PaymentezService) { }


  gateway(method: string) : any{
    if(method=="ePayco")
      return this.epaycoService;
    if(method=="Paymentez")
      return this.paymentezService;
  }
}
