import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieGatewayService } from '../core/services/cookie.service';
import { SessionStorageService } from "../core/services/session-storage.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent {

  constructor(  private router: Router,
                private sessionStorageService: SessionStorageService,
                private cookieService: CookieGatewayService) { 
    this.sessionStorageService.removeItem(SessionStorageService.AUTH);
    this.sessionStorageService.removeLocalItem(SessionStorageService.AUTH);
    this.sessionStorageService.removeLocalItem(SessionStorageService.MENU_ITEMS);
    this.sessionStorageService.removeItem(SessionStorageService.NAMECLIENT);
    this.cookieService.removeCookie(CookieGatewayService.COOKIE_LOGGED);
    this.router.navigate(['/login-admin']);
  }
}
