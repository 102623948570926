import {CommonModule, NgOptimizedImage} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {SessionStorageService} from '@core/services/session-storage.service';
import html2canvas from 'html2canvas';

import {
  PaymentMethodsRedirectionModule
} from '@payment-app/components/payment-methods-redirection/payment-methods-redirection.module';
import {AuthModel} from '@core/models/auth.model';
import {LoadingScreenService} from '@core/services/loading-screen.service';
import {ConfigClient, StatusTransaction} from '../../core/interfaces/payment-gateway-response.interface';

declare var jsPDF;

@Component({
  selector: 'app-payment-methods-redirection',
  standalone: true,
  templateUrl: './payment-methods-redirection.component.html',
  imports: [CommonModule, PaymentMethodsRedirectionModule, NgOptimizedImage],
  styleUrls: ['./payment-methods-redirection.component.scss'],

})
export class PaymentMethodsRedirectionComponent implements OnInit {

  auth: AuthModel;
  service: any;
  transaction: StatusTransaction;
  configClient: ConfigClient;
  styleUrl: SafeResourceUrl;
  loadCss = false;
  urlRetry = this.sessionStorageService.getItem(SessionStorageService.URL_PAYMENT_REQUEST);
  logo: string;
  // TODO: align with low environments
  isTesting = false;

  constructor(
    private readonly route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private sessionStorageService: SessionStorageService,
    private loadingScreenService: LoadingScreenService) {

    this.auth = this.sessionStorageService.getItem(SessionStorageService.AUTH);
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.service = this.route.snapshot.data.service;
    const reference = this.route.snapshot.params.reference;
    const response = this.service.getData(reference);

    response.subscribe((res: any) => {
      const key = res.configurationClient.key;
      // window.location.href = this.getUrlResponse(res.transaction.paylink, key);
      this.transaction = res.transaction;
      this.configClient = res.configurationClient;
      this.configClient.urlStyles = this.sanitizer.bypassSecurityTrustResourceUrl(res.configurationClient.urlStyles);
      const logo = res.configurationClient.logo.split('/');
      this.logo = './assets/img/' + logo[logo.length - 1];
    })
  }

  async printPdf() {

    window.scroll(0, 0);
    const data = document.getElementById('redirection-order');

    html2canvas(data, {scale: 2})
      .then((canvas) => {

        const ctx = canvas.getContext('2d');
        ctx.shadowColor = 'transparent';
        ctx.shadowBlur = 0;

        const imgWidth = 208;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png');

        const pdf = new jsPDF({
          orientation: 'p',
          unit: 'mm',
          format: 'letter',
          compress: true
        });

        const imgProps = pdf.getImageProperties(contentDataURL);
        const width = pdf.internal.pageSize.getWidth();
        const ratio = width / imgProps.width;
        const height = ratio * imgProps.height;
        pdf.internal.pageSize.height = height;
        const position = 10;

        pdf.addImage(contentDataURL, "PNG", 0, position, width, height);
        pdf.save(this.transaction.reference + "_" + Date.now() + ".pdf");
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
      });
  }
}
