import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PaymentResponseComponent } from '../payment/payment-response/payment-response.component';
import { MatDialog } from "@angular/material/dialog";
import { ReturnBrowserComponent } from "../return-browser/return-browser.component";

@Injectable({
  providedIn: 'root'
})
export class ReturnProcessGuard implements CanActivate, CanDeactivate<unknown> {

  constructor(public dialog: MatDialog) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canDeactivate(
    component: PaymentResponseComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const clientName = component.paymentRequest.name;

    if (clientName === 'jelpit-conjuntos' && !nextState.url.includes('respuesta')) {
      window.location.href = component.paymentRequest.data.url_return;
      return true;
    }

    return true;
  }

}
