export default {
    "displayFieldName": "DPTO_CCDGO",
    "fieldAliases": {
        "DEPTO": "Departamento"
    },
    "fields": [
        {
            "name": "DEPTO",
            "type": "esriFieldTypeString",
            "alias": "Departamento",
            "length": 254
        }
    ],
    "features": [
        {
            "attributes": {
                "DEPTO": "AMAZONAS"
            }
        },
        {
            "attributes": {
                "DEPTO": "ANTIOQUIA"
            }
        },
        {
            "attributes": {
                "DEPTO": "ARAUCA"
            }
        },
        {
            "attributes": {
                "DEPTO": "ATLANTICO"
            }
        },
        {
            "attributes": {
                "DEPTO": "BOGOTA"
            }
        },
        {
            "attributes": {
                "DEPTO": "BOLIVAR"
            }
        },
        {
            "attributes": {
                "DEPTO": "BOYACA"
            }
        },
        {
            "attributes": {
                "DEPTO": "CALDAS"
            }
        },
        {
            "attributes": {
                "DEPTO": "CAQUETA"
            }
        },
        {
            "attributes": {
                "DEPTO": "CASANARE"
            }
        },
        {
            "attributes": {
                "DEPTO": "CAUCA"
            }
        },
        {
            "attributes": {
                "DEPTO": "CESAR"
            }
        },
        {
            "attributes": {
                "DEPTO": "CHOCO"
            }
        },
        {
            "attributes": {
                "DEPTO": "CORDOBA"
            }
        },
        {
            "attributes": {
                "DEPTO": "CUNDINAMARCA"
            }
        },
        {
            "attributes": {
                "DEPTO": "GUAINIA"
            }
        },
        {
            "attributes": {
                "DEPTO": "GUAVIARE"
            }
        },
        {
            "attributes": {
                "DEPTO": "HUILA"
            }
        },
        {
            "attributes": {
                "DEPTO": "LA GUAJIRA"
            }
        },
        {
            "attributes": {
                "DEPTO": "MAGDALENA"
            }
        },
        {
            "attributes": {
                "DEPTO": "META"
            }
        },
        {
            "attributes": {
                "DEPTO": "NARIÑO"
            }
        },
        {
            "attributes": {
                "DEPTO": "NORTE DE SANTANDER"
            }
        },
        {
            "attributes": {
                "DEPTO": "PUTUMAYO"
            }
        },
        {
            "attributes": {
                "DEPTO": "QUINDIO"
            }
        },
        {
            "attributes": {
                "DEPTO": "RISARALDA"
            }
        },
        {
            "attributes": {
                "DEPTO": "SAN ANDRES,PROVIDENCIA Y SANTA CATALINA"
            }
        },
        {
            "attributes": {
                "DEPTO": "SANTANDER"
            }
        },
        {
            "attributes": {
                "DEPTO": "SUCRE"
            }
        },
        {
            "attributes": {
                "DEPTO": "TOLIMA"
            }
        },
        {
            "attributes": {
                "DEPTO": "VALLE DEL CAUCA"
            }
        },
        {
            "attributes": {
                "DEPTO": "VAUPES"
            }
        },
        {
            "attributes": {
                "DEPTO": "VICHADA"
            }
        }
    ]
}