import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackMessageComponent } from "../../commons/snack-message/snack-message.component";
import { LoadingScreenService } from '../services/loading-screen.service';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private loadingScreenService: LoadingScreenService 
  ) {}


  //metodo sobreescrito para recibir todas las peticiones http de la aplicacion
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let request = req;

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        if (err.status === 401 || err.status === 403 ) {
          this.loadingScreenService.stopLoading();
          const message = "Tu sesión ha expirado";
          this.presentError(message);
        }

        return throwError( err );

      })
    );
  }

  presentError(message: any) {
    this._snackBar.openFromComponent(SnackMessageComponent, {
      data: message,
      panelClass: ["snackError"],
      duration: 4000
    });
  }

}
