import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PuntosColombiaResponse } from '../models/puntos-colombia-response.model';
import { EncryptService } from './encrypt.service';

@Injectable({
  providedIn: 'root'
})

export class PuntosColombiaService {

  documents = {
    CC: 2,
    NIT: 5,
    CE: 3,
  }

  constructor(private http: HttpClient, private encryptService: EncryptService) { }

  getPointsUser(request: any, token: string): Observable<PuntosColombiaResponse> {

    request = this.encryptService.encryptCipherData(request);

    const headers = this.getHeaders(token);

    return this.http.post<PuntosColombiaResponse>(`${environment.apiUrl}/api/puntosColombia/shortBalance`, request, { headers });
  }

  getOtpCode(request: any, token: string): Observable<PuntosColombiaResponse> {

    request = this.encryptService.encryptCipherData(request);

    const headers = this.getHeaders(token);
    return this.http.post<PuntosColombiaResponse>(`${environment.apiUrl}/api/puntosColombia/beginIdentityValidation`, request, { headers });
  }

  redemptionPoints(request: any, token): Observable<PuntosColombiaResponse> {

    request = this.encryptService.encryptCipherData(request);
    const headers = this.getHeaders(token);

    return this.http.post<PuntosColombiaResponse>(`${environment.apiUrl}/api/puntosColombia/processRedemption`, request, { headers });
  }

  getToken(reference: string, subclient: string, token: string): Observable<PuntosColombiaResponse> {

    const params = new HttpParams()
      .append('reference', reference)
      .append('subclient', subclient)

    const headers = this.getHeaders(token);

    return this.http.get<PuntosColombiaResponse>(`${environment.apiUrl}/api/puntosColombia/getTokenPuntos`, { params, headers });
  }

  /** @Route("/payMixedPoints", name="pay_mixed_points", methods={"POST"}) */
  redemptionPayMixedPonts(request: any, token): Observable<PuntosColombiaResponse> {
    
    request = this.encryptService.encryptCipherData(request);
    const headers = this.getHeaders(token);
    return this.http.post<PuntosColombiaResponse>(`${environment.apiUrl}/api/puntosColombia/payMixedPoints`, request, { headers });
  }

  reverseTransaction(request: any, token) : Observable<PuntosColombiaResponse> {
    request = this.encryptService.encryptCipherData(request);
    const headers = this.getHeaders(token);

      return this.http.post<PuntosColombiaResponse>(`${environment.apiUrl}/api/puntosColombia/reversePuntosColombia`, request, { headers });
  }

  
  getHeaders(token: string){
    let headers = new HttpHeaders();

    headers = headers.append('Authorization', token)
    headers = headers.append('Content-Type', 'application/json')

    if(!environment.apiUrl.includes('localhost')){
          headers = headers.append('Strict-Transport-Security', 'max-age=31536000; includeSubdomains; preload');
          headers = headers.append('Content-Security-Policy', "default-src 'self'")
          headers = headers.append('X-Frame-Options', 'DENY')
          headers = headers.append('X-Content-Type-Options', 'nosniff')
          headers = headers.append('Referrer-Policy', 'no-referrer-when-downgrade')
          headers = headers.append('Permissions-Policy', "geolocation=(),midi=(),sync-xhr=(),microphone=(),camera=(),magnetometer=(),gyroscope=(),fullscreen=(self),payment=()")
          headers = headers.append('X-XSS-Protection', '1; mode=block')          
    }

    return headers
  }


}
