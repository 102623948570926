import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { SessionStorageService } from "../../core/services/session-storage.service";
import { PAYMENT, DEV, QA, url_bolivar } from 'src/app/core/utilities/enviroment-consts';

@Component({
  selector: 'app-payment-message',
  templateUrl: './payment-message.component.html',
  styleUrls: ['./payment-message.component.scss']
})
export class PaymentMessageComponent implements OnInit {
  message: string;
  urlReturnProblem = url_bolivar;

  ignorePrefixes = [
    PAYMENT,
    DEV,
    QA
  ];

  constructor(
    private _router: Router,
    private sessionStorageService: SessionStorageService
  ) {

    this.message = this._router.getCurrentNavigation().extras.state.message;

  }

  ngOnInit() {
    try {
      if (this.sessionStorageService.getItem(SessionStorageService.URL_RETURN_PROBLEM) !== null) {
        this.urlReturnProblem = this.sessionStorageService.getItem(SessionStorageService.URL_RETURN_PROBLEM);
      }
    } catch (error) {
    }
  }

  backUrlProblem() {
    window.open(this.urlReturnProblem, '_self');
  }

  getAbsoluteURL() {

    this.urlReturnProblem = window.location.protocol + window.location.host;

    this.ignorePrefixes.forEach(substring => {
      this.urlReturnProblem = this.urlReturnProblem.replace(substring, "");
    });

  }

}
