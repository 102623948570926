import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from "rxjs/operators";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  constructor(private http: HttpClient) {
  }

  getTokenClientModule(tokenCaptcha: string, tokenGateway: string, reference: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + tokenGateway
      })
    };
    return this.http.get<any>(`${environment.apiUrl}/api/captcha/captchaValidation?token=${tokenCaptcha}&reference=${reference}`, httpOptions)
      .pipe(
        map((response) => response),
        catchError((err) => {
          console.log('error caught in service')
          console.error(err);
          return throwError(err);
        })
      );
  }
}
