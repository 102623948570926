import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CardFranchises, Dialog } from '@core/models/payment-config.model';
import { InfoPaymentMethodDialogComponent } from './info-payment-method-dialog.component';

export interface InfoPaymentMethodData {
  cardFranchises: CardFranchises;
  dialog: Dialog;
  isDebit: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class InfoPaymentMethodDialogService {
  #matDialog = inject(MatDialog);

  open(data: InfoPaymentMethodData): void {
    this.#matDialog.open(InfoPaymentMethodDialogComponent, {
      width: '456px',
      minHeight: '200px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data,
    });
  }
}
