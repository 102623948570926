import { JQUERY350, NUVEI_CHECKOUT, NUVEI_PG } from '@core/services/cdn-loader/cdn-constants';
export interface Cdn {
  name: string;
  src: string;
}

export const CdnStore: Cdn[] = [
  { name: JQUERY350, src: 'https://code.jquery.com/jquery-3.5.0.min.js' },
  { name: NUVEI_CHECKOUT, src: 'https://cdn.paymentez.com/ccapi/sdk/payment_checkout_3.0.0.min.js' },
  { name: NUVEI_PG, src: 'https://cdn.paymentez.com/ccapi/sdk/payment_sdk_stable.min.js' },
];
