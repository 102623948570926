import {Component, Input, OnInit} from '@angular/core';
import {StatusTransaction} from '../../../core/interfaces/payment-gateway-response.interface';

@Component({
  selector: 'app-purchase-summary-client-info',
  standalone: false,
  templateUrl: './purchase-summary-client-info.component.html',
  styleUrls: ['./purchase-summary-client-info.component.scss'],
})
export class PurchaseSummaryClientInfoComponent implements OnInit {
  // NuveiResponse;
  constructor() {
  }

  @Input() transaction: StatusTransaction;

  ngOnInit(): void {
  }
}
