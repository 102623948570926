import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackMessageComponent } from './snack-message.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        MatSnackBarModule,
        MatIconModule
    ],
    declarations: [SnackMessageComponent],
    exports: [SnackMessageComponent]
})
export class SnackMessageModule {
}
