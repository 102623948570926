import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { PaymentComponent } from './payment.component';
import { PaymentRequestComponent } from './payment-request/payment-request.component';
import { PaymentRoutingModule } from './payment-routing.module';
import { PaymentLoadComponent } from './payment-load/payment-load.component';
import { PaymentResponseComponent } from './payment-response/payment-response.component';
import { GatewayModule } from '../gateway/gateway.module';
import { PaymentMessageComponent } from './payment-message/payment-message.component';
import { ApplicationComponModule } from '../shared/ApplicationComponModule';
import { AddDataComponent } from './payment-request/add-data/add-data.component';
import { FormTitleComponent } from './payment-request/form-title/form-title.component';
import { ProductDataComponent } from './payment-request/product-data/product-data.component';
import { OwnerDataComponent } from './payment-request/owner-data/owner-data.component';
import { MobileProductDataComponent } from './payment-request/mobile-product-data/mobile-product-data.component';
import { ComponentsMaterial } from '../components-material';
import { DialogSummaryComponent } from './payment-request/mobile-product-data/dialog-summary/dialog-summary.component';
import { PurchaseCreditCardComponent } from './payment-response/purchase-credit-card/purchase-credit-card.component';
import { PurchasePseComponent } from './payment-response/purchase-pse/purchase-pse.component';
import { PurchaseCheckoutComponent } from './payment-response/purchase-checkout/purchase-checkout.component';
import { ApplicationPipesModule } from '@core/pipes/application-pipes.module';
import { HtmlPipe } from '@core/pipes/html.pipe';
import { PaymentRedirectComponent } from './payment-redirect/payment-redirect.component';
import { PaymentDavipuntosComponent } from './payment-davipuntos/payment-davipuntos.component';
import { PurchaseDavipuntosComponent } from './payment-response/purchase-davipuntos/purchase-davipuntos.component';
import { PurchaseNequiComponent } from './payment-response/purchase-nequi/purchase-nequi.component';
import { PurchasePuntosColombiaComponent } from './payment-response/purchase-puntos-colombia/purchase-puntos-colombia.component';
import { PurchaseDiraPayComponent } from './payment-response/purchase-dira-pay/purchase-dira-pay.component';
import { PurchaseDiraPayPdfComponent } from './payment-response/purchase-dira-pay/purchase-dira-pay-pdf/purchase-dira-pay-pdf.component';
import { PaymentReturnUrlComponent } from './payment-return-url/payment-return-url.component';
import { PurchasePuntosPdfComponent } from './payment-response/purchase-puntos-colombia/pdf/purchase-puntos-pdf/purchase-puntos-pdf.component';
import { PurchaseClaroPayComponent } from './payment-response/purchase-claro-pay/purchase-claro-pay.component';
import { ReturnBrowserComponent } from '../return-browser/return-browser.component';
import { PaymentMethodsRoutingModule } from '../payment-gateway/payment-methods-routing.module';
import { InfoAmountToPayComponent } from '@payment-app/components/info-amount-to-pay/info-amount-to-pay.component';

@NgModule({
  declarations: [
    PaymentComponent,
    PaymentRequestComponent,
    PaymentLoadComponent,
    PaymentResponseComponent,
    PaymentMessageComponent,
    AddDataComponent,
    DialogSummaryComponent,
    FormTitleComponent,
    ProductDataComponent,
    OwnerDataComponent,
    MobileProductDataComponent,
    PurchaseCreditCardComponent,
    PurchasePseComponent,
    PurchaseCheckoutComponent,
    PaymentRedirectComponent,
    HtmlPipe,
    PaymentDavipuntosComponent,
    PurchaseDavipuntosComponent,
    PurchaseNequiComponent,
    PurchasePuntosColombiaComponent,
    PurchaseDiraPayComponent,
    PurchaseDiraPayPdfComponent,
    PaymentReturnUrlComponent,
    PurchasePuntosPdfComponent,
    PurchaseClaroPayComponent,
    ReturnBrowserComponent,
  ],
  imports: [
    CommonModule,
    PaymentRoutingModule,
    PaymentMethodsRoutingModule,
    SweetAlert2Module,
    GatewayModule,
    FormsModule,
    ComponentsMaterial,
    ApplicationPipesModule,
    ApplicationComponModule,
    ClipboardModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    InfoAmountToPayComponent,
  ],
})
export class PaymentModule {}
