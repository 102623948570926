import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appValidateSlider]',
  providers:[{provide: NG_VALIDATORS, useExisting:ValidateSliderDirective, multi:true}]
})
export class ValidateSliderDirective  implements Validator{

  constructor() { }

  @Input() minimunPoints;

  validate(control: import("@angular/forms").AbstractControl): import("@angular/forms").ValidationErrors {
    const slider = control.value;
    
    if(slider < Number(this.minimunPoints) && slider != 0 ){
      return {'puntos invalidos':{'message':'los puntos deben ser mayor a 5'}}
    }
    return null;
  }

}
