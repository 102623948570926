import {Component, Input, OnInit} from '@angular/core';
import {PaymentConfigModel} from "../../../core/models/payment-config.model";

@Component({
  selector: 'app-add-data',
  templateUrl: './add-data.component.html',
  styleUrls: ['./add-data.component.scss']
})
export class AddDataComponent implements OnInit{

  @Input() paymentRequest: PaymentConfigModel;

  constructor() {
  }


  ngOnInit() {}

}
