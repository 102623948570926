import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {ResponseUserCardPaymentezModel} from "../models/paymentez/response-user-card-paymentez.model";
import {UserCardPaymentezModel} from "../models/paymentez/user-card-paymentez.model";

@Injectable({
  providedIn: 'root'
})
export class UserCardService {

  constructor(
    private http: HttpClient
  ){}

  addCard(obj : UserCardPaymentezModel, token: string) : Observable<ResponseUserCardPaymentezModel>{
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.http.post<ResponseUserCardPaymentezModel>(environment.apiUrl + "/api/user-card",  obj,{headers});
  }

  getCard(obj : UserCardPaymentezModel, token: string) : Observable<ResponseUserCardPaymentezModel>{
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.http.post<ResponseUserCardPaymentezModel>(environment.apiUrl + "/api/view/user-card",  obj,{headers});
  }

  getAuth(token: string, name: string, subClient:string = null ) : Observable<any>{
    const headers = new HttpHeaders()
      .set('Authorization', token)

    let url = environment.apiUrl + "/api/token?q=" + name;

    if (subClient){
      url = url + "&subclient=" + subClient;
    }
    return this.http.get<any>(url ,{headers});
  }
  
  addResponsePaymentez(obj : any, token: string) : Observable<any>{
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.http.post<any>(environment.apiUrl + "/api/result",  obj,{headers});
  }
}