import {Injectable, Injector} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {
  NuveiBankTransferService
} from '@payment-app/components/payment-methods/methods/nuvei-bank-transfer/nuvei-bank-transfer.service';
import { NuveiCardService } from '@payment-app/components/payment-methods/methods/nuvei-card/nuvei-card.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsRedirectionResolver implements Resolve<any> {

  constructor(
    private readonly injector: Injector,
    protected router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const params = route.params;

    if (params.method === 'pse-nuvei') {
      return this.injector.get(NuveiBankTransferService);
    }
    if (params.method === 'card-nuvei') {
      return this.injector.get(NuveiCardService);
    }

    return this.router.navigate(["/pagos/mensaje"], {
      state: {
        message: 'La solicitud que intenta realizar no es valida',
      },
    });

  }
}
