import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CookieService } from 'ngx-cookie-service';

import { AppDateAdapter, APP_DATE_FORMATS } from '@core/adapters/date.adapter';
import { AuthInterceptorService } from '@core/interceptors/auth-interceptor.service';
import { AdminModule } from './admin/admin.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ConfirmDialogComponent } from './commons/confirm-dialog/confirm-dialog.component';
import { LoadingScreenComponent } from './shared/component/loading-screen/loading-screen.component';
import { LogoutComponent } from './logout/logout.component';
import { PaymentModule } from './payment/payment.module';
import { SnackMessageModule } from './commons/snack-message/snack-message.module';
import { VerifyCardDialogComponent } from './commons/verify-card-dialog/verify-card-dialog.component';
import {HttpAuthInterceptor} from "@payment-app/core/interceptors/http-auth.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    LoadingScreenComponent,
    LogoutComponent,
    ConfirmDialogComponent,
    VerifyCardDialogComponent,
  ],
  imports: [
    BrowserModule,
    PaymentModule,
    BrowserAnimationsModule,
    MatDialogModule,
    AppRoutingModule,
    HttpClientModule,
    SnackMessageModule,
    MatSnackBarModule,
    AdminModule,
    MatSnackBarModule,
    MatIconModule,
    SweetAlert2Module.forRoot(),
  ],
  providers: [
    CookieService,
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
  exports: [ConfirmDialogComponent, MatDialogModule],
})
export class AppModule {}
