import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidenavService } from './sidenav.service';
import { SidenavState } from './sidenav-state.enum';
import { SidenavItem } from './sidenav-item.interface';
import { SessionStorageService } from '../core/services/session-storage.service';
import { LoginModel } from '../core/models/login.model';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('sidenavState', [
      state(SidenavState.Collapsed, style({
        position: 'absolute',
        width: '70px'
      })),
      state(SidenavState.CollapsedHover, style({
        position: 'absolute',
        width: '250px'
      })),
      state(SidenavState.Expanded, style({
        position: 'relative',
        width: '250px'
      })),
      state(SidenavState.Mobile, style({
        position: 'absolute',
        width: '250px',
        transform: 'translate3d(-200px, 0, 0)',
        visibility: 'hidden'
      })),
      state(SidenavState.MobileOpen, style({
        position: 'absolute',
        width: '250px',
        transform: 'translate3d(0, 0, 0)',
        visibility: 'visible'
      })),
      transition(`${SidenavState.Expanded} => ${SidenavState.CollapsedHover}`, [
        style({ position: 'absolute' }),
        animate('300ms cubic-bezier(.35, 0, .25, 1)')
      ]),
      transition(`${SidenavState.Expanded} => ${SidenavState.Collapsed}`, [
        style({ position: 'absolute' }),
        animate('300ms cubic-bezier(.35, 0, .25, 1)')
      ]),
      transition(`${SidenavState.CollapsedHover} => ${SidenavState.Collapsed}`, [
        animate('300ms cubic-bezier(.35, 0, .25, 1)')
      ]),
      transition(`${SidenavState.Collapsed} => ${SidenavState.CollapsedHover}`,
        animate('300ms cubic-bezier(.35, 0, .25, 1)')
      ),
      transition(`${SidenavState.Mobile} => ${SidenavState.MobileOpen}`,
        animate('300ms cubic-bezier(.35, 0, .25, 1)')
      ),
      transition(`${SidenavState.MobileOpen} => ${SidenavState.Mobile}`,
        animate('300ms cubic-bezier(.35, 0, .25, 1)')
      )
    ]),
  ]
})
export class SidenavComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  public sideNavState: boolean = false;
  public linkText: boolean = false;
  public onSideNavChange: boolean;


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  sidenavState$: Observable<SidenavState>;

  sidenavState: string;
  isCollapsedState: boolean;

  isCollapsed: boolean;
  isMobile: boolean;
  nameUser;

  menu: SidenavItem[] = [];


  constructor(
    private breakpointObserver: BreakpointObserver,
    private _sidenavService: SidenavService,
    private sessionStorage: SessionStorageService,
    private router : Router
  ) {
    this.nameUser = "";
    this.addItems();
  }

  ngOnInit() {
    this._sidenavService.sidenavState$.pipe().subscribe(sidenavState => {
      this.isCollapsedState = sidenavState === SidenavState.Collapsed || sidenavState === SidenavState.CollapsedHover;
      this.isCollapsed = sidenavState === SidenavState.Collapsed;
    });
  }

  addItems() {

    try {
      let dynamicMenu = JSON.parse(atob(this.sessionStorage.getLocalItem(SessionStorageService.MENU_ITEMS)));
      let i = 1;
      this.nameUser = dynamicMenu.user;
      dynamicMenu = dynamicMenu.menu;
      dynamicMenu.forEach(field => {
        let menuItem = {
          position: i,
          pathMatchExact: true,
          name: field.description,
          routeOrFunction: field.router,
          icon: field.icon
        }

        this.menu.push(menuItem)
      });
    } catch (error) {
      alert("ocurrió un error al cargar el menú sidenav");
      this.menu.push({
        name: 'Dashboard',
        routeOrFunction: '/admin/dashboard',
        icon: 'dashboard',
        position: 1,
        pathMatchExact: true
      });

      this.menu.push({
        name: 'Estado de transacción',
        routeOrFunction: '/admin/check-transaction-status',
        icon: 'info',
        position: 2,
        pathMatchExact: true
      });

      this.menu.push({
        name: 'Reversar',
        routeOrFunction: '/admin/reverse',
        icon: 'settings_backup_restore',
        position: 3,
        pathMatchExact: true
      });

      this.menu.push({
        name: 'Reporte de transacciones',
        routeOrFunction: '/admin/report-transactions',
        icon: 'list',
        position: 4,
        pathMatchExact: true
      });

      this.menu.push({
        name: 'Reporte de suscripciones',
        routeOrFunction: '/admin/report-subscriptions',
        icon: 'card_membership',
        position: 3,
        pathMatchExact: true
      });

      this.menu.push({
        name: 'Links de pagos',
        routeOrFunction: '/admin/paylinks-list',
        icon: 'link',
        position: 3,
        pathMatchExact: true
      });

      this.menu.push({
        name: 'Sub-Clientes',
        routeOrFunction: '/admin/sub-clients',
        icon: 'supervisor_account',
        position: 3,
        pathMatchExact: true
      });

      this.menu.push({
        name: 'Conciliación Epayco',
        routeOrFunction: '/admin/epayco-conciliation',
        icon: 'link',
        position: 15,
        pathMatchExact: true
      });

      // this.menu.push({
      //   name: 'Configuraciones',
      //   routeOrFunction: '/admin/configurations',
      //   icon: 'settings',
      //   position: 5,
      //   pathMatchExact: true
      // });
    }

    // Salir
    this.menu.push({
      name: 'Cerrar sesión',
      routeOrFunction: '/admin/logout',
      icon: 'exit_to_app',
      position: 99,
      pathMatchExact: true
    });

  }

  private newMethod(): string {
    return 'Configuración';
  }

  toggleCollapsed() {
    this._sidenavService.sidenavState = this._sidenavService.sidenavState === SidenavState.Expanded ? SidenavState.Collapsed : SidenavState.Expanded;
  }

  collapseOnMobile() {
    this.isHandset$.subscribe(isMobile => {
      if (isMobile) {
        this.sidenav.close();
      }
    });
  }

  confirmDialogClose(routeOrFunction) {
    if (routeOrFunction == '/admin/logout') {     
      Swal.fire({
        title: 'Cerrar Sesión',
        text: '¿Está seguro que desea Cerrar Sesión?',
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-custom-primary',
          cancelButton: 'btn btn-custom-cancel'
        },
        confirmButtonText: 'Sí',
        cancelButtonText: 'No'
      }).then((result => {
        if (result.value) {
          this.router.navigate([routeOrFunction]);
        }
      }));
    } else {
      this.router.navigate([routeOrFunction]);
    }
  }

}
