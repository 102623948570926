import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cvv-help-text-dialog',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule],
  template: `
    <div class="container-dialog">
      <div class="container-dialog-data">
        <h4>Código CVV</h4>

        <p>
          El código CVV o CVC es un número de 3 o 4 dígitos que se utiliza para
          realizar transacciones de manera segura.
        </p>

        <p>
          Lo encuentra en la parte de atrás de las tarjetas de crédito y/o
          algunas débito.
        </p>

        <mat-dialog-actions>
          <button mat-stroked-button (click)="close()">Entendido</button>
        </mat-dialog-actions>
      </div>
    </div>
  `,
  styleUrls: ['./cvv-help-text-dialog.component.scss'],
})
export class CvvHelpTextDialogComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<CvvHelpTextDialogComponent>,
  ) {}

  close() {
    this.dialogRef.close();
  }
}
