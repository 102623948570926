import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-verify-card-dialog',
  templateUrl: './verify-card-dialog.component.html',
  styleUrls: ['./verify-card-dialog.component.scss']
})
export class VerifyCardDialogComponent implements OnInit {

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
  }

}
