import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-payment-return-url',
  templateUrl: './payment-return-url.component.html',
  styleUrls: ['./payment-return-url.component.scss']
})
export class PaymentReturnUrlComponent implements OnInit {

  constructor(
    private sessionStorageService: SessionStorageService,
    protected _router: Router,
  ) { }

  ngOnInit() {    
    const payment_return: string = this.sessionStorageService.getItem(SessionStorageService.PAYMENT_URL);

    if (!payment_return) {
      this._router.navigate(['/pagos/mensaje'], { state: { message: 'Esta solicitud no puede ser resuelta.' } });
      return;
    } else {
      window.location.href = payment_return;
    }
  }

}
