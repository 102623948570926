import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PaymentConfigModel } from '../models/payment-config.model';
import { PaymentTransactionModel } from '../models/paymentez/payment-transaction.model';
import { PaymentezCheckoutService } from './paymentez-checkout.service';
import { AuditErrorService } from './audit-error.service';


@Injectable({
  providedIn: 'root'
})
export class ChangeStatusService {

  constructor(
    private http: HttpClient,
    private paymentezCheckoutService: PaymentezCheckoutService,
    private auditErrorService: AuditErrorService
  ) { }

  updateStatutsAttempted(transaction: PaymentTransactionModel, base64: string) {
    const headers = new HttpHeaders()
      .set('Authorization', base64)
      .set('Content-Type', 'application/json');
    return this.http.post(environment.apiUrl + "/api/update-status-attempted", transaction, { headers });
  }

  public changeStatus(auth, paymentRequest: PaymentConfigModel, method, bank?, login?) {
    return new Promise<any>((resolve, reject) => {
      let transactionStatus: PaymentTransactionModel;
      let transactionStatusPSE;
      let transaction;
      let body;
      const token = 'Bearer ' + auth.token;
      if(bank != null){
        transactionStatusPSE = {
          client: paymentRequest.name,
          payment_method: method,
          payment_reference: paymentRequest.payment_reference_status ? paymentRequest.payment_reference_status : paymentRequest.data.payment_reference,
          amount: paymentRequest.data.amount ? paymentRequest.data.amount : null,
          body: bank ? bank : null          
        };
        transaction = transactionStatusPSE;
      }
      else{
        transactionStatus = {
          client: paymentRequest.name,
          payment_method: method,
          payment_reference: paymentRequest.payment_reference_status ? paymentRequest.payment_reference_status : paymentRequest.data.payment_reference,
          amount: paymentRequest.data.amount ? paymentRequest.data.amount : null
        }
        transaction = transactionStatus;
      }
      let messageAttempted = login ? `intento de logeo con:${transaction.payment_method}` : `intento de pago con: ${transaction.payment_method}`;
      let message ={
        message: messageAttempted
      }
      
      let paymentSend ={
        client: paymentRequest.name,
        payment_method: method,
        payment_reference: paymentRequest.payment_reference_status ? paymentRequest.payment_reference_status : paymentRequest.data.payment_reference,
        amount: paymentRequest.data.amount ? paymentRequest.data.amount : null,
        reference: transaction.body.dev_reference ? transaction.body.dev_reference : null 
      }
      this.updateStatutsAttempted(
        transaction, token
        ).subscribe(res => {
          resolve(true);
          this.auditErrorStatusAttempted(message, paymentSend, auth.token);
          console.log(res);
        });
        resolve(true);
      })
  }

  public auditErrorStatusAttempted(audit, data, token) {
    this.auditErrorService.AuditError(audit, data, token).subscribe(
      success => {
        console.log("registro INTENTADO actualizado exitosamente");
      },
      failure => {
        console.log(failure);
      }
    );
  }

}
