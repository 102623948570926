import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})

export class EncryptService {

    encryptCipherData(input){
        const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
        const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);
        const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify({ cipher: input }),
        key,
        {
            mode: CryptoJS.mode.CTR,
            iv,
            padding: CryptoJS.pad.NoPadding
        }
        );
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    }

    encryptStringData(input){
        const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
        const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);
        const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(input),
        key,
        {
            mode: CryptoJS.mode.CTR,
            iv,
            padding: CryptoJS.pad.NoPadding
        }
        );
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    }

    encryptCipherDataRaw(input){
        const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
        const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);
        const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify({ cipher: input }),
        key,
        {
            mode: CryptoJS.mode.CTR,
            iv,
            padding: CryptoJS.pad.NoPadding
        }
        );
        return encrypted;
    }

  encryptData  (
    data: any,
    hasSpecialCharacters = true
  ) {
    const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);

    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
      mode: CryptoJS.mode.CTR,
      iv,
      padding: CryptoJS.pad.NoPadding
    });

    const ciphertext = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

    if (hasSpecialCharacters) return this.replaceSpecialCharacters(ciphertext);

    return ciphertext;
  }

  decryptData(cipherText: string) {
    const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);

    const decrypted = CryptoJS.AES.decrypt(this.replaceCharacter(cipherText), key, {
      mode: CryptoJS.mode.CTR,
      iv,
      padding: CryptoJS.pad.NoPadding
    });

    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  };

  private replaceCharacter(cipherText: string) {
    return cipherText.replace(/(\-[0-9][0-9]\-)/g, (match: string) =>
      String.fromCharCode(parseInt(match.replace(/\-/g, ''), 10))
    );
  }

  private replaceSpecialCharacters(ciphertext: string) {
    return ciphertext.replace(
      /[!*'();:@&=+$,\/?#\[\]<>]/g,
      (match: string) => `-${match.charCodeAt(0)}-`
    );
  }
}
