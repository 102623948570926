import {Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy} from '@angular/core';
import { PaymentConfigModel } from "../../../../core/models/payment-config.model";
import { AuthModel } from "../../../../core/models/auth.model";
import { SessionStorageService } from "../../../../core/services/session-storage.service";

import * as CryptoJS from 'crypto-js';
import { PaymentezService } from "../../../../core/services/paymentez.service";

import { v4 as uuid } from 'uuid';
import { UserCardService } from "../../../../core/services/user-card.service";
import { UserCardPaymentezModel } from "../../../../core/models/paymentez/user-card-paymentez.model";
import { PaymentezRequestModel } from "../../../../core/models/paymentez/paymentez-request.model";
import { LoadingScreenService } from "../../../../core/services/loading-screen.service";
import { PaymentTransactionModel } from "../../../../core/models/paymentez/payment-transaction.model";
import { PaymentTransactionService } from "../../../../core/services/payment-transaction.service";
import { CreditCardPaymentezUtility } from "../../../../core/utilities/credit-card-paymentez.utility";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import { UntypedFormGroup, FormControl } from '@angular/forms';
import { PaymentMethodService } from '../../../../core/services/payment-method.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PaymentezComponent } from '../../paymentez.component';
import { DefaultDialogComponent } from 'src/app/commons/default-dialog/default-dialog.component';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import {fromEvent, Observable, of, Subscription} from 'rxjs';
import { EncryptService } from 'src/app/core/services/encrypt.service';
import { ConfirmDialogComponent } from 'src/app/commons/confirm-dialog/confirm-dialog.component';
import { ChangeStatusService } from 'src/app/core/services/change-status.service';
import { EpaycoService } from 'src/app/core/services/epayco.service'; //este es un comentario para cargar esta linea
import { EpaycoApifyService } from 'src/app/core/services/epayco-apify.service';
import { VerifyCardDialogComponent } from 'src/app/commons/verify-card-dialog/verify-card-dialog.component';
import {ReCaptchaV3Service} from "ng-recaptcha";
import {RecaptchaService} from "../../../../core/services/recaptcha.service";


declare var Paymentez: any;

declare var jQuery: any;
declare var $: any;
declare const ePayco: any;
declare var window: any;
var nameCategory: any;
var description: any;



//const url = 'https://cdn.paymentez.com/js/1.0.1/paymentez.min.js';
const url = environment.urlCdnPaymentez;
//             https://cdn.paymentez.com/js/ccapi/stg/paymentez.min.js
//declare var epayco: any;
@Component({
  selector: 'app-pay-credit-card',
  templateUrl: './pay-credit-card.component.html',
  styleUrls: ['./pay-credit-card.component.scss']
})
export class PayCreditCardComponent implements OnInit,OnDestroy {

  @ViewChild('cnumber') cardInput: ElementRef;
  loadAPI: Promise<any>;
  @Input() paymentRequest: PaymentConfigModel;
  @Input() debit: boolean;
  @Input() savedCards: any;
  @Input() isOpen:Observable<boolean> = of(false);
  private isOpenSusbcription:Subscription ;
  @Input() isPuntosColombia: boolean;
  @Output() dataPuntosColombia: any = new EventEmitter<string>();

  service: any;
  message: any;
  confirm: MatDialogRef<ConfirmDialogComponent> = null;
  quota: string;
  public cardPaymentForm: UntypedFormGroup;
  activateChip: boolean;
  datemask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  numbers: any;

  card_number: any;
  exp_month: any;
  exp_year: any;
  card_email: any;
  card_name: any;
  cvc: any;
  cellphone: any;
  token_gen: any;
  save_card: boolean = false;
  cardBin: number;
  useSavedCard: boolean = false;
  savedCardPay: any;
  doc_type_holder: any;
  doc_number_holder: any;
  validate_card_holder: boolean = false;
  validatorRetentions: boolean = false;
  activate_mixed: boolean = false;
  validate_save_card: boolean = false;
  infoDataCardTransaction: any;
  validateTokenizeCard: boolean = false;
  //validateCaptcha: boolean = false;

  constructor(
    public dialog: MatDialog,
    private userCardService: UserCardService,
    private paymentezService: PaymentezService,
    private sessionStorageService: SessionStorageService,
    private paymentTransactionService: PaymentTransactionService,
    private loadingScreenService: LoadingScreenService,
    protected router: Router,
    public dialogRef: MatDialogRef<PaymentezComponent>,
    private paymentMethodService: PaymentMethodService,
    private datalayerService: DatalayerService,
    private encryptService: EncryptService,
    private changeStatusService: ChangeStatusService,
    private epaycoService: EpaycoService,
    private epaycoApify: EpaycoApifyService,
    public verifyCardDialog:MatDialog,
    private httpService: RecaptchaService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {

    let iframe;
    let noscript;


    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });

    this.numbers = Array(36).fill(1).map((x, i) => (i + 1));
  }

  ngOnDestroy(): void {
    this.isOpenSusbcription.unsubscribe();
  }

  ngOnInit() {
    this.validateTokenizeCard = this.paymentRequest.tokenizeCard;
    this.card_email = this.paymentRequest.data.email;
    this.service = this.paymentMethodService.gateway(this.paymentRequest.gateway_active);
    this.service.setDialog(this.dialogRef);
    this.service.message.subscribe(message => {
      this.message = message;
      this.loadingScreenService.stopLoading();
    });
    this.epaycoApify.message.subscribe(message => {
      this.message = message;
      this.loadingScreenService.stopLoading();
    });
    this.service.activateChip.subscribe(activate => {
      this.activateChip = activate;
      this.paymentRequest.aplly_bonus = activate
    });
    this.epaycoApify.activateChip.subscribe(activate => {
      this.activateChip = activate;
      this.paymentRequest.aplly_bonus = activate
    });

    if (this.paymentRequest.card_holder) {
      this.validate_card_holder = true;
    }
    this.epaycoService.validatedRetentions.subscribe(retentions => {
      if (this.debit && this.paymentRequest.retentions && !this.paymentRequest.retentions.message) {
        this.validatorRetentions = retentions;
      }
      if (this.paymentRequest.retentions && this.paymentRequest.retentions.message) {
        this.paymentRequest.retentions = null;
      }
      if (this.paymentRequest.retentions && this.paymentRequest.retentions.message) {
        this.paymentRequest.retentions = null;
      }
    })
    this.epaycoService.activateMixed.subscribe(mixed_activate => {
      this.activate_mixed = mixed_activate;
    })

    if (this.paymentRequest.save_card) {
      this.validate_save_card = true;
    }
    this.isOpenSusbcription = this.isOpen.subscribe((isOpen) => {
      if (isOpen) this.getInfoRecaptcha();
    })

  }

  public loadScript() {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  async puntosColombiaSubmit(){

    this.loadingScreenService.startLoading();

    const paymentType = this.debit ? "0" : "1";


    //datalayer boton de pago
    let amountDatalayer = this.paymentRequest.data.amount
    let textButton = `PAGAR ${amountDatalayer}`;
    if (this.paymentRequest.name == 'ciencuadras') {
      this.datalayerService.AddInfoDataLayerPaymentButton(textButton);
    }
    if (this.paymentRequest.name == 'doctoraki') {
      this.datalayerService.addInfoDataLayerPurchaseResume(textButton, this.paymentRequest.name, this.debit ? 'TD' : 'TC')
    }
    this.datalayerService.checkoutEvent(paymentType, this.paymentRequest);

    this.message = {};
    let ban_errores = 0;

    if (this.debit) {
      this.quota = "1";
    }
    this.paymentRequest.is_debit = this.debit;

    if (this.paymentRequest.data.plan) {
      ban_errores = this.validateFields(ban_errores, false);
    } else {
      ban_errores = this.validateFields(ban_errores, true);
    }

    if (this.card_email !== this.paymentRequest.data.email) {
      this.save_card = false;
    }

    if (this.paymentRequest.data.subclient != null && this.validate_save_card == false) {
      this.save_card = false;
    }
    // this.save_card = true;

    const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    const body = {
      dev_reference: this.paymentRequest.data.reference ? this.paymentRequest.data.reference : null
    }
    await this.changeStatusService.changeStatus(auth, this.paymentRequest, "CARD", body);

    if (ban_errores === 0) {

      this.validateTokenizedCard();
      let token_card = await this.service.sendValidMask(this.paymentRequest, this.card_number);

      const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);

      let today = new Date();
      let year = today.getFullYear().toString().substring(0, 2);
      if(this.useSavedCard || token_card != null){
        let save_token_card = token_card != null ? token_card : this.savedCardPay.token;
        let _request = {
          dues: this.quota,
          exist_token_card: true,
          save_card: this.save_card,
          token_card: save_token_card,
          ip: this.service.getIpUser()
        };
        this.loadingScreenService.stopLoading();
        this.dataPuntosColombia.emit(_request)
      }else{
        let _request = {
          dues: this.quota,
          exist_token_card: false,
          save_card: this.save_card,
          token_card: '',
          card: {
            name: this.card_name,
            number: this.card_number.replace(/\s/g, ""),
            cvc: this.cvc,
            month: this.exp_month,
            year: `${year}${this.exp_year}`
          },
        }
        const token = auth.token;
        await this.epaycoApify.getTokenizedCard(token,_request,this.paymentRequest),

        this.loadingScreenService.stopLoading();
        this.dataPuntosColombia.emit(_request)
      }

    }else{
      this.loadingScreenService.stopLoading();

    }
  }

  async submit() {
    this.getInfoRecaptcha();
    this.loadingScreenService.startLoading();

    let audit = {
      reference: this.paymentRequest.data.reference,
      customer_email: this.paymentRequest.data.email,
      client: this.paymentRequest.name,
      payment_reference: this.paymentRequest.data.payment_reference,
      response_token: null,
      error: null,
      dev_message: null
    };

    const paymentType = this.debit ? "0" : "1";

    //datalayer boton de pago
    let amountDatalayer = this.paymentRequest.data.amount
    let textButton = `PAGAR ${amountDatalayer}`;
    if (this.paymentRequest.name == 'ciencuadras') {
      this.datalayerService.AddInfoDataLayerPaymentButton(textButton);
    }
    if (this.paymentRequest.name == 'doctoraki') {
      this.datalayerService.addInfoDataLayerPurchaseResume(textButton, this.paymentRequest.name, this.debit ? 'TD' : 'TC')
    }
    this.datalayerService.checkoutEvent(paymentType, this.paymentRequest);

    this.message = {};
    let ban_errores = 0;

    if (this.debit) {
      this.quota = "1";
    }
    this.paymentRequest.is_debit = this.debit;
    // else{
    //   this.paymentRequest.retentions = null;
    // }
    // si es suscripcion
    if (this.paymentRequest.data.plan) {
      ban_errores = this.validateFields(ban_errores, false);
    } else {
      ban_errores = this.validateFields(ban_errores, true);
    }

    if (this.card_email !== this.paymentRequest.data.email) {
      this.save_card = false;
    }

    if (this.paymentRequest.data.subclient != null && this.validate_save_card == false) {
      this.save_card = false;
    }

    const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    const body = {
      dev_reference: this.paymentRequest.data.reference ? this.paymentRequest.data.reference : null
    }
    await this.changeStatusService.changeStatus(auth, this.paymentRequest, "CARD", body);

    if (ban_errores === 0) {

      let token_card = null;

        this.validateTokenizedCard();
        token_card = await this.service.sendValidMask(this.paymentRequest, this.card_number);

      const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
      if (this.useSavedCard || token_card != null) {
        const token = 'Bearer ' + auth.token;
        const request = {
          dues: this.quota,
          exist_token_card: true,
          exist_validate_token: token_card != null ? true : false,
          save_card: this.save_card,
          doc_type_holder: this.doc_type_holder ? this.doc_type_holder : null,
          doc_number_holder: this.doc_number_holder ? this.doc_number_holder : null,
          activate_mixed: this.activate_mixed ? this.activate_mixed : null,
        };
        let save_token_card = token_card != null ? token_card : this.savedCardPay.token;
        this.service.transactionTokenizedCard(token, request, this.paymentRequest, save_token_card);

      } else {
        this.loadingScreenService.stopLoading();
        await this.openVerifyCardDialog(this.save_card);
        this.loadingScreenService.startLoading();
        let today = new Date();
        let year = today.getFullYear().toString().substring(0, 2);
        let _request = {
          email: this.card_email,
          dues: this.quota,
          doc_type_holder: this.doc_type_holder ? this.doc_type_holder : null,
          doc_number_holder: this.doc_number_holder ? this.doc_number_holder : null,
          activate_mixed: this.activate_mixed ? this.activate_mixed : null,
          card: {
            name: this.card_name,
            number: this.card_number,
            cvc: this.cvc,
            month: this.exp_month,
            year: `${year}${this.exp_year}`
          },
          save_card: this.save_card
        }

        this.epaycoApify.transactionCard(auth.token, _request, this.paymentRequest);

      }

    } else {
      this.loadingScreenService.stopLoading();
    }
  }

  sendDataCard(data) {
    this.infoDataCardTransaction = data;
  }

  validateFields(ban_errores, quotes) {

    const quote = quotes ? this.quota === null || this.quota === '' || this.quota === undefined : false;

    if (this.card_name === null || this.card_name === '' || this.card_name === undefined ||
      this.card_email === null || this.card_email === '' || this.card_email === undefined ||
      this.card_number === null || this.card_number === '' || this.card_number === undefined ||
      this.cvc === null || this.cvc === '' || this.cvc === undefined ||
      this.exp_year === null || this.exp_year === '' || this.exp_year === undefined ||
      this.exp_month === null || this.exp_month === '' || this.exp_month === undefined || quote
    ) {
      if (this.validate_card_holder) {
        if (this.doc_type_holder === null || this.doc_type_holder === '' || this.doc_type_holder == undefined ||
          this.doc_number_holder === null || this.doc_number_holder === '' || this.doc_number_holder == undefined) {
          this.message.message = 'Datos incompletos. Por favor diligenciar todos los campos.';
          this.message.origin = 'CARD';
          ban_errores = 1;
        }
      } else {
        this.message.message = 'Datos incompletos. Por favor diligenciar todos los campos.';
        this.message.origin = 'CARD';
        ban_errores = 1;
      }
    } else {
      if (this.card_number.length < 15) {
        this.message.message = 'El número de tarjeta ingresado es incorrecto';
        this.message.origin = 'CARD';
        ban_errores = 1;
      }
      else {
        if (this.exp_month > 12 || this.exp_month < 1) {
          this.message.message = 'El número del mes ingresado es incorrecto';
          this.message.origin = 'CARD';
          ban_errores = 1;
        }
        else {
          if (this.cvc.length < 3) {
            this.message.message = 'El número cvc ingresado es incorrecto';
            this.message.origin = 'CARD';
            ban_errores = 1;
          }
        }
      }
      if (!(/^[_a-zA-Z0-9-\+]+(\.[_a-zA-Z0-9-+]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]+)*\.[a-zA-Z]{2,4}$/.test(this.card_email))) {
        this.message.message = 'El correo electrónico ingresado es incorrecto';
        this.message.origin = 'CARD';
        ban_errores = 1;
      }

      if (!(/^[A-Za-zÀ-ú _.'-]*$/.test(this.card_name))) {
        this.message.message = 'El nombre ingresado es incorrecto';
        this.message.origin = 'CARD';
        ban_errores = 1;
      }

      if (this.cvc.length < 3) {
        this.message.message = 'El cvc debe tener al menos 3 digitos';
        this.message.origin = 'CARD';
        ban_errores = 1;
      }

      if (this.validate_card_holder) {
        if (!(/^[0-9-]*$/.test(this.doc_number_holder)) || this.doc_number_holder === null || this.doc_number_holder === '' || this.doc_number_holder == undefined) {
          this.message.message = 'El documento ingresado es inválido';
          this.message.origin = 'CARD';
          ban_errores = 1;
        }
        if (this.doc_type_holder === null || this.doc_type_holder === '' || this.doc_type_holder == undefined) {
          this.message.message = 'Debe seleccionar un tipo de documento';
          this.message.origin = 'CARD';
          ban_errores = 1;
        }
      }

      this.addInfoDataLayerPay();
    }

    return ban_errores;

  }

  help() {
    this.dialog.open(DefaultDialogComponent, {
      width: '465px',
      minHeight: '200px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        title: this.paymentRequest.dialogs.cvv_message.title,
        message: this.paymentRequest.dialogs.cvv_message.body,
        defaultBtnText: this.paymentRequest.dialogs.cvv_message.button
      }
    });
  }

  confirmBonusBines(res, cardNumber) {

  }

  consumeBonusBines() {
    if (!this.paymentRequest.data.client_token) {
      return
    }

    let auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    let cardNum = this.cardInput.nativeElement.value.replace(/\D+/g, '');
    const cardBin = cardNum.substring(0, 6);

    if (cardNum.length >= 6 && !this.paymentRequest.data.plan && this.cardBin != cardBin) {

      if (this.cardBin != cardBin) {
        this.cardBin = cardBin;
        if (this.debit) {
          this.service.cardBinDebit.emit(this.cardBin);
        } else {
          this.service.cardBin.emit(this.cardBin);
        }
        this.loadingScreenService.startLoading();
        const request = {
          card_number: this.encryptService.encryptStringData(cardNum),
          token_external: this.paymentRequest.data.client_token,
          reference: this.paymentRequest.data.reference
        };

        this.paymentTransactionService.consumeBonusBines(request, auth.token).subscribe(
          res => {
            this.loadingScreenService.stopLoading();
            if (res.has_discount) {
              this.paymentRequest.data.amount = res.amount;
              this.paymentRequest.aplly_bonus = true;
              this.paymentRequest.card_number = request.card_number;
              this.paymentRequest.voucher_name = res.voucher_name;
              this.service.activateChip.emit(true);
              this.epaycoApify.activateChip.emit(true);
              this.paymentTransactionService.displayBonusSnack(res.dialog.body);
            } else {
              this.service.activateChip.emit(false);
              this.epaycoApify.activateChip.emit(false);
              this.paymentRequest.data.amount = this.paymentRequest.aux_init_amount;
              this.service.changeAmountInitial.emit(this.paymentRequest.aux_init_amount);
              this.epaycoApify.changeAmountInitial.emit(this.paymentRequest.aux_init_amount);
            }
          },
          error => {
            console.log(error);
          }
        );
      }

    } else if (cardNum.length <= 5 && !this.paymentRequest.data.plan) {
      this.cardBin = cardBin;
      this.service.activateChip.emit(false);
      this.epaycoApify.activateChip.emit(false);
      if (this.debit) {
        this.service.cardBinDebit.emit(this.cardBin);
      } else {
        this.service.cardBin.emit(this.cardBin);
      }
      this.service.changeAmountInitial.emit(this.paymentRequest.aux_init_amount);
    }
  }


  validateTokenizedCard() {
    let cardNum = this.cardInput.nativeElement.value.replace(/\D+/g, '');
    if (cardNum.length >= 15 && this.savedCards && this.savedCards.length > 0) {
      const cardBin = cardNum.substring(0, 6);
      const cardEnd = cardNum.substr(cardNum.length - 4);
      try {
        let mask = this.encryptService.encryptStringData(cardEnd);
        this.savedCards.forEach(card => {
          const cardBinSaved = card.mask.substring(0, 6);
          const cardEndSaved = card.mask.substr(card.mask.length - 4);

          if (cardBin === cardBinSaved && cardEnd === cardEndSaved && mask === card.card_mask) {
            this.useSavedCard = true;
            this.savedCardPay = card;
          }
        });
      } catch (error) {
        console.log('Error no se pudo enmascarar la tarjeta');
      }

    }

  }

  public addInfoDataLayerPay() {
    nameCategory = this.paymentRequest.data.client
    description = this.paymentRequest.data.description

    window.dataLayer.push({
      eventCategory: nameCategory + " - Intención de pago",
      eventAction: 'Pago con ' + (this.debit ? "Tarjeta de crédito" : "Tarjeta de débito"),
      eventLabel: description,
      eventValue: '',
      event: 'eventClick'
    });
  }

  public validateSaveCard() {
    if (this.doc_number_holder == this.paymentRequest.data.id &&
      this.doc_type_holder == this.paymentRequest.data.id_type) {
      this.validate_card_holder = false;
    } else {
      this.validate_card_holder = true;
    }
  }

  public tokenizedCard() {
    const request = {
      email: this.card_email,
      number: this.card_number,
      cvc: this.cvc,
      month: this.exp_month,
      year: this.exp_year
    }
  }


  addToDataLayerCheckoutEvent(method: string, paymentRequest) {
    this.datalayerService.addToDataLayerCheckoutEvent(method, paymentRequest);
  }

  openVerifyCardDialog(save_card: boolean) {

    return new Promise<any>((resolve, reject) => {
      if (this.paymentRequest.data.plan || this.paymentRequest.tokenizeCard || save_card) {
        let verifyCard = this.verifyCardDialog.open(VerifyCardDialogComponent, {
          minWidth: '350px',
          maxWidth: '400px',
        })

        verifyCard.afterClosed().subscribe(() => {
          resolve(true);
        })
      } else {
        resolve(true);
      }
    })
  }

  getInfoRecaptcha() {
    const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    this.recaptchaV3Service.execute('')
      .subscribe((token) => {
          const auxiliar = this.httpService.getTokenClientModule(
            token,
            auth.token,
            this.paymentRequest.data.reference
          )
          auxiliar.subscribe({
            error: () => {
              //TODO tomar acciones
            },
            next: (resultado) => {
              if (resultado.score < 1) {
                //TODO tomar acciones
              }
            }
          });
        }
      );
  }

}
