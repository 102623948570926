import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { CreditCardPaymentezUtility } from 'src/app/core/utilities/credit-card-paymentez.utility';

@Component({
  selector: 'app-purchase-payment-mix',
  templateUrl: './purchase-payment-mix.component.html',
  styleUrls: ['./purchase-payment-mix.component.scss']
})
export class PurchasePaymentMixComponent implements OnInit {

  paymentRequest: PaymentConfigModel;
  response: any;
  status;
  responseMixedPurchase;
  url_retry;
  pending_value;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private sessionStorageService: SessionStorageService) {
    this.paymentRequest = this.data.paymentRequest;
    this.response = this.data.res;
  }



  ngOnInit(): void {
    this.status = CreditCardPaymentezUtility.getValueResult(this.response.transaction.status);
    this.url_retry = this.sessionStorageService.getItem(SessionStorageService.URL_PAYMENT_REQUEST);


    if(this.status == "aprobada" && this.response.transaction.mixed_purchase && this.response.transaction.mixed_purchase.pending_value !=0){
      this.status = "ABONADO"
    }
  }

  retryPayment(){
    window.location.href = this.url_retry + "&reload=" + + new Date().getTime();
  }

}
