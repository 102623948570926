import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CurrencyPipe, NgForOf, NgIf } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { NuveiBankTransferService } from './nuvei-bank-transfer.service';
import { typeDocument, BankTransferTransactionRequest } from './models';
import { ExpansionPanelComponent } from '@payment-app/components/payment-methods/expansion-panel/expansion-panel.component';
import { PaymentRequestService } from '../../../../../payment/payment-request/payment-request.service';
import { FormPayment, PaymentMethods } from '../../../../core/models';
import { BankTransactionFormComponent } from '@payment-app/components/payment-methods/bank-transaction-form/bank-transaction-form.component';
import { BankTransactionForm } from '@payment-app/components/payment-methods/bank-transaction-form/bank-transaction-form.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'nuvei-bank-transfer',
  templateUrl: './nuvei-bank-transfer.component.html',
  styleUrls: ['./nuvei-bank-transfer.component.scss'],
  standalone: true,
  imports: [
    CurrencyPipe,
    NgForOf,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    ExpansionPanelComponent,
    BankTransactionFormComponent,
    NgIf,
  ],
})
export class NuveiBankTransferComponent implements OnInit, OnDestroy {
  readonly #paymentRequestService = inject(PaymentRequestService);
  readonly #service = inject(NuveiBankTransferService);
  #subscription: Subscription;
  paymentRequest = this.#paymentRequestService.paymentRequest;
  protected bankTransferFormGroup!: FormGroup;
  protected readonly paymentMethod = PaymentMethods.get(
    FormPayment.BANK_TRANSFER,
  );
  protected readonly typeDocument = typeDocument;
  protected nuveiBankTransactionFormGroup: FormGroup<BankTransactionForm>;

  // TODO: only for testing
  readonly #activatedRoute = inject(ActivatedRoute);
  isTesting = false;
  errorMessage!: string | null;

  ngOnInit(): void {
    this.#paymentRequestService.paymentRequest$.subscribe((paymentRequest) => {
      this.paymentRequest = paymentRequest;
    });

    this.isTesting = this.#activatedRoute.snapshot.queryParams.test === 'true';

    if (!this.isTesting) {
      this.createForm();
    } else {
      this.initNuveiBankTransactionForm();
    }
  }

  ngOnDestroy(): void {
    this.#subscription?.unsubscribe();
  }

  createBankTransferTransaction() {
    if (!this.bankTransferFormGroup.invalid) {
      this.#subscription = this.#service
        .createBankTransferTransaction(this.bankTransferRequest)
        .subscribe({
          next: (response) => {
            window.location.href = response.transaction.bank_url;
          },
          error: (error) => {
            this.errorMessage = error.error.error.error.description + ' ' + error.error.error.error.type;
            setTimeout(() => {
              this.errorMessage = null;
            }, 3000);
          },
        });
    }
  }

  get bankTransferRequest() {
    const urlReturnPSE = `${document.location.origin}/pagos/volver-al-comercio/pse-nuvei/${this.paymentRequest.data.reference}`;

    const id = uuid();

    return {
      carrier: {
        id: 'PSE',
        extra_params: {
          bank_code: this.bankTransferFormGroup.get('bank').value,
          response_url: urlReturnPSE,
          user: {
            name:
              this.paymentRequest.data.name +
              ' ' +
              this.paymentRequest.data.last_name,
            fiscal_number: this.paymentRequest.data.id,
            type: this.paymentRequest.data.type_user,
            type_fis_number: this.paymentRequest.data.id_type,
            ip_address: this.paymentRequest.data.ip,
          },
        },
      },
      user: {
        id,
        email: this.paymentRequest.data.email,
      },
      order: {
        dev_reference: this.paymentRequest.data.reference,
        amount: this.paymentRequest.data.amount,
        bonus: this.paymentRequest.aplly_bonus
          ? this.paymentRequest.aplly_bonus
          : null,
        manual_bonus: this.paymentRequest.manual_bonus
          ? this.paymentRequest.manual_bonus
          : null,
        vat: this.paymentRequest.data.vat,
        description: this.paymentRequest.data.description,
      },
      dataRetentions: this.paymentRequest.dataRetentions,
    } as unknown as BankTransferTransactionRequest;
  }

  private createForm() {
    this.bankTransferFormGroup = new FormGroup({
      documentType: new FormControl(this.paymentRequest.data.id_type, [
        Validators.required,
      ]),
      documentNumber: new FormControl(this.paymentRequest.data.id, [
        Validators.minLength(6),
        Validators.maxLength(20),
        Validators.required,
      ]),
      personType: new FormControl(this.paymentRequest.data.type_user, [
        Validators.required,
      ]),
      bank: new FormControl('', [Validators.required]),
    });
  }

  private initNuveiBankTransactionForm() {
    this.nuveiBankTransactionFormGroup = new FormGroup<BankTransactionForm>({
      idType: new FormControl<string>(''),
      idValue: new FormControl<string>(''),
      bank: new FormControl<string>(''),
      customerType: new FormControl<string>(''),
    });
  }
}
