import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { AuthService } from "../../core/services/auth.service";
import { SessionStorageService } from "../../core/services/session-storage.service";
import { PostMessageService } from "../../core/services/post-message.service";
import { ConfigurationService } from "../../core/services/configuration.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { url_bolivar } from "src/app/core/utilities/enviroment-consts";
import { CookieGatewayService } from "../../core/services/cookie.service";

interface RequestPayModel {
  name;
  last_name;
  email;
  phone;
  type_user;
  id_type;
  id;
  reference;
  description;
  vat;
  amount;
  url_return;
  url_return_problem;
}
@Component({
  selector: "app-payment-load",
  templateUrl: "./payment-load.component.html",
  styleUrls: ["./payment-load.component.scss"],
})
export class PaymentLoadComponent implements OnInit {
  private urlReturnProblem = url_bolivar;
  private googleTagManager: string;
  styleUrl: SafeResourceUrl;
  loadCss = false;
  utm_medium: string;
  utm_source: string;
  utm_campaign: string;

  constructor(
    private sanitizer: DomSanitizer,
    protected _router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private _authService: AuthService,
    private sessionStorageService: SessionStorageService,
    private configurationService: ConfigurationService,
    private cookieGatewayService: CookieGatewayService,
    private postMessageService: PostMessageService
  ) {}

  ngOnInit() {
    this.sessionStorageService.setItem(
      SessionStorageService.PAYMENT_URL,
      window.location.href
    );
    this.sessionStorageService.setItem(
      SessionStorageService.URL_RETURN_PROBLEM,
      this.urlReturnProblem
    );
    this.sessionStorageService.setItem(
      SessionStorageService.URL_PAYMENT_REQUEST,
      window.location.href
    );
    // quitar este subcribe por que solo se utiliza para recuperar
    // el dominio pero ya se esta recuperando del path url
    this.activatedRoute.params.subscribe((params) => {
      // cuando se hagan las configuracion del proxy pass entonces quitar este if
      // y recuperar el dominio del parthurl
      const dominio = document.location.origin;
      if (dominio !== undefined) {
        this.sessionStorageService.setItem(
          SessionStorageService.DOMAIN,
          dominio
        );

        const info = this.activatedRoute.snapshot.queryParamMap.get("q");
        const tokenPm = this.activatedRoute.snapshot.queryParamMap.get("token_pm");

        if (typeof info != "string" || !info) {
          this._router.navigate(["/pagos/mensaje"], {
            state: {
              message: "La solicitud que intenta realizar no es valida",
            },
          });
          return true;
        }

        if (tokenPm) {
          this.sessionStorageService.setItem(
            SessionStorageService.TOKEN_PM,
            tokenPm
          );
          this.postMessageService.send();
        }


        this._authService.login(dominio).subscribe(
          (res) => {
            this.styleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              res["url_style"]
            );
            this.loadCss = true;

            this.sessionStorageService.setItem(SessionStorageService.AUTH, res);

            if (!res.token) {
              this._router.navigate(["/pagos/mensaje"], {
                state: { message: res["msg"] },
              });
              return false;
            }
            let gaCookies = null;

            if (
              this.cookieGatewayService.getCookie(
                CookieGatewayService.GA_COOKIE
              ) ||
              this.cookieGatewayService.getCookie(
                CookieGatewayService.GID_COOKIE
              )
            ) {
              gaCookies = {
                ga_cookie: this.cookieGatewayService.getCookie(
                  CookieGatewayService.GA_COOKIE
                ),
                gid_cookie: this.cookieGatewayService.getCookie(
                  CookieGatewayService.GID_COOKIE
                ),
              };
            }
            this.configurationService
              .getConfiguration(
                { url: dominio, gaCookies, info },
                "Bearer " + res.token
              )
              .subscribe(
                (res) => {
                  if (res["error"]) {
                    this.urlReturnProblem = res["url_return_problem"]
                      ? res["url_return_problem"]
                      : this.urlReturnProblem;
                    this.sessionStorageService.setItem(
                      SessionStorageService.URL_RETURN_PROBLEM,
                      this.urlReturnProblem
                    );
                    this._router.navigate(["/pagos/mensaje"], {
                      state: {
                        message:
                          res["error"] +
                          ". Por favor intente nuevamente con otra referencia.",
                      },
                    });
                    return;
                  }

                  if (this.validarSolicitud(res.data)) {
                    // se utiliza este pare para evitar que continue evaluando
                    return;
                  }

                  this.sessionStorageService.setItem(
                    SessionStorageService.PLAIN_TRX_ID,
                    res.data.data.reference
                  );

                  // se valida si el usuario tiene el campo de clarityId
                  if (res.data.clarityId) {
                    this.loadClarityScript(res.data.clarityId);
                  }

                  if (res.data.data.email) {
                    this.cookieGatewayService.setCookie(
                      CookieGatewayService.USER_EMAIL,
                      res.data.data.email
                    );
                  }

                  if (res.data.data.url_return_problem) {
                    this.urlReturnProblem = res.data.data.url_return_problem;
                    this.sessionStorageService.setItem(
                      SessionStorageService.URL_RETURN_PROBLEM,
                      this.urlReturnProblem
                    );
                  }

                  if (res.data.gtm) {
                    this.googleTagManager = res.data.gtm;
                    this.sessionStorageService.setItem(
                      SessionStorageService.GOOGLE_TAG_MANAGER,
                      this.googleTagManager
                    );
                  }

                  if (res.data.gtm_davivienda && tokenPm) {
                    this.googleTagManager = res.data.gtm_davivienda;
                    this.sessionStorageService.setItem(
                      SessionStorageService.GOOGLE_TAG_MANAGER,
                      this.googleTagManager
                    );
                  }

                  this.sessionStorageService.setItem(
                    SessionStorageService.ORDEN,
                    res.data.data
                  );
                  if (this.clearPhone(res.data.data)) {
                    let phoneNumber = res.data.data.phone;
                    let newPhoneNumber;
                    let number;
                    number = parseInt(phoneNumber);
                    newPhoneNumber = phoneNumber.substr(2, number.maxLength);
                    res.data.data.phone = newPhoneNumber;
                  }
                  res.data.payment_reference_status = res.payment_reference;
                  this.setUtmVariables(res);
                  this._router.navigate(["/pagos/procesa"], {
                    queryParams: {
                      utm_source: this.utm_source,
                      utm_medium: this.utm_medium,
                      utm_campaign: this.utm_campaign,
                    },
                    state: { obj: res.data },
                  });
                },
                (err) => {
                  this._router.navigate(["/pagos/mensaje"], {
                    state: { message: err },
                  });
                }
              );
          },
          (err) => {
            this._router.navigate(["/pagos/mensaje"], {
              state: { message: err },
            });
          }
        );
      } else {
        this._router.navigate(["/pagos/mensaje"], {
          state: { message: "Esta solicitud no puede ser resuelta." },
        });
      }
    });
  }

  isValidEmail(email) {
    const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return re.test(email);
  }

  isValidText(str) {
    const re = /^([a-zA-Z0-9\xC0-\uFFFF \-\'\.\&]{1,60}){1,10}$/;
    return re.test(str);
  }

  isValidName(str) {
    const re =
      /^([a-zA-ZÀ-ú0-9!@#$%^&*(),_. \'-\xC0-\uFFFF \-\'\.\&]{1,60}){1,10}$/;
    return re.test(str);
  }

  isValidPhoneNumber(phone) {
    const re = /^([0-9]{7,20})$/;
    return re.test(phone);
  }

  isValidAmount(amount) {
    const re = /^[-+]?[0-9]*\.?[0-9]*$/;
    return re.test(amount);
  }

  isBase64(str) {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  validarSolicitud(data: any) {
    try {
      //const solicitud: RequestPayModel = JSON.parse(decodeURIComponent(escape(window.atob(data))));

      const solicitud = data.data;

      if (solicitud.url_return_problem) {
        this.sessionStorageService.setItem(
          SessionStorageService.URL_RETURN_PROBLEM,
          solicitud.url_return_problem
        );
      }

      if (this.invalidName(solicitud)) {
        return true;
      }

      if (this.invalidEmail(solicitud)) {
        return true;
      }

      if (this.invalidPhone(solicitud)) {
        return true;
      }

      if (this.invalidAmount(solicitud)) {
        return true;
      }

      if (this.invalidReference(solicitud)) {
        return true;
      }

      if (this.invalidDescription(solicitud)) {
        return true;
      }

      if (this.invalidCustomerId(solicitud)) {
        return true;
      }
    } catch (error) {
      this._router.navigate(["/pagos/mensaje"], {
        state: {
          message: "Lo sentimos esta no parece ser una solicitud valida",
        },
      });
      return true;
    }

    return false;
  }

  private notValidData(data) {
    if (data === null || data === undefined) {
      this._router.navigate(["/pagos/mensaje"], {
        state: {
          message:
            "Lo sentimos se ha presentado un error con esta solicitud. Por favor intente nuevamente.",
        },
      });
      return true;
    }

    return false;
  }

  private invalidName(solicitud) {
    let validate = !solicitud.name;

    if (solicitud.name) {
      solicitud.name = solicitud.name.replace(/\s\s+/g, " ");
      validate = !this.isValidName(solicitud.name);
    }

    validate = !validate
      ? !solicitud.last_name &&
        solicitud.id_type != "NIT" &&
        solicitud.type_user == "N"
      : validate;

    if (
      solicitud.last_name &&
      solicitud.id_type != "NIT" &&
      solicitud.type_user == "N"
    ) {
      solicitud.last_name = solicitud.last_name.replace(/\s\s+/g, " ");
      validate = !validate ? !this.isValidText(solicitud.last_name) : validate;
    }

    if (validate) {
      this._router.navigate(["/pagos/mensaje"], {
        state: {
          message:
            "Lo sentimos se ha presentado un error con esta solicitud. Por favor intente nuevamente.",
        },
      });
      return true;
    }
    return false;
  }

  private invalidEmail(solicitud) {
    if (
      !solicitud.email ||
      !this.isValidEmail(solicitud.email) ||
      solicitud.email === ""
    ) {
      this._router.navigate(["/pagos/mensaje"], {
        state: { message: "Lo sentimos, debe suministrar un email valido" },
      });
      return true;
    }
    return false;
  }

  private invalidPhone(solicitud) {
    if (
      !solicitud.phone ||
      !this.isValidPhoneNumber(solicitud.phone) ||
      solicitud.phone === ""
    ) {
      this._router.navigate(["/pagos/mensaje"], {
        state: { message: "Lo sentimos, debe suministrar un telefono valido" },
      });
      return true;
    }
    return false;
  }

  private invalidAmount(solicitud) {
    if (
      !solicitud.amount ||
      !this.isValidAmount(solicitud.amount) ||
      solicitud.amount === ""
    ) {
      this._router.navigate(["/pagos/mensaje"], {
        state: {
          message:
            "Lo sentimos, debe suministrar un monto valido para la transacción",
        },
      });
      return true;
    }
    return false;
  }

  private invalidReference(solicitud) {
    if (!solicitud.reference || solicitud.reference === "") {
      this._router.navigate(["/pagos/mensaje"], {
        state: {
          message: "Lo sentimos, no puede realizar un pago sin referencia",
        },
      });
      return true;
    }
    return false;
  }

  private invalidDescription(solicitud) {
    if (!solicitud.description) {
      this._router.navigate(["/pagos/mensaje"], {
        state: {
          message: "Lo sentimos, debe suministrar el servicio que desea pagar",
        },
      });
      return true;
    }
    return false;
  }

  private invalidCustomerId(solicitud) {
    if (!solicitud.id) {
      this._router.navigate(["/pagos/mensaje"], {
        state: {
          message:
            "Lo sentimos, debe cargar el documento de identidad para la transacción",
        },
      });
      return true;
    }
    return false;
  }

  private clearPhone(data) {
    let phoneNumber = data.phone;
    let digits = phoneNumber.substr(0, 2);
    let newPhoneNumber;
    if (phoneNumber.length > 10 && digits == 57) {
      return true;
    }
    return false;
  }

  private setUtmVariables(infoTransaction) {
    if (
      infoTransaction.data.is_paylink ||
      infoTransaction.data.data.subclient == "JELPER-0005" ||
      infoTransaction.data.data.subclient == "JELPER-0006"
    ) {
      this.utm_campaign = "Pago_por_link";
      this.utm_source = "Link";
      this.utm_medium = "Shopper";
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadClarityScript(clarityId: string) {
    ((c, l, a, r, i, t, y) => {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = `https://www.clarity.ms/tag/${i}`;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, this.document, 'clarity', 'script', clarityId);
  }
}
