import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { PaymentezResponseStatusTransferModel } from "../../../core/models/paymentez/paymentez-response-status-transfer.model";
import { PaymentConfigModel } from "../../../core/models/payment-config.model";
import { ActivatedRoute, Router } from "@angular/router";
import { CreditCardPaymentezUtility } from "../../../core/utilities/credit-card-paymentez.utility";
import { SessionStorageService } from "../../../core/services/session-storage.service";
import { CurrencyPipe } from '@angular/common';
import { AuthModel } from 'src/app/core/models/auth.model';
import { AuditService } from 'src/app/core/services/audit.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';


@Component({
  selector: 'app-purchase-checkout',
  templateUrl: './purchase-checkout.component.html',
  styleUrls: ['./purchase-checkout.component.scss'],
  providers: [CurrencyPipe]
})
export class PurchaseCheckoutComponent implements OnInit {

  @Input() response: PaymentezResponseStatusTransferModel;

  @Input() paymentRequest: PaymentConfigModel;

  @Output() imprimir: EventEmitter<any>;
  status = '';
  url_return = '';
  url_retry = '';
  authToken;
  logo: string;
  puntosColombia: boolean = false;
  nPuntosColombia: number = 0;
  statusPayment: boolean = false;
  registerPuntosColombia: boolean = false;
  puntosColombiaPromotion: string;
  hasPaymentReattempt: boolean;
  

  constructor(
    protected _router: Router,
    private activatedRoute: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private auditService: AuditService,
    private datalayerService: DatalayerService,
    private sessionStorageService: SessionStorageService
  ) {
    this.imprimir = new EventEmitter();
  }

  ngOnInit() {
    const auth = this.sessionStorageService.getItem(SessionStorageService.ORDEN);
    this.hasPaymentReattempt = this.paymentRequest.payment_reattempt;
    this.authToken = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    this.url_return = auth['url_return'];
    this.url_retry = this.sessionStorageService.getItem(SessionStorageService.URL_PAYMENT_REQUEST);
    if (this.paymentRequest.name === 'doctoraki') {
      this.datalayerService.addInfoDataLayerPurchaseResumeConfirmation(this.response);
    }
    
  }

  ngOnChanges() {
    //Puntos colombia
    if (this.paymentRequest.has_puntoscolombia) {
      if (this.response && this.response.transaction && this.response.transaction.puntos_colombia) {
        if (this.response.transaction.puntos_colombia.success && this.response.transaction.puntos_colombia.user_exist) {
          this.puntosColombia = true;
          this.nPuntosColombia = this.response.transaction.puntos_colombia.body.pointsEarned;
          this.puntosColombiaPromotion = this.paymentRequest.colombia_points_promotion;
        } else {
          this.registerPuntosColombia = true;
        }
      }
    }

    if (this.response && (this.response.transaction.status === 'APROBADO' || this.response.transaction.status === 'success')) {
      this.statusPayment = true;
    }
  }


  returnClient() {
    this.datalayerService.addInfoDataLayerPurchaseResume('Volver a comercio', this.paymentRequest.name, null, this.paymentRequest.name == 'doctoraki'? this.response.transaction.status : null)

    const request = {
      dev_reference: this.paymentRequest.data.reference,
      client: this.paymentRequest.client,
      subclient: this.paymentRequest.data.subclient,
      leave_type: "Salida por Boton",
      payment_status: this.response.transaction.status,
      leave_page: true
    }

    this.auditService.saveAudit(request, this.authToken.token).subscribe(res => {
      window.location.href = this.url_return;
    });

  }

  retryPayment() {
    this.datalayerService.addInfoDataLayerPurchaseResume("Reintentar el pago", this.paymentRequest.name, null, this.paymentRequest.name == 'doctoraki'? this.response.transaction.status : null);
    window.location.href = this.url_retry + "&reload=" + + new Date().getTime();
  }

  printPdf() {
    this.imprimir.emit(true);
  }



}
