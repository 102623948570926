import { Component, Input, OnInit } from '@angular/core';
import { PaymentConfigModel } from '../../../core/models/payment-config.model';
import {   DataResponse } from '../../../gateway/paymentez/paymentez-dialog/pay-claro-pay/pay-claro-pay.interface';
import {SessionStorageService} from '../../../core/services/session-storage.service';
import {AuthModel} from '../../../core/models/auth.model';
import {AuditService} from '../../../core/services/audit.service';

@Component({
  selector: 'app-purchase-claro-pay',
  templateUrl: './purchase-claro-pay.component.html',
  styleUrls: ['./purchase-claro-pay.component.scss']
})

export class PurchaseClaroPayComponent implements OnInit {
  @Input() claroPayInfo: DataResponse;
  @Input() paymentRequest: PaymentConfigModel;
  urlReturn: string;
  authToken;

  listStates = {
    'delivered': 'APROBADO',
    'paid': 'APROBADO',
    'failed': 'RECHAZADO',
    'expired': 'RECHAZADO',
    'created': "PENDIENTE"
  };
  constructor(
    private sessionStorageService: SessionStorageService,
    private auditService: AuditService
  ) { }

  ngOnInit(): void {
    const auth = this.paymentRequest.data;
    this.authToken = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    this.urlReturn = auth.url_return;
  }

  returnClient() {
    const request = {
      dev_reference: this.paymentRequest.data.reference,
      client: this.paymentRequest.data.client,
      subclient: this.paymentRequest.data.subclient,
      leave_type: "Salida por Boton",
      payment_status: this.claroPayInfo.status,
      leave_page: true
    }
    this.auditService.saveAudit(request, this.authToken.token).subscribe(res => {
      window.location.href = this.urlReturn;
    });
  }
  retryPayment() {
    window.location.href = window.location + '/paylink?q=' + this.paymentRequest.data.pay_link;
  }
}
