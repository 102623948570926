import { Component, OnInit, Input, Output,EventEmitter, ViewChild } from '@angular/core';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { EpaycoService } from 'src/app/core/services/epayco.service';
import { LoginModel } from 'src/app/core/models/login.model';
import { LoadingScreenService } from 'src/app/core/services/loading-screen.service';
import { PaymentMethodService } from 'src/app/core/services/payment-method.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { EncryptService } from 'src/app/core/services/encrypt.service';
import { PaymentTransactionService } from 'src/app/core/services/payment-transaction.service';
import {MatAccordion} from '@angular/material/expansion';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { ChangeStatusService } from 'src/app/core/services/change-status.service';
import { AuthModel } from 'src/app/core/models/auth.model';

@Component({
  selector: 'app-saved-card',
  templateUrl: './saved-card.component.html',
  styleUrls: ['./saved-card.component.scss']
})
export class SavedCardComponent implements OnInit {

  @Input() paymentRequest: PaymentConfigModel;
  @Input() card: any;
  @Input() id: any;
  @Input() selectedId: number;
  @Output() emisor = new EventEmitter<number>();
  @Output() reloadCards = new EventEmitter<any>();
  @ViewChild('cards_tokenize') accordion: MatAccordion;
  confirm: MatDialogRef<ConfirmDialogComponent> = null;
  private auth;
  public numbers;
  public quota = 1;
  // public doc_type_holder;
  // public doc_number_holder;
  public exist_token_card = true;
  public message: any = {};
  private service = null;

  constructor(
    public dialog: MatDialog,
    private sessionStorageService: SessionStorageService,
    private epaycoService: EpaycoService,
    private loadingScreenService: LoadingScreenService,
    private paymentMethodService: PaymentMethodService,
    private encryptService : EncryptService,
    private paymentTransactionService: PaymentTransactionService,
    private datalayerService: DatalayerService,
    private changeStatusService: ChangeStatusService
  ) {
    this.numbers = Array(36).fill(1).map((x, i) => (i + 1));
    this.auth = this.sessionStorageService.getItem<LoginModel>(SessionStorageService.AUTH);
  }

  ngOnInit(): void {
    this.service = this.paymentMethodService.gateway(this.paymentRequest.gateway_active);
    this.service.message.subscribe(message => {
      this.message = message;
      this.loadingScreenService.stopLoading();
      setTimeout(() => {
        this.message = {};
      }, 5000);
    });
    this.epaycoService.acordionCardsTokenize.subscribe(status =>{
      this.toggleAccordion();
    });
  }

  async transactionExistingToken(tokenCard) {
    //se actualiza a transacción intentada
    const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    const body = {
      dev_reference: this.paymentRequest.data.reference ? this.paymentRequest.data.reference : null
    }
    await this.changeStatusService.changeStatus(auth, this.paymentRequest,"CARD", body);

    //datalayer boton de pago
    let amountDatalayer = this.paymentRequest.data.amount;
    let textButton = `PAGAR ${amountDatalayer}`;
    this.datalayerService.AddInfoDataLayerPaymentButton(textButton);

    this.message = {};
    const token = 'Bearer ' + this.auth.token;
    const request = {
      dues: this.quota,
      exist_token_card: this.exist_token_card,
      // doc_type_holder: this.doc_type_holder ? this.doc_type_holder : null,
      // doc_number_holder: this.doc_number_holder ? this.doc_number_holder : null,
    };

    if (!this.quota && (!this.paymentRequest.data.plan || (this.paymentRequest.data.plan && this.paymentRequest.data.pay_link))){
      console.log('por favor elige el número de cuotas');
      this.message.origin = 'TOKENCARD';
      this.message.message = 'por favor elige el número de cuotas';
      return false;
    }

    this.loadingScreenService.startLoading();
    let token_card = await this.epaycoService.sendValidMask(this.paymentRequest, this.card.mask)
    tokenCard = token_card != null ? token_card : tokenCard;
    this.epaycoService.transactionTokenizedCard(token, request, this.paymentRequest, tokenCard);

  }

  selectCard() {
    this.emisor.emit(this.id);
    this.epaycoService.savedCardExpanded.emit(true);
    if (this.paymentRequest.data.client_token) {
      this.consumeBonusBines(this.card.mask);
    }
  }

  closedCard() {
    this.service.activateChip.emit(false);
  }

  deleteCard() {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      position: {
        top: '5%'
      },
      data: {
        titulo: '¿Seguro que desea eliminar esta tarjeta?',
        mensaje: '',
      }
    });

    const dialogSubmitSubscription = dialogRef.componentInstance.onConfirm
      .subscribe(result => {
        this.loadingScreenService.startLoading();
        const token = this.auth.token;

        const request = {
          id: this.card.id,
          mask: this.card.card_mask,
          client: this.paymentRequest.name
        };

        this.epaycoService.forgetCustomerCard( request , token).subscribe(
          success => {
            this.reloadCards.emit(true);
            this.loadingScreenService.stopLoading();
          },
          failure => {
            console.log(failure);
            this.loadingScreenService.stopLoading();
          }
        );
        dialogRef.close();
        dialogSubmitSubscription.unsubscribe();
      });
  }

  consumeBonusBines(cardNum) {
    let auth = this.sessionStorageService.getItem<LoginModel>(SessionStorageService.AUTH);
    this.loadingScreenService.startLoading();
    let request = {
      card_number: this.encryptService.encryptStringData(cardNum),
      token_external: this.paymentRequest.data.client_token,
      reference : this.paymentRequest.data.reference
    };

    this.paymentTransactionService.consumeBonusBines(request, auth.token).subscribe(
      res => {
        this.loadingScreenService.stopLoading();
        if (res.has_discount) {
          this.paymentRequest.data.amount = res.amount;
          this.paymentRequest.aplly_bonus = true;
          this.paymentRequest.card_number = request.card_number;
          this.paymentRequest.voucher_name = res.voucher_name;
          this.service.activateChip.emit(true);

          this.paymentTransactionService.displayBonusSnack(res.dialog.body);
        } else {
          this.service.activateChip.emit(false);
          this.service.changeAmountInitial.emit(this.paymentRequest.aux_init_amount);
        }
      },
      error => {
        console.log(error);
      }
    );
  }


  toggleAccordion() {
    this.accordion.multi = true;
    this.accordion.closeAll();
    this.accordion.multi = false;
  }

  addToDataLayerCheckoutEvent(method: string, paymentRequest){
    this.datalayerService.addToDataLayerCheckoutEvent(method, paymentRequest);
  }

}
