import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { PaylinkService } from 'src/app/core/services/paylink.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { url_bolivar } from 'src/app/core/utilities/enviroment-consts';

@Component({
  selector: 'app-payment-redirect',
  templateUrl: './payment-redirect.component.html',
  styleUrls: ['./payment-redirect.component.scss']
})
export class PaymentRedirectComponent implements OnInit {
  private urlReturnProblem = url_bolivar;
  private googleTagManager: string;

  constructor(
    protected _router: Router,
    private activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private sessionStorageService: SessionStorageService,
    private configurationService: ConfigurationService,
    private paylinkService: PaylinkService,
  ) { }

  ngOnInit(): void {
    this.sessionStorageService.setItem(SessionStorageService.PAYMENT_URL, window.location.href)
    this.sessionStorageService.setItem(SessionStorageService.URL_RETURN_PROBLEM, this.urlReturnProblem);
    this.sessionStorageService.setItem(SessionStorageService.URL_PAYMENT_REQUEST, window.location.href);


    this.activatedRoute.params.subscribe(params => {

      // cuando se hagan las configuracion del proxy pass entonces quitar este if
      // y recuperar el dominio del parthurl
      const dominio = document.location.origin;
      if (dominio !== undefined) {

        this.sessionStorageService.setItem(SessionStorageService.DOMAIN, dominio);

        const info = this.activatedRoute.snapshot.queryParamMap.get('q');

        if (typeof (info) != 'string' || !info) {
          this._router.navigate(['/pagos/mensaje'], { state: { message: 'La solicitud que intenta realizar no es valida' } });
          return true;
        }

        this._authService.login(dominio).subscribe(
          res => {

            this.sessionStorageService.setItem(SessionStorageService.AUTH, res);

            if (!res.token) {
              this._router.navigate(['/pagos/mensaje'], { state: { message: res['msg'] } });
              return false;
            }

            this.paylinkService.redirectPayLink(info, 'Bearer ' + res.token).subscribe(response => {
              if (response.error) {
                this._router.navigate(['/pagos/mensaje'], { state: { message: 'La solicitud que intenta realizar no es valida' } });
              } else {
                window.location.href = response.pay_link;
              }
            },
              err => {
                this._router.navigate(['/pagos/mensaje'], { state: { message: err } });
              });

          },
          err => {
            this._router.navigate(['/pagos/mensaje'], { state: { message: err } });
          }
        );

      } else {

        this._router.navigate(['/pagos/mensaje'], { state: { message: 'Esta solicitud no puede ser resuelta.' } });

      }
    });

  }

}
