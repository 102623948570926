import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


import {
  BankTransferTransactionRequest,
  BankTransferTransactionResponse,
} from './models';
import { PaymentGatewayResponse } from '../../../../core/interfaces/payment-gateway-response.interface';

@Injectable({
  providedIn: 'root',
})
export class NuveiBankTransferService {
  #http = inject(HttpClient);

  getData(reference: string): Observable<PaymentGatewayResponse> {
    return this.#http.get<any>(
      `payments/nuvei-status-transaction/pse/${reference}`,
    );
  }

  createBankTransferTransaction(body: BankTransferTransactionRequest) {
    return this.#http.post<BankTransferTransactionResponse>(
      'payments/nuvei/order',
      body,
    );
  }
}
