import {Component, Input, OnInit} from '@angular/core';
import {PaymentConfigModel} from "../../core/models/payment-config.model";
import {Router} from "@angular/router";
import {PaymentezResponseCheckoutModel} from "../../core/models/paymentez/paymentez-response-checkout.model";

declare var PaymentezCheckout: any;

@Component({
  selector: 'gateway-paymentez-checkout',
  templateUrl: './paymentez-checkout.component.html',
  styleUrls: ['./paymentez-checkout.component.scss']
})
export class PaymentezCheckoutComponent implements OnInit {

  @Input() paymentRequest: PaymentConfigModel;

  paymentezCheckout: any;

  constructor(
    protected _router: Router,
  ) { }

  ngOnInit() {
    this.paymentezCheckout = new PaymentezCheckout.modal({
      client_app_code: this.paymentRequest.paymentes_credentials.code_app_client, // Client Credentials Provied by Paymentez
      client_app_key: this.paymentRequest.server_app_client, // Client Credentials Provied by Paymentez
      locale: 'es', // User's preferred language (es, en, pt). English will be used by default.
      env_mode: 'stg', // `prod`, `stg` to change environment. Default is `stg`
      onOpen: () => {
      },
      onClose: () => {

      },
      onResponse: (response) => { // The callback to invoke when the Checkout process is completed

        if(response.error !== undefined) {
          this._router.navigate(['/pagos/mensaje'], {state: { response: response }});
        } else {
          this._router.navigate(['/pagos/respuesta'], {state: { paymentRequest: this.paymentRequest}});
        }
      }
    });


  }

  openCheckout() {
    this.paymentezCheckout.open({
      user_id: '1234',
      user_email: this.paymentRequest.data.email, //optional
      user_phone: this.paymentRequest.data.phone, //optional
      order_description: this.paymentRequest.data.description,
      order_amount: this.paymentRequest.data.amount,
      order_vat: 0,
      order_reference: this.paymentRequest.data.reference,
    });
  }

}
