import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackMessageComponent } from 'src/app/commons/snack-message/snack-message.component';
import { AuthModel } from 'src/app/core/models/auth.model';
import { LoginModel } from 'src/app/core/models/login.model';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';
import { ChangeStatusService } from 'src/app/core/services/change-status.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { EpaycoService } from 'src/app/core/services/epayco.service';
import { LoadingScreenService } from 'src/app/core/services/loading-screen.service';
import { PaymentTransactionService } from 'src/app/core/services/payment-transaction.service';
import { PaymentezCheckoutService } from 'src/app/core/services/paymentez-checkout.service';
import { PaymentezService } from 'src/app/core/services/paymentez.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import Swal from 'sweetalert2';
import { PaymentezDialogComponent } from '../paymentez-dialog.component';


@Component({
  selector: 'app-pay-mixed',
  templateUrl: './pay-mixed.component.html',
  styleUrls: ['./pay-mixed.component.scss'],
  providers: [CurrencyPipe]
})
export class PayMixedComponent implements OnInit {


  @Input() paymentRequest: PaymentConfigModel;
  disableCreditCard: boolean = true;
  disablePse: boolean = true;

  public validateMixedPurchase: number = null;
  public percentageValid: number;
  public disablePercentage: boolean = true;
  public payAttemptedComplete: boolean = false;
  public payAttempted: number;
  public loading: boolean = false;
  public openExpantion = false;
  public openExpantionCard = false;
  public method: string;
  disableButton = false;
  auth;
  private _snackBar: MatSnackBar;
  public dialog: MatDialog;
  public url_return: string;
  auth_url;

  constructor(private epaycoService: EpaycoService,
    private currencyPipe: CurrencyPipe,
    private datalayerService: DatalayerService,
    private sessionStorageService: SessionStorageService,
    private changeStatusService: ChangeStatusService,
    private paymentService:PaymentezService,
    private paymentezCheckoutService: PaymentezCheckoutService,
    private loadingScreenService: LoadingScreenService,
    private paymentTransactionService: PaymentTransactionService
  ) {
    this.auth = this.sessionStorageService.getItem<LoginModel>(SessionStorageService.AUTH);
  }

  ngOnInit(): void {
    this.percentageValid = Math.ceil(this.paymentRequest.mixed_purchase_data.pending_value * this.paymentRequest.config_mixed_purchase.minimum_percentage);
    this.disablePercentage = this.paymentRequest.mixed_purchase_data.no_payments > 0 ? false : true;
    this.payAttemptedComplete = this.paymentRequest.mixed_purchase_data.no_payments ==
      this.paymentRequest.config_mixed_purchase.pay_attempted_card ? true : false;
    this.payAttempted = this.paymentRequest.config_mixed_purchase.pay_attempted_card;
    if (this.payAttemptedComplete) {
      this.validateAttemptedComplete();
    }

    if (this.paymentRequest.data.plan) {
      this.method = '1';
    }
    this.auth_url = this.sessionStorageService.getItem(SessionStorageService.ORDEN);
  }

  validateMixedPurchaseAmount() {

    if(this.disablePercentage && this.validateMixedPurchase < this.percentageValid){
      this.sendMessage('Error', `El monto mínimo debe ser ${this.currencyPipe.transform(this.percentageValid)}`);
      this.resetParameters();
    }else if(this.validateMixedPurchase > this.paymentRequest.mixed_purchase_data.pending_value){
      this.sendMessage('Error', `El monto no debe ser mayor a ${this.currencyPipe.transform(this.paymentRequest.mixed_purchase_data.pending_value)}`);
      this.resetParameters();
    }else if(this.validateMixedPurchase == this.paymentRequest.mixed_purchase_data.pending_value){
      this.disableCreditCard = false;
      this.disablePse = false;
      this.epaycoService.activateMixed.emit(true);
      this.epaycoService.changeAmountInitialMixedPurchase.emit(this.validateMixedPurchase);
    }else{
      this.epaycoService.activateMixed.emit(true);
      this.disableCreditCard = false;
      this.disablePse = true;
      this.openExpantion =  false;
      this.epaycoService.changeAmountInitialMixedPurchase.emit(this.validateMixedPurchase);
    }
  }

  resetParameters(){
    this.openExpantionCard =  false;
    this.validateMixedPurchase = null;
    this.disableCreditCard = true;
    this.disablePse = true;
    this.openExpantion =  false;
    this.epaycoService.changeAmountInitialMixedPurchase.emit(this.validateMixedPurchase);
  }

  sendMessage(titleMessage:any, textMessage:any){
    Swal.fire({
      title: titleMessage,
      text: textMessage,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-custom-primary'
      },
      confirmButtonText: 'Confirmar'
    })
  }

  validateAttemptedComplete(){
    this.disablePse = false;
    this.disableCreditCard = false;
    this.epaycoService.changeAmountInitialMixedPurchase.emit(this.paymentRequest.mixed_purchase_data.pending_value);
  }

  returnClient() {
    Swal.fire({
      title: "Advertencia",
      text: "Sí regresas al comercio, no podrás continuar con tu pago.",
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-custom-primary',
        cancelButton: 'btn btn-custom-cancel'
      },
      confirmButtonText: 'Si, continuar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.returnCommerce();
      }
    })
  }

  async returnCommerce() {
    const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    const request = {
      client: this.paymentRequest.name,
      dev_reference: this.paymentRequest.data.reference,
      amount: this.paymentRequest.data.amount
    }
    const response = await this.paymentTransactionService.returnCommerce(auth.token, request).toPromise();
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: response.message,
        text: "Intenta nuevamente",
      })
    } else {
      this.url_return = this.auth_url['url_return'];
      window.location.href = this.url_return;
    }
  }

  async openDialog(debit = false) {
    const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);

    const body = {
      dev_reference: this.paymentRequest.data.reference ? this.paymentRequest.data.reference : null
    }
    try {
      await this.changeStatusService.changeStatus(auth, this.paymentRequest, 'CARD', body);
    }
    catch (error) {
      console.log(error);
    }
    const method = debit ? '0' : this.method;
    this.loading = true;

    //datalayer boton de pago
    let amountDatalayer = this.paymentRequest.data.amount
    let textButton = `PAGAR ${amountDatalayer}`;
    this.datalayerService.AddInfoDataLayerPaymentButton(textButton);

    this.datalayerService.checkoutEvent(this.method, this.paymentRequest);
    if (this.method !== undefined && !this.disableButton) {

      this.disableButton = true;

      const datos = this.sessionStorageService.getItem(SessionStorageService.ORDEN);
      const token = 'Bearer ' + this.auth.token;

      this.paymentService.obtainStatusPayment(token, datos).subscribe(
        result => {
          if (result.status) {
            if (this.paymentRequest.gateway_active === 'Paymentez' && this.method === '1') {
              this.paymentezCheckoutService.inicializarPago(this.paymentRequest, debit,true);
              setTimeout(() => {
                this.disableButton = false;
                this.loading = false;
              }, 1500);
            } else {
              setTimeout(() => {
                this.disableButton = false;
              }, 700);
              const dialogRef = this.dialog.open(PaymentezDialogComponent, {
                // height: '630px',
                width: '465px',
                minHeight: '500px',
                maxWidth: '100vw',
                maxHeight: '100vh',
                data: {
                  payment: this.paymentRequest,
                  methodPayment: this.method
                }
              });
            }
          }
          else {
            this.disableButton = false;
            const mess = 'Esta referencia ya se encuentra aprobada';
            this.errorLogin(mess);
          }
        },
        error => {
          this.disableButton = false;
          const mess = 'No se ha encontrado la transaccion';
          this.errorLogin(mess);
        }
      );
    }
    this.loadingScreenService.stopLoading();
    this.loading = true;
  }

  addToDataLayerCheckoutEvent(method: string, paymentRequest) {
    this.datalayerService.addToDataLayerCheckoutEvent(method, paymentRequest);
  }

  errorLogin(message: any) {
    this._snackBar.openFromComponent(SnackMessageComponent, {
      data: message,
      duration: 5000,
      panelClass: ['snackError', 'withMin']
    });
  }
}
