import { Component, OnInit, Input, ViewChild, ElementRef, AfterContentInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterContentInit {
  image = null;
  @Input() paymentRequest: PaymentConfigModel;

  constructor(private sanitization: DomSanitizer) {
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
    if (this.paymentRequest) {
      this.image = this.paymentRequest.data.url_icon_subclient ? `url(${this.paymentRequest.data.url_icon_subclient})` : null;
    }
  }

  backToOrigin() {
    if (this.paymentRequest.platform_url) {
      window.open(this.paymentRequest.platform_url, '_self');
    }
  }


}
