import { Injectable } from '@angular/core';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class DatalayerService {

  paymentMethods = {
    '0': 'TARJETA DE DEBITO',
    '1': 'TARJETA DE CREDITO',
    '2': 'PSE',
    '3': 'DAVIPLATA',
    '4': 'DAVIPUNTOS',
    '5': 'DIRAPAY',
    '6': 'PUNTOS COLOMBIA',
    '7': 'NEQUI QR',
    '8': 'NEQUI PUSH',
    '9': 'CLARO PAY'
  };


  checkoutEvent(method, paymentRequest) {

    const infoDataLayer = {
      'event': 'checkout1',
      'ecommerce': {
        'checkout': {
          'actionField': {
            'id': paymentRequest.data.reference,
            'step': 1,
            'option': method ? this.paymentMethods[method] : null
          }
        },
        'products': [{
          'name': paymentRequest.data.description,
          'id': paymentRequest.data.reference,
          'price': paymentRequest.data.amount,
          'category': 'Publicacion',
          'quantity': 1
        }]
      }
    };

    try {
      window.dataLayer.push(infoDataLayer);
    } catch (error) {
      console.log('fallo al empujar datalayer');
    }

  }

  purchaseEvent(transactionId?, orderPaymentezModel?, auth?, paymentMethod?) {
    let method = orderPaymentezModel.is_debit ? "TD" : "TC";
    let option = paymentMethod ? paymentMethod : method;
    let is_paylink = orderPaymentezModel.is_paylink ? orderPaymentezModel.is_paylink : null;
    let products = {};
    if ((is_paylink != null) && (is_paylink)) {
      if(orderPaymentezModel.data.client == 'ciencuadras'){
        products =[{
          'name': orderPaymentezModel.data.description,
          'price': orderPaymentezModel.data.amount,
          'brand': orderPaymentezModel.name,
          'quantity':'1'
        }]
      }
      else{
        products =[{
          'name': 'Link de pagos',
          'price': orderPaymentezModel.data.amount,
          'brand': orderPaymentezModel.name,
          'quantity':'1'
        }]
      }
    }
    else{
      products = orderPaymentezModel.productsAnalyticsObject ? orderPaymentezModel.productsAnalyticsObject : [];
    }

    var infoDataLayer = {
      'event': 'purchase',
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': transactionId ? transactionId : auth['reference'],
            'affiliation': orderPaymentezModel.client ? orderPaymentezModel.client : orderPaymentezModel.name,
            'revenue': orderPaymentezModel.data.amount ? orderPaymentezModel.data.amount : auth['amount'],
            'option': option,
          },
          'products': products,
        },
      }
    };

    try {
      window.dataLayer.push(infoDataLayer);
    } catch (error) {
      console.log("fallo al empujar datalayer", error);
    }
  }

  AddInfoDataLayerPaymentMethod($medioDePago, $step?) {

    const infoDataLayerPaymentMethod = {
      'event': 'checkoutOption',
      'ecommerce': {
        'checkout_option': {
          'actionField': {
            //  'step​': $step, 'option': $medioDePago
            'step': "step3", 'option': $medioDePago
          }
        },
      }
    };

    try {
      window.dataLayer.push(infoDataLayerPaymentMethod);
    } catch (error) {
      console.log("fallo al empujar datalayer");
    }
  }

  AddInfoDataLayerPaymentButton($textButton, $action = null) {
    const action = $action ? $action : 'GTW - pagar';

    const dataLayerPaymentButton = {
      'event': 'ga_event',
      'category': 'gateway',
      'action': action,
      'label': $textButton
    };

    try {
      window.dataLayer.push(dataLayerPaymentButton);
    } catch (error) {
      console.log("fallo al empujar datalayer");
    }
  }
  //APIMAN 801 CIENCUADRAS
  //no se puede saber el medio de pago recien abierto el link 26H
  addToDataLayerCheckoutEvent(method, paymentRequest, step = '2') {
    let option = method ? method : 'paso2';
    let is_paylink = paymentRequest.is_paylink ? paymentRequest.is_paylink : null;
    let products = {};
    if ((is_paylink != null) && (is_paylink)) {
      products = [{
        'name':  paymentRequest.name == 'ciencuadras' ? paymentRequest.data.description: 'Link de pagos',
        'price': paymentRequest.data.amount,
        'brand': paymentRequest.name,
        'quantity': '1'
      }]
    }
    else {
      products = paymentRequest.productsAnalyticsObject ? paymentRequest.productsAnalyticsObject : [];
    }
    const datalayerCheckout = {
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {
            'step': step,
            'option': option
          },
          'products': products
        }
      }
    };

    try {
      window.dataLayer.push(datalayerCheckout);
    } catch (error) {
      console.log("fallo al empujar datalayer");
    }
  }

  addInfoDataLayerVirtualPagePaymentMethod() {
    const infoDataLayerVirtualPagePaymentMethod = {
      'event': 'virtualPage',
      'title': 'CCW - Carrito',
      'UrlVP': '/proceso-compra/metodo-pago/paso4'
    }

    try {
      window.dataLayer.push(infoDataLayerVirtualPagePaymentMethod);
    } catch (error) {
      console.log("fallo al enviar datalayer virtualPage");
    }
  }

  addInfoDataLayerPurchaseResume(textoBoton, client, paymentMethod?, status = null) {
    let action = '';
    let category = '';
    let metodoDePago = '';
    let metodo_de_pago = '';

    if (client == 'ciencuadras') {
      action = 'CCW-reintentar pago';
      category = 'carrito';
    }

    if (client == 'doctoraki' && status == null) {
      action = 'DAW-pagar-medico en linea';
      category = 'carrito';
    }

    if (client == 'doctoraki' && status != null) {

      if (status == 'success') {
        status = 'exitoso';
      }
      else {
        status = 'fallido';
      }

      action = 'DAW-cita-' + status;
      category = 'agendar cita';
    }

    if (client == 'doctoraki' && paymentMethod) {
      action = 'DAW-paso4-pagar cita';
      category = 'agendar cita';
      metodoDePago = paymentMethod;
      metodo_de_pago = metodoDePago ? `metodo_de_pago:${metodoDePago}` : null;
    }

    // GD815-1019
    category = 'gateway';
    action = 'GTW - pagina confirmacion';

    const infoDataLayerPurchaseResume = {
      'event': 'ga_event',
      'category': category,
      'action': action,
      'label': textoBoton,
    }

    const infoDataLayerPurchaseResumeP = {
      'event': 'ga_event',
      'category': category,
      'action': action,
      'label': textoBoton,
      metodo_de_pago,
    }

    try {
      if(paymentMethod){ window.dataLayer.push(infoDataLayerPurchaseResumeP); }
      else { window.dataLayer.push(infoDataLayerPurchaseResume); }
    } catch (error) {
      console.log("fallo al enviar datalayer PurchaseResume");
    }
  }

  addInfoDataLayerPurchaseResumeConfirmation(response?) {
    let idTransaction = response.transaction.payment_reference ? response.transaction.payment_reference : response.transaction.dev_reference
    let status;
    if(response.transaction.status == 'success'){
      status = 'exitoso';
    }
    else{
      status = 'fallido';
    }
    const infoDataLayerPurchaseResume = {
      'event': 'ga_event',
      'category': 'agendar cita',
      'action': 'DAW - confirmacion cita',
      'label': status,
      'id_transaction': idTransaction
    }

    try {
      window.dataLayer.push(infoDataLayerPurchaseResume);
    }
    catch (error) {
      console.log("fallo al enviar datalayer PurchaseResume");
    }
  }

  addBackToCommerceDataLayer(portal: string){

    const infoDataLayerBackToCommerce = {
      event: "ga_event",
      category: "carrito",
      action: "GTW - volver al portal",
      label: portal
    };

    try {
      window.dataLayer.push(infoDataLayerBackToCommerce);
    }
    catch (error) {
      console.log("fallo al enviar datalayer volver al comercio ",error);
    }
  }
}
