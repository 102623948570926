import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es';
@Pipe({
  name: 'dateFormatES'
})
export class DateFormatEsPipe implements PipeTransform {


  transform(value: string, format?: any): any {
    if(format){
      return moment(value).format(format);
    }
    return moment(value).format("DD-MM-YYYY hh:mm:ss a");
  }

}
