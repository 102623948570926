import { Injectable } from '@angular/core';
import {DEFAULT_INTERRUPTSOURCES, Idle} from "@ng-idle/core";
import {PostMessageService} from './post-message.service';
import {SessionStorageService} from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  isIdle = false;
  tokenPm: string;
  constructor(private idle: Idle,private postMessageService: PostMessageService,private sessionStorageService: SessionStorageService,) {}

  setIdleState(idleTime:  number, idleTimeout: number){

    console.log(idleTimeout)
    console.log(idleTime)
    console.log(this.isIdle)

    this.idle.setIdle(idleTime);
    this.idle.setTimeout(idleTimeout);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.isIdle = false;
    });

    this.idle.onTimeout.subscribe(() => {
      this.isIdle = true;
      this.postMessageService.send(true, null, false);
    });
  }

  getIsIdle(){
    return this.isIdle;
  }

  IdleWatch(){
    this.idle.watch();
  }

  sendPostMessage(){
    this.postMessageService.send();
  }

  stopWatching() {
    this.idle.stop();
    this.idle.clearInterrupts();
    this.isIdle = false;
  }

  activateIdle(idleTime:  number, idleTimeout: number){
    this.tokenPm = this.sessionStorageService.getItem<string>(SessionStorageService.TOKEN_PM);
    if (this.tokenPm){
      this.stopWatching();
      this.setIdleState(idleTime,idleTimeout);
      this.IdleWatch();
    }
  }


}
