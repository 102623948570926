import { Injectable } from '@angular/core';
import { Cdn, CdnStore } from '@core/services/cdn-loader/cdn-storage';

interface Script {
  script: string;
  loaded: boolean;
  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class CdnLoaderService {
  #scripts: { [key: string]: { loaded: boolean; src: string, status?: string } } = {};

  constructor() {
    CdnStore.forEach((script: Cdn) => {
      this.#scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  load(...scripts: string[]) {
    const promises: Promise<Script>[] = [];

    scripts.forEach((script) => promises.push(this.#loadScript(script)));

    return Promise.all(promises);
  }

  #loadScript(name: string) {
    return new Promise<Script>((resolve, reject) => {
      if (this.#scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Loaded' });
      } else {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.#scripts[name].src;
        script.onload = () => {
          this.#scripts[name].loaded = true;

          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Error' });

        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}
