import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthModel } from "../models/auth.model";
import { environment } from "../../../environments/environment";

import * as CryptoJS from 'crypto-js';
import { SessionStorageService } from './session-storage.service';
import { LoginModel } from '../models/login.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private sessionStorage: SessionStorageService
  ) { }

  login(domain: string, generic = '', product = ""): Observable<AuthModel> {
    return this.http.get<AuthModel>(`${environment.apiUrl}/login/check-user?q=${domain}&g=${generic}&p=${product}`);
  }

  getToken(obj: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post<any>(environment.apiUrl + '/login/login-user', obj, { headers });
  }

  encryptData(data) {

    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), environment.secretKey).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, environment.secretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return false;
    } catch (e) {
      console.log(e);
    }
  }

  decryptDataPlain(data) {
    const keyEncr = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);
    try {
      const decrypted = CryptoJS.AES.decrypt(data, keyEncr, { mode: CryptoJS.mode.CTR, iv, padding: CryptoJS.pad.NoPadding })
      if (decrypted.toString()) {
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      }
      //return false;
    } catch (e) {
      console.log('fallo al desncriptar datos de confirmación daviplata');
    }
  }

  getTimezone() {
    const auth = this.sessionStorage.getItem<AuthModel>(SessionStorageService.AUTH);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + auth.token);
    return this.http.get<any>(environment.apiUrl + '/api/timezone', { headers });
  }

}
