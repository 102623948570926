import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthModel } from 'src/app/core/models/auth.model';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';
import { PaymentezResponseSdkModel } from 'src/app/core/models/paymentez/paymentez-response-sdk.model';
import { AuditService } from 'src/app/core/services/audit.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { DiraPayInvoiceResponse } from 'src/app/gateway/paymentez/paymentez-dialog/pay-dira/pay-dira.interface'; 

@Component({
  selector: 'app-purchase-dira-pay',
  templateUrl: './purchase-dira-pay.component.html',
  styleUrls: ['./purchase-dira-pay.component.scss']
})
export class PurchaseDiraPayComponent implements OnInit {

  @Input() diraPayInfo: DiraPayInvoiceResponse;
  @Input() paymentRequest: PaymentConfigModel;
  @Output() imprimir: EventEmitter<any>;
  
  authToken;
  url_retry;
  url_return;

  constructor(
    private sessionStorageService: SessionStorageService,
    private auditService: AuditService
  ) { 
    this.imprimir = new EventEmitter()
  }

  ngOnInit(): void {
    let auth = this.sessionStorageService.getItem(SessionStorageService.ORDEN);
    this.authToken = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    this.url_retry = this.sessionStorageService.getItem(SessionStorageService.URL_PAYMENT_REQUEST);
    this.url_return = auth['url_return'];
  }
 
  returnClient() {
    const request = {
      dev_reference: this.paymentRequest.data.reference,
      client: this.paymentRequest.data.client,
      subclient: this.paymentRequest.data.subclient,
      leave_type: "Salida por Boton",
      payment_status: this.diraPayInfo.status,
      leave_page: true
    }

    this.auditService.saveAudit(request, this.authToken.token).subscribe(res => {
      window.location.href = this.url_return;
    });
  }
  retryPayment() {
    window.location.href = this.url_retry + "&reload=" + + new Date().getTime();
  }

  printPDF() {
    this.imprimir.emit(true);
  }

  private dataMock() {
    this.diraPayInfo = {
      diraId: "efe5dd97-0e8b-4823-a7ce-730810a869f3",
      reference: "XTOKkpsuN",
      description: "Payment_test_1_Paylink_mixed",
      value: 15000,
      currency: "COP",
      paidValueCrypto: 0.000165,
      cryptoCurrency: "BTC",
      status: "APPROVED",
      paidDate: "2022-08-19T13:28:18.537Z",
      name: "arith",
      email: "arithalexis@gmail.cc"
    }
  }
}

