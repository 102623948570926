import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
import { BehaviorSubject } from 'rxjs';
import { SidenavState } from './sidenav-state.enum';

@Injectable()
export class SidenavService {

  // With this subject you can save the sidenav state and consumed later into other pages.
  public sideNavState$: Subject<boolean> = new Subject();

  constructor() { }

  /**
   * SidenavState for Animation
   * @type {BehaviorSubject<SidenavState>}
   * @private
   */
  private _sidenavState = new BehaviorSubject<SidenavState>(SidenavState.Expanded);

  sidenavState$ = this._sidenavState.asObservable();

  get sidenavState() {
    return this._sidenavState.getValue();
  }

  set sidenavState(sidenavState: SidenavState) {
    this._sidenavState.next(sidenavState);
  }

}