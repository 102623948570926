import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PaymentezModule} from "./paymentez/paymentez.module";
import {PaymentezLinkToPayModule} from "./paymentez-link-to-pay/paymentez-link-to-pay.module";
import {PaymentezCheckoutModule} from "./paymentez-checkout/paymentez-checkout.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    PaymentezModule,
    PaymentezLinkToPayModule,
    PaymentezCheckoutModule
  ]
})
export class GatewayModule { }
