import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "src/environments/environment";
import { AuthModel } from "../models/auth.model";
import { AuthService } from "./auth.service";
import { EncryptService } from "./encrypt.service";
import { SessionStorageService } from "./session-storage.service";

/**
 * Davipuntos Mixed Purchase Service
 */
@Injectable({
  providedIn: "root",
})
export class DavipuntosService {
  private auth: AuthModel;
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private encryptService: EncryptService,
    private sessionStorageService: SessionStorageService
  ) {
    this.auth = this.sessionStorageService.getItem<AuthModel>(
      SessionStorageService.AUTH
    );

    this.headers = new HttpHeaders()
      .set("Authorization", `Bearer ${this.auth.token}`)
      .set("Content-Type", "application/json");
  }

  davipuntosLogin(devReference:string): Observable<DavipuntosResponse> {
    return this.http.get<DavipuntosResponse>(
      `${environment.apiUrl}/api/davipuntos-login?devReference=${devReference}`,
      { headers: this.headers }
    );
  }

  getData(
    rstate: string,
    confirmation: string,
    username: string,
    devReference: string
  ): Observable<DavipuntosResponse> {
    const data = this.encryptService.encryptCipherData({
      rstate,
      confirmation,
      username,
      devReference
    });

    return this.http.post<DavipuntosResponse>(
      `${environment.apiUrl}/api/davipuntos-token`,
      { data },
      { headers: this.headers }
    );
  }

  makePayment(paymentObjectRequest: any, success: any): Observable<DavipuntosResponse> {
    console.log("paymentObjectRequest",paymentObjectRequest)
    const data = this.encryptService.encryptCipherData(paymentObjectRequest);
    const hash = this.encryptService.encryptCipherData(success);
    return this.http.post<DavipuntosResponse>(
      `${environment.apiUrl}/api/davipuntos/make-payment`,
      { data, 
        hash
       },
      { headers: this.headers }
    );
  }
}

interface DavipuntosResponse {
  success: boolean;
  data: Data;
  error: null | string;
}

export interface Data {
  creditCards?: CreditCards;
  pricePoints?: PricePoints;
  token?: string;
  urlOauth?: string;
  errorCode?: string;
  errorMessage?: string;
  status?: string;
  points?: string;
  selectedCard?: string;
  installmentSelected?: string;
  totalAmountPayCreditCard?: string;
  transactionId?: string;
}

export interface CreditCards {
  Products?: ProductItem[];
  errorCode?: string;
  errorMessage?: string;
}

interface ProductItem {
  Product?: Product;
}

export interface Product {
  alias: string;
  bin: string;
  codeProduct: string;
  idProduct: string;
  franchise: string;
  reference: string;
  state: string;
  subCodeProduct: string;
}

export interface PricePoints {
  contract?: Contract;
  client?: Client;
  errorCode?: string;
  errorMessage?: string;
}

interface Client {
  datePointsToExpire: string;
  name: string;
  nextPointsToExpire: string;
  typeDocument: string;
  numberDocument: string;
  points: string;
}

interface Contract {
  minimumPointsToRedemption: string;
  points: string;
  pricePoints: string;
  errorCode?: string;
  errorMessage?: string;
}
interface MixedPurchase {
  pointsInfo?: {
    pointsQty: number;
    ivaTax: number;
  };
  creditCardInfo?: {
    creditCardId: string;
    amount: number;
    installments: number;
    ivaTax: number;
    comsumptionTax: number;
    taxBase: number;
    tip: number;
    locationInfo: {
      terminalId: number;
      storeCode: number;
      storeName: string;
      countryCode: string;
      stateCode: string;
      cityCode: string;
      cityName: string;
    };
  };
}
