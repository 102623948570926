import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RetentionsComponent } from '@payment-app/components/info-amount-to-pay/components/retentions/retentions.component';
import { ManualBonusComponent } from '@payment-app/components/info-amount-to-pay/components/manual-bonus/manual-bonus.component';
import { MixedPurchaseComponent } from '@payment-app/components/info-amount-to-pay/components/mixed-purchase/mixed-purchase.component';
import { BinBonusComponent } from '@payment-app/components/info-amount-to-pay/components/bin-bonus/bin-bonus.component';
import { PaymentRequestService } from '../../../payment/payment-request/payment-request.service';
import { CommonInfoAmountComponent } from '@payment-app/components/info-amount-to-pay/components/common-info-amount/common-info-amount.component';
import { RetentionsService } from '@payment-app/components/info-amount-to-pay/components/retentions/retentions.service';

@Component({
  selector: 'info-amount-to-pay',
  standalone: true,
  imports: [
    CommonModule,
    RetentionsComponent,
    ManualBonusComponent,
    MixedPurchaseComponent,
    BinBonusComponent,
    CommonInfoAmountComponent,
  ],
  templateUrl: './info-amount-to-pay.component.html',
  styleUrls: ['./info-amount-to-pay.component.scss'],
})
export class InfoAmountToPayComponent implements OnInit {
  readonly #paymentRequestService = inject(PaymentRequestService);
  protected paymentRequest = this.#paymentRequestService.paymentRequest;
  protected data = this.paymentRequest.data;
  #service = inject(RetentionsService);
  showInfo = false;

  ngOnInit(): void {
    this.#service.showInfo$.subscribe((showInfo) => {
      this.showInfo = showInfo;
    });
  }
}
