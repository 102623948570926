import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CvvHelpTextDialogComponent } from './cvv-help-text-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CvvHelpTextDialogService {
  #matDialog = inject(MatDialog);

  open(): void {
    this.#matDialog.open(CvvHelpTextDialogComponent, {
      position: { top: '64px' },
      disableClose: true,
    });
  }
}
