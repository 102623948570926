import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { PaymentMethodsRedirectionComponent } from '@payment-app/components/payment-methods-redirection/payment-methods-redirection.component';
import {
  PaymentMethodsRedirectionResolver
} from '@payment-app/components/payment-methods-redirection/payment-methods-redirection.resolver';

const paymentRoutes: Routes = [
  {
    path: 'volver-al-comercio/:method/:reference',
    component: PaymentMethodsRedirectionComponent,
    resolve: {
      service: PaymentMethodsRedirectionResolver,
    },
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(paymentRoutes), CommonModule],
})
export class PaymentMethodsRoutingModule {}
