import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentRequestService } from '../../../../../payment/payment-request/payment-request.service';

@Component({
  selector: 'common-info-amount',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './common-info-amount.component.html',
  styleUrls: ['./common-info-amount.component.scss'],
})
export class CommonInfoAmountComponent implements OnInit {
  readonly #paymentRequestService = inject(PaymentRequestService);
  #paymentRequest = this.#paymentRequestService.paymentRequest;
  data = this.#paymentRequest.data;

  ngOnInit(): void {
    this.#paymentRequestService.paymentRequest$.subscribe((paymentRequest) => {
      this.#paymentRequest = paymentRequest;
      this.data = this.#paymentRequest.data;
    });
  }
}
